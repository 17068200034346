import { RouteRecordRaw } from "vue-router";

export const onlineRoutes: Array<RouteRecordRaw> = [
	{
		path: "/:language(en|ar|)?/company/",
		component: () => import("@/online/layout/Layout.vue"),
		children: [
			{
				path: ":aliasName",
				name: "online-home",
				components: {
					mainView: () => import("@/online/views/Home.vue"),
				},
			},
			{
				path: ":aliasName/booking",
				name: "online-booking",
				components: {
					bookingView: () => import("@/online/views/booking/Booking.vue"),
				},
			},
			{
				path: ":aliasName/booking-success/:bookingId",
				name: "online-booking-success",
				components: {
					bookingView: () => import("@/online/views/booking/BookingSuccess.vue"),
				},
			},
			{
				path: ":aliasName/price-list",
				name: "online-price-list",
				components: {
					mainView: () => import("@/online/views/PriceList.vue"),
				},
			},
			{
				path: ":aliasName/offers",
				name: "online-offers",
				components: {
					mainView: () => import("@/online/views/Offers.vue"),
				},
			},
			{
				path: ":aliasName/buy-gift-card",
				name: "online-buy-gift-card",
				components: {
					mainView: () => import("@/online/views/BuyGiftCard.vue"),
				},
			},
			{
				path: ":aliasName/payment-gateway-status/:uniqueId",
				name: "online-payment-gateway-status",
				components: {
					bookingView: () => import("@/online/views/PaymentGatewayStatus.vue"),
				},
			},
			{
				path: ":aliasName/terms-and-conditions",
				name: "online-terms-and-conditions",
				components: {
					mainView: () => import("@/online/views/TermsAndConditions.vue"),
				},
			},
		],
	},
];
