import { createI18n } from "vue-i18n";

const messages = {
	en: {
		dashboard: "Dashboard",
		appointments: "Appointments",
		customers: "Tenants",
		customers_original: "Customers",
		employees: "Employees",
		employee: "Employee",
		roles_and_permissions: "Roles & Permissions",
		services: "Services",
		groups: "Groups",
		packages: "packages",
		products: "Products",
		stock_management: "Stock Management",
		accounting: "Accounting",
		total_sales: "Total Sales",
		expenses: "Expenses",
		payments: "Payments",
		receivable: "Receivable",
		accounting_reports: "Accounting Reports",
		setting: "Setting",
		general_setting: "General Setting",
		invoice_setting: "Invoice Setting",
		accounting_setting: "Accounting Setting",
		online_setting: "Online Setting",
		notification_setting: "Notifications Setting",
		cs_setting: "Tenant Service Setting",
		logout: "Logout",
		edit: "Edit",
		activate: "Activate",
		disable: "Disable",
		filter: "Filter",
		export: "Export",
		actions: "Action",
		name: "Name",
		mobile: "Mobile",
		email: "Email",
		type: "Type",
		loyality_points: "Loyality Points",
		account_balace: "Account Balance",
		identification_method: "Identification Method",
		notes: "Notes",
		status: "Status",
		not_exist_create_new_customer: "Not Exist, Create New Tenant?",
		walk_in_customer: "Walk-in Tenant",
		general_reports: "General Reports",
		inventory_reports: "Inventory Reports",
		management_reports: "Management Reports",
		marketing_tools: "Marketing Tools",
		date_and_time: "Date & Time",
		invoice_number: "Invoice #",
		contract_number: "Contract #",
		subtotal: "SubTotal",
		vat: "VAT",
		total: "Total",
		salesman: "Sales Man",
		issued_by: "Issued By",
		description: "Descriptions",
		supplier: "Supplier",
		supplier_invoice: "Supplier Invoice",
		supplier_trn: "Supplier TRN",
		paid_vat: "Paid VAT",
		payment_method: "Payment Method",
		payment_date: "Payment Date",
		reference_number: "Reference #",
		paid_amount: "Paid Amount",
		remaining: "Remaining",
		credit_note_number: "Credit Note #",
		returned_invoice_numner: "Returned Invoice #",
		returned_amount: "Returned Amount",
		returned_vat: "Returned VAT",
		debit_note_number: "Debit Note #",
		supplier_invoice_number: "Supplier Invoice #",
		picture: "Picture",
		experience: "Experience",
		passport_expiry: "Passport Expiry",
		residancy_expiry: "Residancy Expiry",
		joining_date: "Joining Date",
		leaving_date: "Leaving Date",
		show_in_appointment_table: "Show in Appointment table",
		have_access_login: "Have Access Login",
		order: "Order",
		english_name: "English Name",
		arabic_name: "Arabic Name",
		show_to_customer: "Show to Tenant",
		group: "Group",
		original_price: "Original Price",
		price_include_vat: "Price including VAT",
		total_including_vat: "Total including VAT",
		job_time: "Job Time (MIN)",
		offer_price: "Offer Price",
		from: "From",
		to: "To",
		barcode: "Barcode",
		average_cost_price: "Average Cost Price",
		warning_if_reach: "ًWarning if Reach",
		print_barcode: "Print Barcode",
		add: "Add",
		define: "Define",
		appointment_reports: "Appointment Reports",
		appointment_report: "Appointment Report",
		inventory_status: "Inventory Status",
		inventory_movements: "Inventory Movements",
		internal_use_report: "Internal Use Report",
		inventory_evaluation: "Inventory Evaluation",
		booking_source: "Booking Source",
		payment_status: "Payment Status",
		service: "Service",
		service_without_the: "Service",
		booking_date: "Booking Date",
		start_time: "Start Time",
		google_map: "Google Map",
		send_reminder: "Send Reminder",
		sent: "Sent",
		send: "Send",
		marketing_sms: "Marketing SMS",
		inventory_movements_period: "Inventory Movements (Period)",
		internal_use: "Internal Use",
		added_quantity: "Added Quantity",
		sold: "Sold",
		lost: "Lost",
		returned: "Returned",
		remaining_quantity: "Remaining Quantity",
		product_name: "Product Name",
		operation: "Operation",
		quantity: "Quantity",
		service_product: "Service/Product",
		activity_report: "Activity Report",
		daily_payment_summary: "Daily Payment Summary",
		cash: "Cash",
		credit_card: "Credit Card",
		transfer: "Transfer",
		cheuqe: "Cheuqe",
		payment_link: "Payment Link",
		website: "Website",
		date: "Date",
		monthly_payment_summary: "Monthly Payment Summary",
		month: "Month",
		employees_report: "Employees Report",
		employee_commission: "Employee Commission",
		employee_part_of_sales: "Employee Part of Sales",
		details: "Details",
		services_report: "Services Report",
		number_of_time_used: "Number of time used",
		most_used_products: "Most used Products",
		time_of_used: "Time of Used",
		number_of_selling: "Number of Selling",
		most_sold_products: "Most Sold Products",
		online_daily_visits: "Online Daily Visit",
		number_of_visits: "Number of Visits",
		coupons_report: "Coupons Report",
		coupon_id: "Coupon ID",
		serial_number: "Serial Number",
		value: "Value",
		re_print: "Re-Print",
		complain_report: "Complaints Report",
		complain_id: "Complaint ID",
		customer_discount_report: "Tenant Discount Report",
		discount_percente: "Discount %",
		discount_value: "Discount Value",
		survey_report: "Survey Report",
		excellent: "Excellent",
		good: "Good",
		weak: "Weak",
		very_weak: "Very Weak",
		search: "Search",
		search_and_follow_up: "Search & Follow Up",
		products_management_reports: "Products Management Reports",
		customers_relations_reports: "Tenants Relations Reports",
		important_reports: "Important Reports",
		filter_options: "Filter Options",
		include_disabled: "Include Disabled",
		include_disabled_expired: "Include Disabled / Expired",
		show_more: "Show More",
		show_less: "Show Less",
		salonaty: "AqarBox",
		signin: "Signin",
		signout: "Signout",
		account_id: "Account ID",
		forget_account_id: "Forget Account ID?",
		remember_me: "Remember me",
		continue: "Continue",
		password: "Password",
		forget_password: "Forget Password?",
		login: "Login",
		sms_marketing: "SMS Marketing",
		business_start_time: "Business Start Time",
		business_end_time: "Business End Time",
		holiday: "Holiday",
		day: "Day",
		total_income: "Total Income",
		acheived_services: "Acheived Services",
		birth_date: "Birth Date",
		nationality: "Nationality",
		address: "Address",
		gender: "Gender",
		overview: "Overview",
		working_hours: "Working Hours",
		Activity: "Activity",
		holidays: "Holidays",
		salary: "Salary",
		commission: "Commission",
		holiday_name: "Holiday Name",
		holiday_start_date: "Holiday Start Date",
		holiday_end_date: "Holiday End Date",
		salon_menu: "Salon Menu",
		product_price_search: "Product Price Search",
		add_internal_use: "Add Internal Use",
		send_daily_reports: "Send Daily Reports",
		send_backup_reports: "Send Backup Reports",
		language: "Language",
		lock_screen: "Lock Screen",
		training: "Training",
		quick_setup: "Quick Setup",
		english: "English",
		arabic: "Arabic",
		employee_details: "Employee Details",
		extra_information: "Extra Information",
		rights: "rights!",
		add_employee: "Add Employee",
		save: "Save",
		customer_details: "Tenant Details",
		trn: "TRN",
		view_permissions: "View Permissions",
		user_has: "User Has",
		rights_to_the_system: "Rights to the System, ",
		control_access_login: "Control Access Login",
		add_a_customer: "Add a Tenant",
		packages_and_membership: "Packages & Memberships",
		offers_and_membership: "Offers & Memberships",
		coupons: "Coupons",
		new_invoice: "New Invoice",
		new_deposit: "New Deposit",
		transactions: "Transactions",
		balance_till_the_moment: "Balance till the Moments",
		insurance_till_the_moment: "Insurance till the moment",
		insurance: "Insurance",
		this_year: "This Year",
		add_appointment: "Add Appointment",
		profit_and_loss_report: "Profit & Loss Report",
		cash_flow_report: "Cash Flow Report",
		other_accounting_reports: "Other Accounting Reports",
		debit_notes_report: "Debit Notes Report",
		credit_notes_report: "Credit Notes Report",
		credit_and_debit_notes: "Credit & Debit Notes",
		edit_employee_info: "Edit Employee Informations",
		customer_invoices: "Tenant Invoices",
		saturday: "Saturday",
		sunday: "Sunday",
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednsday",
		thursday: "Thursday",
		friday: "Friday",
		edit_employee: "Edit Employee",
		edit_extra_information: "Edit Extra Informations",
		expiry_date: "Expiry Date",
		show_in_billing: "Show In Billing",
		yes: "Yes",
		no: "No",
		show_in_appointments: "Show In Appointments",
		upload_photo: "Upload Photo",
		administrator: "Administrator",
		total_users_with_roles: "Total Users With Roles:",
		add_a_Role: "Add a Role",
		role_name: "Role Name",
		enter_a_role_name: "Enter A Role Name",
		role_permissions: "Role Permissions",
		read: "Read",
		write: "Write",
		create: "Create",
		administrator_access: "Administrator Access",
		select_all: "Select All",
		cancel: "Cancel",
		add_new_role: "Add New Role",
		assign_permissions: "Assign Permissions",
		add_roles: "Add Roles",
		edit_roles: "Edit Roles",
		stocks_management: "Stock Managements",
		add_quantity: "Add Quantity",
		adjust_quantity: "Adjust Quantity",
		delete: "Delete",
		show: "show",
		adjust: "Adjust",
		all_features: "All Features",
		reports: "Reports",
		most_used: "Most Used",
		most_sold: "Most Sold",
		receipt: "Receipt",
		loyality_program: "Loyality Program",
		discount_codes: "Discount Codes",
		tax: "Tax",
		tax_name: "Tax Name",
		percentage: "Percentage %",
		business_hours: "Business Hours",
		discount_code: "Discount Code",
		loyality_code: "Loyality Code",
		points_vs_paid_currency: "Points / Paid Currency",
		activate_qr: "Activate QR",
		admin_email: "Admin Email",
		country: "Country",
		city: "City",
		address_in_arabic: "Address in Arabic",
		address_in_english: "Address in English",
		trn_number: "TRN Number",
		import_data: "Import Data",
		make_sure_to_use_system_format_from_action_button: "Make Sure to use system format from action button",
		upload_now: "Upload Now",
		link_employee_with_service: "Link Employee with service",
		link_employee_with_product: "Link Employee with product",
		allow_hold_invoice: "Allow hold Invoice",
		controller: "Controller",
		download_groups_template: "Download Groups Template",
		download_services_template: "Download Services Template",
		download_products_template: "Download Products Template",
		edit_business_informations: "Edit Business Informations",
		invoice_controller: "Invoice Controller",
		show_employee_in_customer_invoice: "Show Employees Names In Tenant Invoice",
		show_customer_information_in_invoice: "Show Tenant information in Invoice ",
		send_email_to_employee_with_done_service: "Send Email to employee with done service",
		send_email_to_employee_with_ordered_service: "Send Email to employee with ordered service",
		activate_winning_box: "Activate Winning Box",
		invoice_header: "Invoice Header",
		invoice_footer: "Invoice Footer",
		add_customer: "Add Tenant",
		add_more_data: "Add more data",
		birthday: "Birthday",
		gifts_cards_reports: "Gifts Cards Reports",
		redeem_loyality_points: "Redeem Loyality Points",
		reminder: "Reminder",
		add_balance_to_customer_through_loyality_points: "Add Balance to Tenant through redeeming Loyality Points,",
		gifts_cards: "Gifts Cards",
		send_email: "Send Email",
		whatsapp: "Whatsapp",
		use_service: "Use Service",
		header_and_footer: "Invoice Header & Footer",
		active_gifts_cards: "Active Gifts Cards",
		suppliers: "Suppliers",
		expenses_types: "Expenses Types",
		online_controller: "Online Services Controller",
		is_working_day: "Working Day",
		add_automatically: "Add Automatically",
		primary_informations: "Primary Informations",
		setup_your_account_details: "Setup Your Account Details",
		activate_vat: "Activate VAT",
		activate_vat_in_your_business: "Activate VAT in your Business",
		employee_info: "Employees Info",
		add_employees_info: "Add Employees Info",
		set_business_hours: "Set Business Hours",
		set_how_invoice_looklike: "Set how Invoice looklike",
		activate_services: "Activate Services",
		activate_services_and_set_prices: "Activate Services & set prices",
		completed: "Completed",
		woah_we_are_here: "Woah, we are here",
		aaccount_info: "Account Info",
		if_you_need_to_switch_languages: "If you need to switch languages, ",
		switch_language: "Switch Language",
		upload_business_logo: "Upload Business Logo",
		search_products: "Search Products",
		search_by_barcode_or_name: "Search By Barcode OR Name",
		balance_due: "Balance Due",
		sell_as_package: "Sell As Package",
		add_new_payment: "Add New Payment",
		remove_payment: "Remove Payment",
		issue_invoice: "Issue Invoice",
		cancel_issue_invoice: "Cancel Issue Invoice",
		number_of_items: "Number of Items",
		proceed_payment: "Proceed Payment",
		hold_invoice: "Hold Invoice",
		add_gift_card: "Add Gift Card",
		submit: "Submit",
		add_free_text: "Add Free Text",
		free_text: "Free Text",
		invoice: "Invoice",
		remove: "Remove",
		price: "Price",
		excel: "Excel",
		print: "Print",
		export_options: "Export Options",
		disabled: "Disabled",
		allow_dept_in_invoice: "Allow Debit in Invoice",
		invoice_notes: "Invoice Notes",
		forget_accoubt_id: "Forget Account ID?",
		coupon_gift_card: "Coupon / Gift Card",
		ok: "Ok",
		enter_your_email_to_receive_your_account_id: "Enter Your Email to receive your account ID",
		back: "Back",
		enter_your_email_to_reset_your_password: "Enter your Email to reset your Password",
		hide_from_customer: "Hide from Tenant",
		add_group: "Add Group",
		add_service: "Add Service",
		sell_price: "Sell Price",
		sell_price_including_vat: "Sell Price including VAT",
		add_package: "Add Package",
		edit_package: "Edit Package",
		sell_price_including_price: "Sell Price including price",
		initial_quantity: "Initial Quantity",
		warning_if_stock_reach: "Warning if stock reach",
		define_product: "Define Product",
		edit_product: "Edit Product",
		working_day: "Working Day",
		new_times_warning: "** Times will be applied on employees and appointment table",
		edit_business_hours: "Edit Business Hours",
		edit_tax: "Edit TAX",
		confirm: "Confirm",
		editing_tax_will_affect_all_service_and_products: "** Editing Tax will affect all services and products.",
		this_will_change_all_services_and_products_prices_according_to_new_vat:
			"This will change all services and products prices according to the new VAT",
		code: "Code",
		category: "Category",
		add_discount_code: "Add Discount Code",
		edit_discount_code: "Edit Discount Code",
		superadmin: "Superadmin",
		disable_service: "Disable Service",
		edit_loyality_program: "Edit Loyality Program",
		edit_invoice_setting: "Edit Invoice Setting",
		edit_invoice_header_and_footer: "Edit Invoice Header & Footer",
		header: "Header",
		footer: "Footer",
		receptionist: "Receptionist",
		manager: "Manager",
		hair_services: "Hair Services",
		hair_treatment: "Hair Treatment",
		hair_color: "Hair Color",
		makeup: "Makeup",
		manicurist: "Manicurist",
		pedicurist: "Pedicurist",
		morrocan_bath: "Morrocan Bath",
		henna: "Henna",
		message: "Message",
		facial: "Facial",
		wax: "Wax",
		halawa: "Halawa",
		threding: "Threding",
		eyebrow: "Eyebrow",
		lashes: "Lashes",
		general_services: "General Services",
		not_specific: "Not Specific",
		relatives: "Relative",
		relatives_acquaintances: "Relative / Acquaintances",
		facebook: "FaceBook",
		instagram: "Instagram",
		snapchat: "Snapchat",
		google: "Google",
		blogger_ads: "Blogger Ads",
		coincidence: "Coincidence",
		other: "Other",
		male: "Male",
		female: "Female",
		business: "Business",
		profile: "Profile",
		activities: "Activities",
		this_month: "This Month",
		last_month: "Last Month",
		invoice_payments_should_be_equal_to_invoice_total: "Payments total should be equal to invoice total",
		invoice_payments_should_be_less_or_equal_to_invoice_total: "Payments total should be less or equal to invoice total",
		invoice_payments_is_not_the_same_as_invoice_total: "Invoice payments is not the same as invoice total",
		package_price_should_be_less_than_invoice_total: "Package price should be less than invoice total",
		edit_service: "Edit Service",
		select_package_expiry: "Select Package Expiry",
		days: "Days",
		select_date_manually: "Select Date Manually",
		employees_information: "Employees Informations",
		send_whatsapp: "Send Whatsapp",
		disable_employee: "Disable Employee",
		employee_timing: "Employee Timing",
		remaining_amount_will_be_registered_in_receivable: "Remaining amount will be registered in receivable",
		no_show: "No Show",
		pos: "POS",
		today: "Today",
		yesturday: "Yesturday",
		whats_up_today: "What's up Today",
		booking: "Booking",
		profit_and_loss: "Profit and Loss",
		profit: "profit",
		loss: "Loss",
		net_vat: "Net VAT",
		collected_vat: "Collected VAT",
		due_on_goverment: "Due On Geverment",
		due_to_goverment: "Due To Geverment",
		search_by_mobile_or_name: "Search by Mobile or Name...",
		invoice_added_successfully: "Invoice Issued Successfully",
		invoice_edited_successfully: "Invoice Edited Successfully",
		order_submitted_successfully: "Order Submitted Successfully",
		my_profile: "My Profile",
		job_profile: "Job Profile",
		my_employee_profile: "Employee Profile",
		change_password: "Change Password",
		custom_range: "Custom Range",
		yesterday: "Yesterday",
		last_quarter: "Last Quarter",
		advanced: "Advanced",
		bank_status: "Bank Status",
		create_invoice: "Create Invoice",
		create_contract: "Create Contract",
		appointment: "Appointment",
		block_time: "Block Time",
		cancelled: "Cancelled",
		scheduled: "Scheduled",
		check_in: "Check-in",
		acheived: "Acheived",
		frequent: "Frequent",
		missed: "Missed",
		new: "New",
		end_time: "End Time",
		is_required: "is Required",
		apply: "Apply",
		show_alone: "Show Alone",
		show_all: "Show All",
		am: "AM",
		pm: "PM",
		groups_and_services: "Groups & Services",
		selected_appointments: "Selected Appointments",
		minutes_short: "Min",
		by: "By",
		search_customer: "Search Tenant",
		appointment_added_successfully: "Appointment Added Successfully",
		check_in_now: "Check-in Now",
		summary: "Summary",
		invoices: "Invoices",
		no_email: "No Email",
		select: "Select",
		customer: "Tenant",
		system: "System",
		online: "Online",
		showing: "Showing",
		records: "Records",
		employee_changed: "Employee Changed",
		expired: "Expired",
		id: "ID",
		used_gifts_cards: "Used Gifts Cards",
		whatsapp_marketing: "Whatsapp Marketing",
		reset_all: "Reset All",
		active: "Active",
		unpaid: "Unpaid",
		paid: "Paid",
		waiting: "Waiting",
		used: "Used",
		authorization_code: "Authorization Code",
		add_deposit: "Add Deposit",
		amount: "Amount",
		payment: "Payment",
		customer_form: "Tenant Form",
		balance: "Balance",
		loyality: "Loyality",
		package_expiry: "Package Expiry",
		appointments_controller: "Appointments Controller",
		checkout: "Checkout",
		cancel_all: "Cancel All",
		add_more_services: "Add More Services",
		daily_activity_logs: "Daily Activity Logs",
		redeemed_amount: "Redeemed Amount",
		payments_details: "Payments Details",
		access_level: "Access Level",
		control_access: "Control Access",
		role: "Role",
		edit_working_hours: "Edit Working Hours",
		stock_operations: "Stock Operations",
		change_question: "Change?",
		new_experience_for_salon_management: "New experience for properties management",
		sell_without_stocks: "Sell without Stocks",
		welcome: "Welcome",
		expired_gifts_cards: "Expired Gifts Cards",
		prefrered_language: "Preferred Language",
		left_working: "Left Working",
		if_you_need_more_info_please_check_out: "If you need more info, please check out",
		save_and_start: "Save And Start",
		please_wait_three_dots: "Please Wait...",
		data_saved_successfully: "Data Saved Successfully",
		backup: "Backup",
		backup_account_data: "Backup Account Data",
		email_it_now: "Email it now",
		categories: "Categories",
		services_categories: "Services Categories",
		maintenance_categories: "Maintenance Categories",
		data_removed_successfully: "Data Removed Successfully",
		are_you_sure_you_want_to_remove_the_contract: "Are you sure you want to remove the contract?",
		remove_contract: "Remove Contract",
		maintenance_services: "Maintenance Services",
		offers: "Offers",
		menu: "Menu",
		preferred_language: "Preferred Language",
		add_offer: "Add Offer",
		renew: "Renew",
		disable_offer: "Disable Offer",
		renew_offer: "Renew Offer",
		are_you_sure_you_want_to_renew_the_offer: "Are you sure you want to renew_the_offer?",
		offer_expiry: "Offer Expiry",
		select_offer_expiry: "Select Offer Expiry",
		offer_service_usage: "Offer Service Usage",
		add_custom_category: "Add Custom Category",
		add_category: "Add Category",
		edit_category: "Edit Category",
		disable_category: "Disable Category",
		invoice_gift_card_value_more_than_invoice_total_warning:
			"Gift card value is more than total sales, add more services to be able to use this gift card",
		suggest_development: "Suggest Development",
		home: "Home",
		wizard: "Wizard",
		select_category: "Select Category",
		add_custom_service: "Add Custom Service",
		coming_soon: "Coming Soon",
		expired_gift_card: "Expired Gift Card",
		used_gift_card: "Used Gift Card",
		legacy_version: "Legacy Version",
		invoice_logo: "Invoice Logo",
		on: "On",
		off: "Off",
		menu_off: "Menu Off",
		menu_on: "Menu On",
		setup_menu_now: "Setup Menu Now",
		business_setup_guide: "Business Setup Guide",
		owner_email: "Owner Email",
		salon_name: "Estate Company Name",
		request_free_trial: "Request Free Trial",
		login_by_email: "Login By Email",
		login_by_account_id: "Login By Account ID",
		thank_you_for_your_time: "Thank you for your time!",
		request_sent_successfully: "Request sent successully",
		we_will_send_you_instructions_to_get_started_via_email_or_whatsapp_within_24_hours:
			"We will send you instructions to get started via email or whatsapp within 24 hours",
		privacy_policy: "Privacy Policy",
		features: "Features",
		programs: "Programs",
		beauty_salons_management_system: "Properties Management System",
		contact_us: "Contact Us",
		website_login: "Login",
		any_time_any_device_any_where: "Any Time, Any Where, Any Device",
		cloud_based_software_for_beauty_salon: "Cloud Based Software for Properties Managment",
		cloud_based_software: "Cloud Based Software",
		for_beauty_salon: "Properties Management Software For Real Estate",
		smart_simple_secure: "Smart. Simple. Secure",
		enhance_salon_and_business_operations_with_salonaty: "Enhance Business Operations with AqarBox",
		customers_support: "Tenants Support",
		rules_and_permissions: "Rules and Permissions",
		reports_and_analysis: "Reports and Analysis",
		secure_cloud_system: "Secured Cloud Systems",
		by_checking_this_box_i_confirm_that_i_have_read_understood_and_agree_to_the:
			"By checking this box, I confirm that I have read, understood and agree to the",
		by_checking_this_box_you_agree_with_the_handling_business_data_in_accordance_to_our:
			"also I agree with the storage and handling of your data by this website in accordance with our",
		all_rights_reserved: "All Rights Reserved",
		terms_and_conditions: "Terms & Conditions",
		ultra_cloud_information_technologies: "Ultra Cloud Information Technologies",
		more: "More",
		salonaty_connect: "AqarBox Connect",
		main_office: "Main Office",
		uae: "UAE",
		ksa: "KSA",
		kuwait: "Kuwait",
		later: "Later",
		salonaty_management_software_enhance_business_opearations_and_allow_oweners_and_decision_maker_complete_overview:
			"AqarBox management software enhance business opearations and allow oweners and decision maker complete overview",
		it_include_online_booking_digital_menu_home_services_booking_social_media_links_and_others:
			"It includes online booking, digital menu (either from link or QR), Home services booking, Social media links, and others.",
		our_services: "Our Services",
		technical_support_and_backup: "Technical Support & backup",
		our_team_consist:
			"Our Team have an experience for more than 15 years in business software development, and we are train Tenants on how to use software, and taking a daily backup to avoid lossing tenants data.",
		salonaty_is_cloud_base_software:
			"AqarBox is cloud base software which cover all business needs, & our solutions are saving huge cost of software development, infrastrcutres, maintenance & technical support cost, it also include the following feautures: ",
		respoinsive: "Responsive for screen device",
		online_software: "Online Software",
		multi_languages_support_arabic: "Multi languages & Support Arabic",
		send_sms_through_whatsapp: "Send SMS Through Whatsapp",
		appointment_system: "Appointment System",
		accounting_and_vat_calculations: "Accounting & VAT Calculations",
		customers_survey_system: "Tenants Survey System",
		daily_and_backup_reports: "Daily & Backup Reports",
		respoinsive_descriptions:
			"All programs developed by us are built to be compatible with different screen sizes, so you can use them either from a mobile phone or a computer screen.",
		online_software_descriptions:
			"The programs that we develop can work on them from the cloud internet from anywhere in the world, so you have no limits to manage your business wherever you are and at any time.",
		roles_and_permissions_descriptions:
			"You can simply control the access rights of the employees, and grant the appropriate permissions to each of the employees according to his job grade and the information that is allowed to be viewed.",
		multi_languages_support_arabic_descriptions:
			"Our programs automatically contain English and Arabic, so regardless of the languages ​​and skills your employee holds. Our programs will suit everyone.",
		send_sms_through_whatsapp_descriptions:
			"Our programs will solve for you the issue of communicating with tenants for issues of correspondence or reminders using the famous WhatsApp platform, as it is secured, fully encrypted.",
		appointment_system_descriptions:
			"The software contains an advanced reservation system, easy to use, with a clear schedule that will cover all your appointment scheduling needs",
		accounting_and_vat_calculations_descriptions:
			"Get a detailed report of sales and payment methods for tenants with their debts and all your accounting needs for daily and monthly sales, as well as calculating sales tax on goods sold.",
		customers_survey_system_descriptions:
			"Our software contains an electronic system for tenant questionnaires, to find out their satisfaction with the services or goods provided by the work team, as well as to receive complaints in a modern manner.",
		daily_and_backup_reports_descriptions:
			"You will automatically receive daily reports to your email inbox to keep you up to date on all your business matters, sales volumes, payments and business opportunities.",
		beauty_salons_management_system_part1:
			"The integrated program for managing properties, AqarBox, gives the management and owners of real estates a complete and integrated management of their business, with ease and convenience, as the program contains reports that the company needed. It also contains tenant invoices and payments (cash, or credit card), value-added tax accounts, and any other operations needed by the business.",
		beauty_salons_management_system_part2:
			"AqarBox is designed to meet the needs of managing properties, and works in both Arabic and English languages. It is quick to set up and easy to use, and you can work on it within minutes. AqarBox is not just an ordinary program, it is the first and best business choice, to manage and automate the work of real estates",
		try_software_for_seven_days_free: "Try Software for Seven Days Free",
		training_descriptions:
			"We believe that watching video is the best way to learn and train, and that effective global best practices for training are by watching short videos of specific treatments in the software system.",
		already_have_an_account: "Already Have An Account?",
		valid: "Valid",
		send_message: "Send Message",
		contact_us_message: "Message",
		now_you_can_contact_us_any_time: "Now .. you can contact us any time",
		commercial_partners_in_gulf: "Commercial partners in GULF",
		telephone: "Telephone",
		faq: "Frequent Asked Questions",
		we_received_your_message: "We received your message",
		our_team_will_contact_you_soon: "Our team will contact you soon",
		beauty_salon_system: "Real Estate system",
		beauty_salon_casheir: "Real Estate Cashier",
		salons_software: "Salons Software",
		woman_salon_software: "Woman Salon Software",
		digital_menu_salon: "Digital Menu Salon",
		beauty_salon_management_software: "Real Estate Management Software",
		woman_salon_cashier: "Woman Salon Cashier",
		arabic_salon_software: "Arabic Salon Software",
		free_salon_software: "Free Salon Software",
		salon_appointment_software: "Salon Appointment Software",
		woman_salon_system: "Woman Salon system",
		woman_salon_application: "Salon Application",
		salon_software: "Salon Software",
		business_ready: "Business Ready",
		wrong_number: "Wrong Number",
		online_services: "Online Services",
		english_qr: "English QR",
		arabic_qr: "Arabic QR",
		business_alias: "Business Alias",
		disable_online_setting: "Disable Online Setting",
		alias_name_will_be_deleted_so_that_it_can_be_used_by_another_salon: "Your alias name will be deleted and can be used by another salon",
		your_online_services_will_be_disabled_if_you_want_to_change_the_alias_name_please_contact_support:
			"Your online services will be disabled, if you want to change the alias name please contact support",
		activate_online_setting: "Activate Online Setting",
		arabic_link: "Arabic Link",
		english_link: "English Link",
		price_list: "Price List",
		incomplete_number: "Incomplete Number",
		percentage_can_not_be_zero_when_status_is_active: "Percentage Can't be zero when status is active",
		new_admin_must_be_in_salon_employees_and_must_have_admin_privileges: "New Admin must be in salon employees and must have admin privileges",
		show_price_list: "Show Price List",
		show_price_include_vat_message: "Show Price Include VAT Message",
		show_offers: "Show Offers",
		press_ctrl_with_plus_or_minus_to_adjust_the_screen: "Press (CTRL +) or (CTRL -) to adjust program with your screen",
		show_menu: "Show Menu",
		online_booking: "Online Booking",
		complains: "Complaints & Suggestions",
		home_services: "Home Services",
		link: "Link",
		edit_customer: "Edit Tenant",
		add_note: "Add Note",
		edit_note: "Edit Note",
		working: "Working",
		activated_products: "Activated Products",
		not_exist_request_define_product: "Not Exist, Request Define Product?",
		active_products: "Active Products",
		request_define_product: "Request Define Product",
		proceed: "Proceed",
		add_expense: "Add Expense",
		time: "Time",
		i_do_not_want_to_add_expense: "I do not want to add expense",
		move_up: "Move Up",
		move_down: "Move Down",
		appointments_whatsapp: "Appointments Whatsapp",
		offers_whatsapp: "Offers Whatsapp",
		alias_name: "Alias Name",
		social_media: "Social Media",
		google_maps: "Google Maps",
		facebook_page_id: "Facebook Page ID",
		owner: "Owner",
		this_will_change_all_services_and_products_prices_according_to_the_new_vat:
			"This will change all services and products prices according to the new VAT",
		customer_added_successfully: "Tenant Added Successfully",
		employee_form: "Employee Form",
		online_setting_privacy_policy_for_web_portal:
			"Online portal for Partner “AqarBox Connect” is an appendix to main privacy policy located in the AqarBox.com “site”.By accepting this appendix, (the Partner/s)are authorizing AqarBox as a platform to show public users (the Tenant/s) some business information such a (Salon Services & Prices, Offers, Working Hours, Social media links) & give them access to (Book an Appointment, Request a Home services, Send an suggestion or Complaint to business management).(The Partner/s) can use both QR Code and Web link in their websites, social media pages, Reception area to show their tenants chosen information as per their selection in “online setting option”.",
		online_setting_privacy_policy_aknowledge:
			"You acknowledge that you have read AqarBox “site” Privacy Policy and agree to all of its terms and conditions, as well as, you acknowledge that you aware of online portal privacy policy“AqarBox Connect ”& aware that information’s mentioned above will be accessed by (tenant/s) from AqarBox website, AQARBOX IOS App,AQARBOX Android App.By continuing to use the Site and Services, you agree to be bound by this policy. If you do not agree to be bound by this Policy, you are not authorized to use or access the AQARBOX site.",
		apply_timing_for_all_days: "Apply timing for all days",
		screen_adjust: "Screen Adjust",
		coinsedence: "Coinsendence",
		primary_informations_updated_successfully: "Primary informations updated successfully",
		tax_updated_successfully: "Tax updated successfully",
		tax_status_updated_successfully: "Tax status updated successfully",
		new_updated_business_timing_will_be_applied_to_all_employees_working_hours:
			"New/Updated Business timing will be applied to all Employees Working Hours",
		new_updated_business_timing_will_be_applied_appointment_screen: "New/Updated Business iiming will be applied on Appointment Screen",
		working_hours_updated_successfully: "Working hours updated successfully",
		employee_added_successfully: "Employee Added Successfully",
		services_activated_successfully: "Services Activated Successfully",
		select_group: "Select Group",
		there_are_services_not_saved_do_you_want_to_save_them_before_change_group:
			"There are services not saved, do you want to save them before change group?",
		in: "in",
		email_should_be_correct: "Email should be correct",
		style: "Style",
		background_image: "Background Image",
		primary_color: "Primary Color",
		add_role: "Add Role",
		edit_role: "Edit Role",
		view_role: "View Role",
		view: "View",
		no_access: "No Access",
		profile_and_activities: "Profile & Activities",
		full_access: "Full Access",
		cashier: "Cashier",
		sell_without_stock: "Sell Without Stock",
		i_would_like_to_book_home_service_please: "I would like to book home service please",
		complain_sent_successfully: "Complaint sent successfully",
		book_appointment: "Book Appointment",
		telephone_should_start_with_plus_or_zero_zero: "Telephone should start with (+ or 00)",
		whatsapp_should_not_start_with_plus_or_zero_zero: "Whatsapp should not start with (+ or 00)",
		username_is_incorrect: "Username is incorrect",
		password_is_incorrect: "Password is incorrect",
		should_be_a_number: "should be a number",
		should_be_an_integer: "should be an integer",
		should_be_more_than: "should be more than",
		should_be_less_than: "should be less than",
		should_be_letters: "should be letters",
		should_be_more_than_or_equal_to: "should be more than or equal to",
		should_be_less_than_or_equal_to: "should be less than or equal to",
		should_be_a_number_with_max_two_decimals: "should be a number with max 2 decimals",
		should_contain_only_digits: "should contain only digits",
		should_be_same_as: "should be same as",
		should_be_after: "should be after",
		should_be_after_or_equal: "should be after or equal",
		should_be_before_or_equal: "should be before or equal",
		should_be_multiple_of: "should be multiple of",
		length_should_be_maximum: "length should be maximum",
		length_should_be: "length should be",
		should_contain_only_numbers_zero_to_nine_and_english_letters_small_capital: "should contain only number (0 - 9) and english letters (a-z)(A-Z)",
		prefix: "Prefix",
		is_email_field: "is email field",
		this_field_should_be_email: "This field should be email",
		access: "Access",
		allow_change_price_in_invoice: "Allow Change Price In Invoice",
		customers_care_services: "Tenants Care Services",
		customers_care: "Tenants Care",
		others: "Others",
		sell: "Sell",
		sell_gift_card: "Sell Gift Card",
		gift_card_value: "Gift Card Value",
		country_of_residency: "Country Of Residency",
		you_are_connected_to: "You are connected to",
		current_password: "Current Password",
		new_password: "New Password",
		confirm_new_password: "Confirm New Password",
		i_want_to_add_expense: "I want to add expense",
		quantity_added_successfully: "Quantity Added Successfully",
		stock_operations_report: "Stock Operations Report",
		quantity_adjusted_successfully: "Quantity Adjusted Successfully",
		quantity_used_successfully: "Quantity Used Successfully",
		create_credit_note: "Create Credit Note",
		credit_note: "Credit Note",
		service_on_item: "Service / Item",
		qty: "Qty",
		returned_value_including_vat: "Returned Value Including VAT",
		i_want_to_generate_manual_credit_note: "I want to generate manual credit note",
		edit_payment: "Edit Payment",
		business_name: "Business Name",
		daily_reports: "Daily Reports",
		remove_appointment: "Remove Appointment",
		are_you_sure_you_want_remove_the_appointment: "Are you sure you want to remove the appointment?",
		are_you_sure_you_want_to_deposit_amount_to_this_customer: "Are you sure you want to deposit ({amount}) to this tenant?",
		are_you_sure_to_add_penalty_with_amount_to_this_customer: "Are you sure you want to add penalty with ({amount}) to this tenant?",
		deposit: "Deposit",
		customer_edited_successfully: "Tenant edited successfully",
		gifts_cards_used: "Gifts Cards Used",
		gift_card: "Gift Card",
		custom: "Custom",
		allow_add_free_gift_card: "Allow Add Free Gift Card",
		add_free_gift_card: "Add Free Gift Card",
		add_expenses_type: "Add Expense Type",
		add_supplier: "Add Supplier",
		expense_type_added_successfully: "Expense Type Added Successfully",
		supplier_added_successfully: "Supplier Added Successfully",
		expense_type_status_updated_successfully: "Expense Type Status Updated Successfully",
		expense_type_already_exist: "Expense Type Already Exist",
		supplier_status_updated_successfully: "Supplier Status Updated Successfully",
		supplier_already_exist: "Supplier Already Exist",
		supplier_edited_successfully: "Supplier Edited Successfully",
		expense_type_edited_successfully: "Expense Type Edited Successfully",
		edit_expense_type: "Edit Expense Type",
		edit_supplier: "Edit Supplier",
		employee_profile_updated_successfully: "Employee Profile Updated Successfully",
		pay_pending: "Pay Pending",
		pay_now: "Pay Now",
		add_payment: "Add Payment",
		payment_added_successfully: "Payment Added Successfully",
		partially_paid: "Partially Paid",
		not_paid: "Not Paid",
		offer_added_successfully: "Offer Added Successfully",
		offer_edited_successfully: "Offer Edited Successfully",
		some_block_times_conflict_with_previous_block_times: "Some block times conflict with previous block times",
		block_times_added_successfully: "Block Times added successfully",
		some_employees_are_not_allowed: "Some employees are not allowed",
		employee_does_not_have_email: "Employee does not have email",
		email_sent_successfully: "Email sent successfully",
		block_time_cancelled_successfully: "Block Time cancelled successfully",
		service_added_successfully: "Service Added Successfully",
		customer_has_another_appointment_at_the_same_time: "Tenant has another appointment at the same time",
		you_have_another_appointment_at_the_same_time: "You have another appointment at the same time",
		please_add_at_least_one_service_to_the_appointment: "Please add at least 1 service to the appointment",
		employee_has_another_appointment_at_the_same_time: "Employee has another appointment at the same time",
		some_appointments_conflict_with_block_times: "Some appointments conflict with block times",
		employee_changed_successfully: "Employee changed successfully",
		employee_is_the_same_as_old_employee: "Employee is the same as old employee",
		employee_not_exist: "Employee not exist",
		item_not_exist: "Item not exist",
		changed: "Changed",
		edit_gift_card: "Edit Gift Card",
		gift_card_edited_successfully: "Gift Card Edited Successfully",
		reset_all_customers: "Reset All Tenants",
		are_you_sure_you_want_to_reset_the_sent_flag_for_all_customers: "Are you sure you want to reset the sent flag for all tenants?",
		sent_status_reseted_successfully: "Sent status reseted successfully",
		customer_status_updated_successfully: "Tenant status updated successfully",
		employee_stauts_updated_successfully: "Employee status updated successfully",
		employee_access_edited_successfully: "Employee access edited successfully",
		employee_edited_successfully: "Employee edited successfully",
		new_updated_timing_will_be_applied_to_the_selected_employee_in_appointment_screen:
			"New/Updated timing will be applied to the selected employee in appointment screen",
		new_updated_timing_will_be_applied_to_the_selected_employee_in_online_booking_screen:
			"New/Updated timing will be applied to the selected employee in online booking screen",
		add_holiday: "Add Holiday",
		holiday_added_successfully: "Holiday Added Successfully",
		edit_holiday: "Edit Holiday",
		holiday_edited_successfully: "Holiday Edited Successfully",
		holiday_deleted_successfully: "Holiday Deleted Successfully",
		note_added_successfully: "Note Added Successfully",
		note_edited_successfully: "Note Edited Successfully",
		note_deleted_successfully: "Note Deleted Successfully",
		role_added_successfully: "Role Added Successfully",
		role_edited_successfully: "Role Edited Successfully",
		credit_note_created_successfully: "Credit Note Created Successfully",
		new_supplier: "New Supplier",
		new_expense_type: "New Expense Type",
		expense_added_successfully: "Expense Added Successfully",
		payment_edited_successfully: "Payment Edited Successfully",
		billing: "Billing",
		category_edited_successfully: "Category Edited Successfully",
		remove_category_and_it_is_services_from_billing_screen: "Remove category & its services from billing screen",
		remove_category_and_it_is_services_from_appointment_screen: "Remove category & its services from appointment screen",
		remove_category_and_it_is_services_from_menu_screen: "Remove category & its services from menu screen",
		remove_services_within_group_from_assigned_employee: "Remove services within group from assigned employee",
		hide_category_from_customers: "Hide category from tenants",
		status_updated_successfully: "Status Updated Successfully",
		show_to_customer_updated_successfully: "Show to tenant updated successfully",
		order_updated_successfully: "Order Updated Successfully",
		category_added_successfully: "Category Added Successfully",
		category_already_exist: "Category Already Exist",
		category_exist_in_categories_to_activate_you_can_activate_it_from_there: "Category exist in categories to activate, you can activate it from there",
		service_edited_successfully: "Service Edited Successfully",
		remove_service_from_billing_appointment_menu_screens: "Remove service from (Billing, Appointment, Menu) screens",
		remove_service_from_assigned_group: "Remove service from assigned group",
		remove_service_from_assigned_employee: "Remove service from assigned employee",
		hide_service_from_customers: "Hide service from tenants",
		remove_offer_from_billing_menu_screens: "Remove offer from (Billing & Menu) screens",
		hide_offer_from_customers: "Hide offer from tenants",
		stock: "Stock",
		product_edited_successfully: "Product Edited Successfully",
		disable_product: "Disable Product",
		disable_selling_adding_adjusting_withdraw_from_inventory: "Disable selling, adding, adjusting, withdraw from inventory",
		product_status_updated_successfully: "Product status updated successfully",
		product_defined_successfully: "Product Defined Successfully",
		image_updated_successfully: "Image Updated Successfully",
		image_deleted_successfully: "Image Deleted Successfully",
		controller_updated_successfully: "Controller Updated Successfully",
		account_backup_sent_successfully_to: "Account backup sent successfully to",
		payment_type_status_updated_successfully: "Payment type status updated successfully",
		trn_number_should_be_15_characters: "TRN number should be 15 characters",
		everything: "Everything",
		discount_program_edited_successfully: "Discount program edited successfully",
		discount_program_already_exist: "Discount program already exist",
		discount_program_added_successfully: "Discount program added successfully",
		discount_program_status_updated_successfully: "Discount program status updated successfully",
		loyality_program_edited_successfully: "Loyality program edited successfully",
		loyality_program_status_updated_successfully: "Loyality program status updated successfully",
		invoice_setting_controller_edited_successfully: "Invoice setting controller edited successfully",
		logo_edited_successfully: "Logo Edited Successfully",
		logo_deleted_successfully: "Logo Deleted Successfully",
		account_not_exist: "Account not exist",
		excel_is_not_correct: "Excel is not correct",
		name_is_required: "Name is required",
		name_should_be_letters: "Name should be letters",
		mobile_is_required: "Mobile Required",
		mobile_is_not_correct: "Mobile is not correct",
		mobile_already_exist_in_the_account_with_customer_name: "Mobile aready exist in the account with tenant name",
		mobile_already_exist_in_the_sheet_with_customer_name: "Mobile aready exist in the sheet with tenant name",
		customers_uploaded_successfully: "Tenants uploaded successfully",
		invoice_setting_header_and_footer_edited_successfully: "Invoice setting header and footer edited successfully",
		online_setting_disabled_successfully: "Online setting disabled successfully",
		online_setting_activated_successfully: "Online setting activated successfully",
		price_list_edited_successfully: "Price list edited successfully",
		online_booking_edited_successfully: "Online booking edited successfully",
		social_media_edited_successfully: "Social media edited successfully",
		complains_edited_successfully: "Complaints Edited Successfully",
		working_hours_edited_successfully: "Working Hours Edited Successfully",
		home_services_edited_successfully: "Home services edited successfully",
		style_edited_successfully: "Style Edited Successfully",
		background_image_edited_successfully: "Background image edited successfully",
		background_image_deleted_successfully: "Background image deleted successfully",
		online_setting_not_exist: "Online setting not exist",
		notification_setting_edited_successfully: "Notification setting edited successfully",
		body: "Body",
		some_data_not_allowed: "Some data not allowed",
		edit_appointment_sms: "Edit Appointment SMS",
		edit_offers_whatsapp: "Edit Offers Whatsapp",
		start_date: "Start Date",
		end_date: "End Date",
		url: "URL",
		appointment_cancelled_successfully: "Appointment Cancelled Successfully",
		appointments_cancelled_successfully: "Appointments Cancelled Successfully",
		appointment_attended_successfully: "Appointment Attended Successfully",
		cancel_appointments: "Cancel Appointments",
		are_you_sure_you_want_to_cancel_the_appointments: "Are you sure you want to cancel the appointments?",
		suggest_development_sent_successfully: "Suggest development sent successfully",
		current_password_is_incorrect: "Current password is incorrect",
		password_changed_successfully: "Password Changed Successfully",
		sorry_there_is_an_error_happened: "Sorry there is an error happened",
		send_to_buyer: "Send To Buyer",
		send_to_beneficiary: "Send To Beneficiary",
		gift_card_logo: "Gift Card Logo",
		from_salon: "From Salon",
		beneficiary: "Benificiary",
		this_payment_was_already_cancelled: "This payment was already cancelled",
		only_deposit_payments_can_be_cancelled: "Only deposit payments can be cancelled",
		data_was_not_updated_successfully: "Data was not updated successfully",
		payment_cancelled_successfully: "Payment cancelled successfully",
		payment_paid_successfully: "Payment Paid Successfully",
		authorization_code_is_required_when_the_payment_is_credit_card: "Authorization Code is required when there is credit card payment",
		customer_does_not_have_enough_balance: "Tenant does not have enough balance",
		payment_method_not_exist: "Payment method not exist",
		payment_method_not_changed: "Payment method not changed",
		should_be_letters_and_numbers: "should be letters and number",
		payment_not_exist: "Payment not exist",
		payment_status_not_allowed_to_cancel: "Payment stauts not allowed to cancel",
		service_added_sucessfully: "Service Added Successfully",
		item: "Item",
		all_invoice: "All Invoice",
		part_from_invoice: "Part From Invoice",
		free: "Free",
		daily_reports_sent_successfully: "Daily reports sent successfully",
		complain_send: "Send Complaint",
		seems_there_is_nothing_here: "Seems there is nothing here",
		return_home: "Return Home",
		backup_reports: "Backup Reports",
		tiktok: "TikTok",
		tiktok_should_start_with: "TikTok should start with",
		employee_email_already_exist: "Employee email already exist",
		paid_voucher: "Paid Voucher",
		loyality_voucher: "Loyality Voucher",
		free_voucher: "Free Voucher",
		notifications: "Notifications",
		updates: "Updates",
		issued_gifts_cards: "Issued Gifts Cards",
		is_not_arabic_text: "is not arabic text",
		is_not_english_text: "is not english text",
		password_reset_link_sent_successfully_to_the_email: "Password reset link sent successfully to the email",
		set_new_password: "Set New Password",
		enter_you_new_password: "Enter your new password",
		confirm_password: "Confirm Password",
		submit_send: "Submit",
		password_set_successfully: "Password set successfully",
		prices_are_vat_inclusinve: "Prices are VAT inclusive",
		appointment_rescheduled: "Appointment Rescheduled",
		copy_url_for_share: "Copy URL for share",
		copied_to_clipboard: "Copied to clipboard",
		trn_number_for_ksa_should_start_with_three_and_end_with_three: "TRN number for ksa should start with 3 and end with 3",
		alias_name_is_incorrect: "Alias name is incorrect",
		service_not_exist: "Service not exist",
		services_group_not_exist: "Services  Group not exist",
		please_select_booking_date: "Please select booking date",
		book_today: "Book Today",
		do_you_want_to_add_another_service: "Do you want to add another service?",
		customer_information: "Tenant Information",
		services_are_incorrect: "Services are incorrect",
		employees_are_incorrect: "Employees are incorrect",
		appointment_booked_successfully: "Appointment Booked Successfully",
		add_more: "Add More",
		review_booking: "Review Booking",
		total_services: "Total Services",
		confirm_booking: "Confirm Booking",
		light_primary_color: "Light primary color",
		there_is_no_available_times_for_the_selected_date: "There is no available times for the selected date",
		please_select_another_date: "Please select another date",
		reset: "Reset",
		incorrect_data: "Incorrect Data",
		reset_color: "Reset Color",
		are_you_sure_you_want_to_reset_the_color: "Are you sure you want to reset the color?",
		your_appointment_has_been_booked_successfully: "Your appointment has been booked successfully",
		booking_id: "Booking ID",
		booking_details: "Booking Details",
		please_select_date: "Please Select Date",
		wizard_activate_vat: "Activate VAT",
		review: "Review",
		change_booking_date: "Change Booking Date",
		this_option_will_delete_all_selected_services_from_basket: "This option will delete all selected services from basket",
		remove_last_service: "Remove Last Service",
		this_option_will_return_you_to_home_page: "This option will return you to home page",
		mobile_already_exist: "Mobile already exist",
		employee_mobile_already_exist: "Employee mobile already exist",
		email_already_exist: "Email already exist",
		email_is_not_valid: "Email is not correct",
		edit_customer_email: "Edit Tenant Email",
		customer_email_already_exist: "Tenant email already exist",
		customer_email_edited_successfully: "Tenant email edited successfully",
		customer_mobile_already_exist: "Tenant mobile already exist",
		current_quantity: "Current Quantity",
		powered_by: "Powered By",
		are_you_sure_you_want_to_cancel_the_payment_this_will_change_your_accounting_statements:
			"Are you sure you want to cancel the payment? this will change your accounting statements",
		cancel_payment: "Cancel Payment",
		daily_reports_sent_successfully_to: "Daily reports sent successfully to",
		deposit_return: "Deposit Return",
		to_activate_this_option_paid_amount_should_be_less_than_total_sales: "To activate this option, paid amount should be less than total sales",
		to_activate_this_option_paid_amount_should_be_less_than_total_sales_and_payment_method_is_selected_for_all_payments:
			"To activate this option, paid amount should be less than total sales and payment method is selected for all payments",
		f11_for_full_mode: "F11 for full mode",
		select_date: "Select Date",
		product_request_sent_successfully: "Product request sent successfully",
		product: "Product",
		invoice_not_exist: "Invoice not exist",
		invoice_has_vat_and_can_not_be_cancelled: "Invoice has VAT and can't be cancelled",
		can_not_cancel_invoice_contains_products: "Can't cancel invoice contains products",
		can_not_cancel_sell_gift_card_invoice: "Can't cancel sell gift card invoice",
		invoice_cancelled_successfully: "Invoice cancelled successfully",
		cancel_invoice: "Cancel Invoice",
		are_you_sure_you_want_to_cancel_the_invoice: "Are you sure you want to cancel the invoice?",
		can_not_use_this_gift_card_since_it_is_for_another_customer: "Can't use this gift card since it's for another tenant",
		you_dont_have_permission_to_use_this_screen_please_contact_system_admin:
			"You don't have permission to use this screen, please contact system admin",
		percentage_should_be_zero_when_status_is_disabled: "Percentage should be zero when status is disabled",
		start: "Start",
		all: "All",
		residancy_exipry: "Residancy Expiry",
		service_english_name_is_already_exist_in_active_or_disabled_services: "English service name is already exist in active or disabled services",
		service_arabic_name_is_already_exist_in_active_or_disabled_services: "Arabic service name is already exist in active or disabled services",
		group_english_name_is_already_exist_in_active_or_disabled_services: "English group name is already exist in active or disabled groups",
		group_arabic_name_is_already_exist_in_active_or_disabled_services: "Arabic group name is already exist in active or disabled groups",
		english_name_is_already_exist: "English name is already exist",
		arabic_name_is_already_exist: "Arabic name is already exist",
		change_branch: "Change Branch",
		remember_password: "Remeber Password",
		the_alias_is_not_correct_or_account_is_disabled: "The alias is not correct or account is disabled",
		offer_type: "Offer Type",
		fixed: "Fixed",
		variable: "Variable",
		max_number_of_services_to_use: "Max number of services to use",
		max_number_of_services_to_use_should_be_less_than_count_of_services: "Max number of services to use should be less than count of services",
		max_number_of_services_to_use_is_required: "Max number of services to use is required",
		some_offer_services_are_disabled: "Some offer services are disabled",
		incorrect_parameters_for_offer: "Incorrect parameters for offer",
		offer_not_exist: "Offer not exist",
		offer_is_disabled: "Offer is disabled",
		offer_expired: "Offer expired",
		fixed_services: "Fixed services",
		selection_from_customer: "Selection from tenant",
		owner_name: "Owner Name",
		service_date_and_time: "Service Date & Time",
		this_product_is_already_defined: "This product is already defined",
		this_product_is_already_exist: "This product is already exist",
		there_is_already_a_request_for_this_product: "There is already a request for this product",
		already_defined: "Already defined",
		due_date: "Due Date",
		bill_from: "Bill From",
		expense_type: "Expense Type",
		bill_to: "Bill To",
		past: "Past",
		system_updates: "System Updates",
		offer_price_with_vat_without_vat: "Offer Price (With / Without VAT)",
		sell_price_without_offer: "Sell Price Without Offer",
		some_appointments_to_add_are_at_the_same_time: "Some appointments to add are at the same time",
		appointment_overbooking: "Appointment Overbooking",
		activate_product: "Activate Product",
		please_activate_some_products_for_sell_or_for_internal_use: "Please activate some products for sell or for internal use",
		employee_report: "Employee Report",
		employee_commission_percentage: "Employee Commission Percentage",
		use_package_service: "Use package service",
		reminder_sent: "Reminder Sent",
		refresh_the_page: "Refresh the page",
		full_pay: "Full Payment",
		partial_pay: "Partial Payment",
		remaining_amount: "Remaining Amount",
		this_product_is_already_exist_with_different_name: "This product is already exist with different name",
		old_sales: "Old Sales",
		appointment_not_exist_or_cancelled: "Appointment not exist or cancelled",
		this_product_is_not_exist: "This product is not exist",
		group_exist_in_groups_to_activate_you_can_activate_it_from_there: "Group exist in groups to activate, you can activate it from there",
		group_already_exist: "Group already exist",
		group_not_exist: "Group not exist",
		service_exist_in_services_to_activate_you_can_activate_it_from_there: "Service exist in services to activate. you can activate it from there",
		service_already_exist: "Service already exist",
		your_request_has_been_submitted_successfully_suggested_product_will_be_approved_within_24_hours:
			"Your request has been submitted successfully, suggested product will be approved within 24 hours",
		allow_employee_overbooking: "Allow Employee Overbooking",
		allow_customer_overbooking: "Allow Tenant Overbooking",
		remember: "Remember",
		print_services: "Print Services",
		total_products: "Total Products",
		services_total: "Services Total",
		products_total: "Products Total",
		employee_profile: "Employee Profile",
		invoice_hash: "Invoice #",
		supplier_invoice_date: "Supplier Invoice Date",
		edit_expense: "Edit Expense",
		expense_edited_successfully: "Expense Edited Successfully",
		cashier_summary: "Cashier Summary",
		authorization_code_is: "Authorization Code is",
		are_you_sure_you_want_to_add_the_quantity: "Are you sure you want to add quantity?",
		notify_management: "Notify Management",
		notifications_sent_to_management_successfully: "Notifications sent to management successfully",
		change_order: "Change Order",
		please_add_the_services_that_the_employee_is_fluent_in_from_services_page:
			"Please add the services that the employee are fluent in from services page",
		new_block_times_conflict_with_existing_block_times: "New block times conflict with previous block times",
		new_block_times_conflict_with_existing_appointments: "New block times conflict with existing appointments",
		there_is_a_product_with_this_barcode_is_already_defined: "There is a product with this barcode is already defined",
		there_is_a_product_with_this_barcode_is_already_defined_you_need_to_activate_it:
			"There is a product with this barcode is already defined, you need to activate it",
		services_data_are_incorrect: "Services data are incorrect",
		employees_data_are_incorrect: "Employees data are incorrect",
		cancel_invoice_or_create_credit_note: "Cancel invoice / Create credit note",
		edit_payment_method: "Edit payment method",
		cancel_advanced_payment: "Cancel advanced payment",
		credit: "Credit",
		add_penalty: "Add Penalty",
		penalty_added_successfully: "Penalty Added Successfully",
		customer_no_show: "Tenant No Show",
		new_penalty: "New Penalty",
		penalty: "Penalty",
		account_balance_is_not_enough: "Account balance is not enough",
		penalties_reports: "Penalties Reports",
		customers_penalties_report: "Tenants Penalties Report",
		customers_penalties: "Tenants Penalties",
		customers_offers_report: "Tenants Offers Report",
		remaining_services: "Remaining Services",
		offer: "Offer",
		advanced_credit: "Advanced Credit",
		confirm_appointment: "Confirm Appointment",
		global_appointments_confirm_appointment: "Confirm Appointment",
		global_appointments_unconfirm_appointment: "UnConfirm Appointment",
		other_reasons: "Other Reasons",
		debit_note: "Debit Note",
		gift_card_description: "Gift Card Description",
		payments_total_is_not_the_same_as_gift_card_value: "Payments total is not the same as gift card value",
		training_account: "Training Account",
		appointment_reminder: "Appointment Reminder",
		reschedule_reminder: "Reschedule Reminder",
		rate_us: "Rate us",
		customer_care: "Tenant Care",
		appointments_reminder: "Appointments Reminder",
		no_show_reschedule: "No show reschedule",
		edit_reschedule_appointments_message: "Edit Reschedule Appointments Message",
		edit_salon_rate_message: "Edit Salon Rate Message",
		no_expiry: "No Expiry",
		please_spend_the_current_quantity_and_disable_the_product_later: "Please spend the current quantity and disable the product later",
		export_to_excel_and_print: "Export to excel and print",
		customer_account_balance_reminder: "Tenant account balance reminder",
		edit_customer_account_balance_reminder_sms: "Edit tenant account balance reminder sms",
		customer_account_balance_reminder_sent_successfully: "Tenant account balance reminder sent successfully",
		account_balance: "Account Balance",
		rate_us_for_issued_invoices: "Rate us for issued invoices",
		rate_us_for_services_from_offers: "Rate us for services from offers",
		email_booking_reference: "Email (Optional)",
		expense: "Expense",
		supplier_not_exist: "Supplier not exist",
		expense_type_not_exist: "Expense type not exist",
		show_invoice: "Show Invoice",
		submit_survey: "Submit Survey",
		kindly_rate_our_services: "Kindly rate our services",
		rating: "Rating",
		invoice_rated_successfully: "Invoice Rated Successfully",
		invoice_already_rated: "Invoice Already Rated",
		some_items_not_exist: "Some items not exist",
		no_internet_connection: "No internet connection",
		please_check_and_try_again: "Please check and try again",
		rating_from_5: "ٌRating (From 5)",
		send_survey: "Send Survey",
		send_invoice: "Send Invoice",
		survey_has_been_submitted_successfully: "Survey has been submitted successfully",
		new_customers: "New Tenants",
		another_session_is_already_exist_for_this_user_do_you_want_to_logout_from_it:
			"Another session is already exist for this user, do you want to logout from it?",
		uploaded_file_must_be_one_of_these_extensions: "Uploaded file must be one of these extensions",
		percentage_of_each_employee: "Percentage of each employee",
		employees_performance: "Employees Performance",
		user_is_not_active: "User is not active",
		user_not_exist: "User not exist",
		best_services: "Best Services",
		send_invoice_in_email_to_owner: "Send invoice in email to owner",
		the_session_has_been_expired_or_another_person_logged_in_with_the_same_user_please_login_again: "The session has been expired, please login again",
		another_person_logged_in_with_the_same_user: "Another person logged in with the same user",
		you_are_not_logged_in_please_login_first: "You are not logged in, please login first",
		account_name: "Account Name",
		total_sales_including_vat: "Total Sales Including VAT",
		vat_summary: "VAT Summary",
		net_income: "Net Income",
		general_accounting_report: "General Accounting Report",
		vat_report: "VAT Report",
		vat_report_collected_vat: "Collected VAT",
		paid_vat_expenses: "Paid VAT (Expenses)",
		vat_paid_to_goverment: "VAT Paid to Goverment",
		monthly_income: "Monthly Income",
		most_active_times: "Most Active Times",
		length_should_be_minimum: "Length should be minimum",
		last_year: "Last Year",
		max: "Max",
		last_three_months: "Last 3 Months",
		reminder_sent_successfully: "Reminder Sent Successfully",
		expense_with_same_supplier_invoice_number_and_date_already_exist_are_you_sure_that_you_want_to_continue:
			"Expense with same supplier invoice number and date already exist, are you sure that you want to continue?",
		expected_expense_with_same_supplier_invoice_number_and_date_already_exist_are_you_sure_that_you_want_to_continue:
			"Expected Expense with same supplier invoice number and date already exist, are you sure that you want to continue?",
		all_services: "All Services",
		you_can_choose_services: "You can choose {numberOfServices} services",
		add_item: "Add Item",
		add_custom_business_hours: "Add Custom Business Hours",
		edit_custom_business_hours: "Edit Custom Business Hours",
		custom_business_hours: "Custom Business Hours",
		custom_working_hours_warning: "** Times will be applied on appointment table",
		this_date_already_has_record_you_can_edit_it: "This date already has record, you can edit it",
		custom_working_hours_added_successfully: "Custom working hours added successfully",
		custom_working_hours_edited_successfully: "Custom working hours edited successfully",
		general_business_hours: "General Business Hours",
		orders: "Orders",
		on_hold_report: "On Hold Report",
		order_id: "Order ID",
		cancel_order: "Cancel Order",
		are_you_sure_you_want_to_cancel_the_order: "Are you sure you want to cancel the order?",
		order_cancelled_successfully: "Order Cancelled Successfully",
		employee_performance: "Employee Performance",
		daily_income: "Daily Income",
		show_on_online: "Show On Online",
		hide_from_online: "Hide From Online",
		close: "Close",
		services_without_the: "Services",
		image_should_be_square_width_equal_height: "Image should be square (width = height)",
		image: "Image",
		show_on_online_updated_successfully: "Show on online updated successfully",
		change_image: "Change Image",
		edit_offer: "Edit Offer",
		send_automatic_rating_from_salonaty: "Send automatic rating from AqarBox",
		whatsapp_setting_edited_successfully: "Whatsapp Setting Edited Successfully",
		whatsapp_setting: "Whatsapp Setting",
		edit_whatsapp_setting: "Edit Whatsapp Setting",
		whatsapp_bot: "Whatsapp Bot",
		whatsapp_bot_setting: "Whatsapp Bot Setting",
		disable_employee_will: "Disable employee will:",
		remove_employee_from_billing_screen: "Remove employee from billing screen",
		remove_employee_from_appointments_screen: "Remove employee from appointments screen",
		remove_employee_from_assigned_services: "Remove employee from assigned services",
		disable_employee_access: "Disable employee access",
		activate_whatsapp_bot: "Activate Whatsapp bot",
		disconnected: "Disconnected",
		connected: "Connected",
		connect: "Connect",
		connect_now: "Connect Now",
		please_contact_sales: "Please Contact Sales",
		whatsapp_already_connected: "Whatsapp Already Connected",
		please_activate_two_step_verification_before_connect_to_whatsapp: "Please activate two step verification before connect to Whatsapp",
		in_case_the_number_is_new_please_do_not_send_many_messages_to_prevent_blocking_the_number:
			"In case the number is new, please do not send many messages to prevent blocking the number",
		connect_to_whatsapp: "Connect to Whatsapp",
		you_can_not_use_offers_in_events_and_national_holidays: "You can't use offers in events and national holidays",
		access_inside: "Access",
		failed_to_get_the_qr: "Failed to get the QR",
		failed_to_send_the_message: "Failed to send the message",
		whatsapp_connected_successfully: "Whatsapp Connected Successfully",
		whatsapp_not_connected_successfully: "Failed to connect to Whatsapp",
		whatsapp_message_sent_successfully: "Whatsapp Message Sent Successfully",
		whatsapp_is_not_connected: "Whatsapp is not connected",
		you_do_not_have_valid_whatsapp_package_please_contact_sales: "You do not have valid Whatsapp package, please contact sales",
		sent_updated_successfully: "Sent Updated Successfully",
		please_connect_to_whatsapp_from_notifications_setting_screen: "Please connect to Whatsapp from notifications setting screen",
		send_message_in_arabic: "Send Message in Arabic",
		send_message_in_english: "Send Message in English",
		one_of_the_messages_english_or_arabic_should_be_activated: "One of the messages (english or arabic) should be activated",
		is_not_arabic_or_english_text: "is not arabic or english text",
		should_be_either_arabic_or_english_text_or_numbers: "should be either (arabic or english) letters or numbers",
		your_package_is_paused_please_activate_it_from_notification_setting_screen:
			"Your package is paused, please activate it from notification setting screen",
		resume_package: "Resume Package",
		are_you_sure_you_want_to_resume_the_package: "Are you sure you want to resume the package?",
		package_resumed_successfully: "Package resumed successfully",
		pause: "Pause",
		resume: "Resume",
		package_paused_successfully: "Pacakge paused successfully",
		pause_package: "Pause Package",
		are_you_sure_you_want_to_pause_the_package: "Are you sure you want to pause the package?",
		offered_on_a_monthly_fee_by_third_party_companies_not_affiliated_with_ucitco:
			"Offered on a monthly fee by third party companies not affiliated with UCITCO",
		your_package_has_been_expired_please_contact_sales: "Your package has been expired, please contact sales",
		day_without_the: "Day",
		created_by: "Created By",
		please_try_again_later: "Please try again later",
		send_salon_survey: "Send Salon Survey",
		payments_summary: "Payments Summary",
		paused: "Paused",
		send_issued_invoice_to_customer: "Send issued invoice to tenant",
		send_employees_rating_from_salonaty: "Send employees rating from AqarBox",
		send_appointment_confirmation_to_customer: "Send appointment confirmation to tenant",
		send_appointment_confirmation_to_customer_on_booking_system_or_online: "Send appointment confirmation to tenant on booking (system or online)",
		send_online_appointment_confirmation_to_customer: "Send online appointment confirmation to tenant",
		send_system_appointment_confirmation_to_customer: "Send system appointment confirmation to tenant",
		send_appointment_confirmation_for_bookings_from_online: "Send appointment confirmation for bookings from online",
		send_appointment_confirmation_for_bookings_from_system: "Send appointment confirmation for bookings from system",
		send_salon_rating_to_customer_after_invoice_issue: "Send salon rating to tenant after invoice issue",
		whatsapp_bot_setting_edited_successfully: "Whatsapp Bot setting edited successfully",
		please_enter_a_number_which_has_whatsapp_service_activated_on_it: "please enter a number which has Whatsapp service activated on it",
		include_employees_assessment_link: "Include employees assessment link",
		this_option_is_not_allowed_when_automatic_whatsapp_is_connected: "This option is not allowed when automatic Whatsapp is connected",
		customer_not_exist: "Tenant not exist",
		user_has_no_rights_to_the_system: "User has no rights to the system",
		send_appointment_confirmation: "Send Appointment Confirmation",
		menu_qr: "Menu QR",
		send_automatic_online_booking_confirmation_from_salonaty: "Send automatic online booking confirmation from AqarBox",
		this_option_is_not_allowed_when_automatic_whatsapp_is_connected_or_online_setting_is_disabled:
			"This option is not allowed when automatic Whatsapp is connected or online setting is diasabled",
		it_is_not_allowed_to_change_alias_name_after_selection: "* It's not allowed to change alias name after selection",
		download_graphics_for_reception: "Download graphics for reception",
		basic: "Basic",
		advanced_package: "Advanced",
		premium: "Premium",
		subscription_expired_at: "Subscription expired at",
		expired_at: "Expired at",
		renew_subscription: "Renew subscription",
		allow_sell_products: "Allow Sell Products",
		this_number_does_not_have_active_whatsapp: "Does not have active Whatsapp",
		this_number_have_active_whatsapp: "Have active whatsapp",
		customer_does_not_have_active_whatsapp: "Tenant does not have active Whatsapp",
		activate_customer: "Activate Tenant",
		are_you_sure_you_want_to_activate_the_customer: "Are you sure you want to activate the tenant?",
		customer_activated_successfully: "Tenant activated successfully",
		not_allowed: "Not Allowed",
		check_whatsapp_number_in_process: "Check Whatsapp number in process",
		whatsapp_number_checked_successfully: "Whatsapp number checked successfully",
		verification_code: "Verification Code",
		please_enter_the_code_you_received_on_your_whatsapp: "Please enter the code you received on your Whatsapp",
		resend_code: "Resend code?",
		the_code_you_entered_is_incorrect: "The code you entered is incorrect",
		customer_is_disabled_please_activate_him_first: "Tenant is disabled, please activate him first",
		you_can_not_request_code_in_less_than_1_minute_please_try_again: "You can not request code in less than 1 minutes, please try again",
		added_at: "Added at:",
		ads_campaigns: "Ads Campaigns",
		campaign_period: "Campaign Period",
		customer_type: "Tenant Type",
		campaign_name: "Campaign Name",
		the_code_has_been_expired_we_resend_another_one_to_you: "The code has been expired, we resend another one to you",
		send_otp_for_new_customers: "Send OTP for new tenants",
		server_disconnected: "Server Disconnected",
		server_is_disconnected_please_try_again_later:
			"Whastapp server are disconnected, please try later, you can use manual option till issue solved from supplier",
		this_customer_does_not_allow_whatsapp_contact: "This tenant does not allow WhatsApp contact",
		send_otp_for_new_customers_from_salonaty: "Send OTP for new tenants from AqarBox",
		print_daily_activities_for_employees: "Print daily activities for employees",
		employee_daily_activities: "Employee daily activities",
		campaign_start: "Campaign Start",
		campaign_end: "Campaign End",
		customers_count_total: "Tenants Count Total",
		add_campaign: "Add Campaign",
		done_services: "Done Services",
		starts_with_letter: "Starts With Letter",
		operand: "operand",
		less_than_or_equal: "Less than or equal",
		less_than: "Less than",
		equal: "Equal",
		greater_than: "Greater than",
		greater_than_or_equal: "Greater than or equal",
		starts_with: "Starts With",
		contains: "Contains",
		customers_count_within_campaign: "Tenants count within campaign",
		campaign_content: "Campaign Content",
		customers_count_within_campaign_should_be_more_than_zero: "Tenants count within campaign should be more than 0",
		customers_total_count: "Tenants Total Count",
		success: "Success",
		failed: "Failed",
		sending: "Sending",
		campaign_not_exist_or_not_allowed_to_edit: "Campaign not exist or not allowed to edit",
		temporary_save: "Temporary Save",
		temporary_saved: "Temporary Saved",
		save_draft: "Save Draft",
		can_not_change_the_status_of_this_campaign: "Can't change the status of this campaign",
		status_changed_successfully: "Status Changed Successfully",
		start_sending: "Start Sending",
		disable_campaign: "Disable Campaign",
		when_you_disable_campaign_you_can_not_activate_it_again: "When you disable campaign you can't activate it again",
		report: "Report",
		ads_campaign_report: "Ads Campaign Report",
		drop_file_here: "Drop file here",
		campaign_ready: "Campaign Ready",
		you_can_not_edit_customer_list_after_save: "- You can't edit tenant list after save",
		campaign_added_successfull: "Campaign added successfully",
		customer_selection: "Tenant Selection",
		sent_date_and_time: "Sent date&time",
		campaign_content_edited_successfully: "Campaign content edited successfully",
		google_review: "Google Review",
		product_have_barcode: "Product have barcode",
		product_does_not_have_barcode: "Product does not have barcode",
		expired_only: "Expired",
		only_images_videos_pdf_files_are_supported: "Only image, video, and PDF file are supported",
		file_size_should_be_less_5_mb: "File size should be less than 5 MB",
		please_complete_form_to_calculate_number_of_campaign_customers: "Please complete form to calculate number of campaign tenants",
		start_now: "Start Now",
		max_size_5_mb: "Max size 5 MB",
		pending: "Pending",
		show_customers: "Show Tenants",
		hide_customers: "Hide Tenants",
		adding_date: "Adding Date",
		resend_faild: "Resend Failed",
		there_are_no_failed_customers_for_this_campaign: "There are no failed tenants for this campaign",
		resend_failed_started_successfully: "Resend failed started successfully",
		renew_campaign: "Renew Campaign",
		campaign_renewed_successfully: "Campaign Renewed Successfully",
		send_test: "Send Test",
		campaign_test_send_successfully: "Campaign Test Send Successfully",
		messages_in_the_campaign_are_sent_with_a_time_difference_of_30_to_90_seconds_between_each_client_and_another:
			"- Messages in the campaign are sent with a time difference of 30 - 90 seconds between each client and another",
		the_sending_stops_for_approximately_5_to_15_minutes_after_sending_100_messages:
			"- The sending stops for approximately 5 - 15 minutes after sending 100 messages",
		messages_will_be_sent_between_9am_to_11pm_only: "- Messages will be sent between 9:00 AM to 11:00 PM only",
		serial: "Serial",
		customer_serial: "Tenant Serial",
		full_name: "Full Name",
		first_name: "First Name",
		allow_whatsapp_contact: "Allow Whatsapp Contact",
		we_serve_only_ladies_customers: "We serve only ladies tenants",
		we_serve_only_men_customers: "We serve only men tenants",
		we_serve_only_kids_customers: "We serve only kids tenants",
		campaign_starts_sending_after_approximately_10_minutes_of_starting: "- Campaign starts sending after approximately 10 minutes of starting",
		salonaty_is_not_responsible_of_banning_the_number_from_whatsapp_company_due_to_misuse:
			"- AqarBox is not responsible of banning the number from Whatsapp company due to misuse",
		remove_package: "Remove Package",
		are_you_sure_you_want_to_remove_the_package: "Are you sure you want to remove the package",
		please_connect_your_whatsapp_automatic_whatsapp_is_disconnected: "Please connect your Whatsapp, your Whatsapp is disconnected",
		this_is_only_ladies_salon: "This is only ladies salon",
		this_is_only_men_salon: "This is only men salon",
		this_is_only_kids_salon: "This is only kids salon",
		whatsapp_number_has_been_disconnected_successfully_from_salonaty: "Whatsapp number has been disconnected successfully from AqarBox",
		birth_month: "Birth Month",
		january: "January",
		february: "February",
		march: "March",
		april: "April",
		may: "May",
		june: "June",
		july: "July",
		august: "August",
		september: "September",
		october: "October",
		november: "November",
		december: "December",
		frequent_has_more_than_1_invoice_and_last_invoice_less_than_60_days: "Frequent: has more than 1 invoice and last invoice than less than 60 days",
		missed_has_no_invoices_or_last_invoice_more_than_60_days: "Missed: has no invoices or last invoice more than 60 days",
		new_has_1_invoice_and_last_invoice_less_than_60_days: "New: has 1 invoice and last invoice than less than 60 days",
		new_the_others_are_new_customers: "New: the others are new tenants",
		current_commission: "Current Commission",
		employees_commissions_report: "Employees Commissions Report",
		activities_report_summary: "Activities Report Summary",
		activities_summary: "Activities Summary",
		reception_and_stuff_treatment: "Reception and Stuff Treatment",
		cleanliness_and_place_arrangment: "Cleanliness and Place Arrangment",
		prices: "Prices",
		quality_of_provided_services: "Quality of Provided Services",
		complains_report: "Complaints Report",
		complaints_report: "Complaints Report",
		complaints: "Complaints",
		surveys_report: "Surveys Report",
		remove_connection: "Remove Connection",
		are_you_sure_you_want_to_remove_whatsapp_from_your_account: "Are you sure you want to remove Whatsapp from your account?",
		pdf: "PDF",
		edit_name_request: "Edit Name Request",
		edit_name_request_sent_successfully: "Edit name request sent successfully",
		this_user_is_the_owner_of_this_business_please_contact_with_the_service_provider:
			"This user is the owner of this business\nplease contact with the service provider",
		activities_report_summary_for_services: "Activities Report Summary for Services",
		activities_report_summary_for_products: "Activities Report Summary for Products",
		you_can_request_name_edit_later_from_active_products: "* You can request name edit later from active products",
		edit_appointments: "Edit Appointments",
		all_appointments_in_this_day_will_be_cancelled: "All appointments in this day will be cancelled",
		this_customer_has_unpaid_invoices: "This tenant has unpaid invoices",
		online_review: "Review",
		custom_working_hours_removed_successfully: "Custom working hours removed successfully",
		remove_custom_working_hours: "Remove Custom Working Hours",
		remove_custom_working_hours_group: "Remove Custom Working Hours Group",
		are_you_sure_you_want_to_remove_custom_working_hours: "Are you sure you want to remove custom working hours",
		are_you_sure_you_want_to_remove_custom_working_hours_group: "Are you sure you want to remove custom working hours group",
		note: "Note",
		print_tax_invoice: "Print Tax Invoice",
		internal_use_for_products: "Internal Use for Products",
		sold_products: "Sold Products",
		print_a4: "Print A4",
		services_and_products: "Services & Products",
		if_you_select_then_the_discount_will_be_applied_only_on_the_selected_leave_it_empty_if_you_want_the_discount_to_be_applied_at_all:
			"If you select then the discount will be applied only on the selected, leave it empty if you want the discount to be applied at all",
		services_over_products: "Services/Products",
		account_has_been_disabled: "Account has been disabled",
		account_has_been_expired: "Account has been expired, please contact sales",
		add_edit_note: "Add/Edit Note",
		note_added_edited_successfully: "Note Added/Edited Successfully",
		all_services_and_products: "All Services & Products",
		all_products: "All Products",
		selected_services_and_products: "Selected Services & Products",
		selected_services: "Selected Services",
		selected_products: "Selected Products",
		password_reset_email_sent_successfully_to: "Password reset email sent successfully to",
		reset_password: "Reset Password",
		surveys: "Surveys",
		notification_mark_as_read: "Mark as Read",
		notifications_marked_as_read_successfully: "Notifications Marked as Read Successfully",
		alert: "Alert",
		rate: "Rate",
		achievement: "Achievement",
		invoice_not_exist_or_not_rated: "Invoice not exist or not rated",
		product_is_disabled: "Product is disabled",
		check_in_checkout_report: "Check-in Checkout Report",
		check_in_time: "Check-in Time",
		checkout_time: "Checkout Time",
		are_you_sure_you_want_to_cancel_the_service: "Are you sure you want to cancel the service?",
		cancel_service: "Cancel Service",
		service_cancelled_successfully: "Service Cancelled Successfully",
		accounts_information: "Accounts Information",
		disabled_accounts: "Disabled Accounts",
		expired_accounts: "Expired Accounts",
		birthday_congratulations: "Birthday Congratulations",
		send_birthday_congratulations: "Send Birthday Congratulations",
		whatsapp_packages: "Whatsapp Packages",
		whatsapp_disconnected_packages: "Whatsapp Disconnected Packages",
		whatsapp_disconnected: "Whatsapp Disconnected",
		system_option: "System Option",
		global_aliases: "Global Aliases",
		accounts: "Accounts",
		all_active_employees: "All Active Employees",
		active_employees_with_access: "Active Employees with Access",
		inactive_global_artists: "Inactive Global Artists",
		current_connections: "Current Connections",
		requested_accounts: "Requested Accounts",
		waiting_for_verify: "Waiting for Verify",
		rejected_accounts: "Rejected Accounts",
		all_accounts_requests: "All Accounts Requests",
		added_products: "Added Products",
		edit_products_requests: "Edit Products Requests",
		edit_products: "Edit Products",
		offers_images: "Offers Images",
		instance: "Instance",
		mail_tracker: "Mail Tracker",
		qbot_tracker: "QBot Tracker",
		failed_jobs: "Failed Jobs",
		failed_login_attempts: "Failed Login Attempts",
		telescope: "Telescope",
		js_errors: "JS Errors",
		backup_database: "Backup Database",
		development_suggestions: "Development Suggestions",
		requested: "Requested",
		trackers: "Trackers",
		technical: "Technical",
		system_data: "System Data",
		valid_till: "Valid Till",
		last_login_at: "Last Login At",
		online_url: "Online Url",
		google_index: "Google Index",
		full_backup: "Full Backup",
		number_of_units: "Number of Units",
		number_of_users: "Number of Users",
		login_as_owner: "Login as Owner",
		change_owner: "Change Owner",
		full_backup_to_owner: "Full Backup to Owner",
		start_chat_with_owner: "Start Chat with Owner",
		change_google_index_to_yes: "Change Google Index to Yes",
		change_google_index_to_no: "Change Google Index to No",
		wt_setting: "Whatsapp Setting",
		change_alias: "Change Alias",
		change_to_trial: "Change to Trial",
		change_to_premium: "Change to Premium",
		package: "Package",
		automatic_rating: "Automatic Rating",
		send_otp: "Send OTP",
		automatic_booking_confirmation: "Automatic Booking Confirmation",
		has_wt_package: "Has WT Package",
		upload_customers: "Upload Tenants",
		truncate_failed_jobs: "Truncate Failed Jobs",
		are_you_sure_you_want_to_truncate_failed_jobs: "Are you sure you want to truncate failed jobs?",
		failed_jobs_truncated_successfully: "Failed jobs truncated successfully",
		attendence_system: "Attendence System",
		geolocation_is_not_supported_by_this_browser: "Geolocation is not supported by this browser",
		user_denied_the_request_for_geolocation: "User denied the request for geolocation",
		location_information_is_unavailable: "Location information is unavailable",
		the_request_to_get_user_location_timed_out: "The request to get the user location timed out",
		getting_location: "Getting Location ...",
		check_out: "Check Out",
		operation_done_successfully: "Operation Done Successfully",
		location: "Location",
		get_geolocation: "Get Business Geoocation",
		employees_reports: "Employees Reports",
		attendence_report: "Attendence Report",
		number_of_working_days: "Number of Working Days",
		number_of_working_hours: "Number of Working Hours",
		number_of_hours_at_appointments: "Number of Hours at Appointments",
		employee_attendence_report: "Employee Attendence Report",
		geolocation_updated_successfully: "Geolocation Updated Successfully",
		update_geolocation: "Update Geolocation",
		the_current_location_will_be_took_as_the_geolocation: "The current location will took as Geolocation",
		geolocation_is_not_set_before_you_need_to_set_it_first: "Geolocation is not set before, you need to set it first",
		the_distance_between_you_and_check_in_location_is_more_than_50_meters: "The distance between you and check in location is more than 50 meters",
		please_give_permissions_to_browser_to_access_your_location: "Please give permissions to browser to access your location",
		current_employee: "Current Employee",
		all_business: "All Business",
		block_ip: "Block IP",
		are_you_sure_you_want_to_block_the_ip: "Are you sure you want to block the IP?",
		ip_already_blocked: "IP Already Blocked",
		ip_blocked_successfully: "IP Blocked Successfully",
		blocked: "Blocked",
		unblock_ip: "UnBlock IP",
		are_you_sure_you_want_to_unblock_the_ip: "Are you sure you want to unblock the IP?",
		ip_already_unblocked: "IP Already UnBlocked",
		ip_unblocked_successfully: "IP UnBlocked Successfully",
		ip_is_blocked:
			"You IP has been disabled due to your repeated attempts to log in incorrectly.\nIf hacking attempts on your part are proven, we will inform authorities in the state, to take legal action against you.\nWe have all the data about your geographical location and the device used in the hacking attempts",
		add_as_home_services: "Add As Home Services",
		blocked_ips: "Blocked IPs",
		forecast: "Forecast",
		commulative_attendence_report: "Commulative Attendence Report",
		detailed_attendence_report: "Detailed Attendence Report",
		forecast_daily_sales: "Forecast Daily Sales",
		next_month: "Next Month",
		tomorrow: "Tomorrow",
		next_quarter: "Next Quarter",
		next_three_months: "Next 3 Months",
		next_year: "Next Year",
		completion_period: "Completion Period",
		add_invoice: "Add Invoice",
		edit_invoice: "Edit Invoice",
		form: "Form",
		account_invoices: "Account Invoices",
		request_payment: "Request Payment",
		account_invoice: "Account Invoice",
		owner_email_not_exist: "Owner email not exist",
		owner_mobile_not_exist: "Owner mobile not exist",
		whatsapp_service_is_not_activated_on_owner_mobile: "Whatsapp service is not activated on owner mobile",
		whatsapp_service_is_not_activated_on_bill_to_mobile: "Whatsapp service is not activated on bill to mobile",
		bill_to_email_not_exist: "Bill to email not exist",
		credit_card_payment_type_not_exist: "Credit card payment type not exist",
		change_to_paid: "Change to Paid",
		are_you_sure_you_want_to_change_the_payment_to_paid: "Are you sure you want to change the payment to paid?",
		invoice_is_cancelled: "Invoice is Cancelled",
		total_payments_of_invoice_will_be_more_than_invoice_total: "Total payments of invoice will be more than invoice total",
		payment_status_not_allowed_to_edit: "Payment status not allowed to edit",
		countries: "Countries",
		cities: "Cities",
		marketting: "Marketting",
		active_accounts: "Active Accounts",
		google_index_changed_successfully: "Google Index Changed Successfully",
		owner_changed_successfully: "Owner Changed Successfully",
		whatsapp_setting_changed_successfully: "Whatsapp Setting Changed Successfully",
		alias_name_changed_successfully: "Alias Name Changed Successfully",
		add_account: "Add Account",
		edit_account: "Edit Account",
		approve_account: "Approve Account",
		owner_mobile: "Owner Mobile",
		account_edited_successfully: "Account edited successfully",
		account_package_changed_successfully: "Account package changed successfully",
		account_status_changed_successfully: "Account status changed successfully",
		upload_customers_excel: "Upload Tenants Excel",
		are_you_sure_you_want_to_upload_excel: "Are you sure you want to upload excel",
		customers_invalid_data: "Tenants invalid data",
		row_number: "Row Number",
		errors: "Errors",
		powered_by_ucitco: "Powered by UCITCO",
		payment_done_successfully: "Payment Done Successfully",
		payment_failed: "Payment Failed",
		abu_dhabi: "Abu Dhabi",
		arab_emirates: "Arab Emirates",
		call_us: "Call Us",
		instance_paused_successfully: "Instance Paused Successfully",
		instance_resumed_successfully: "Instance Resumed Successfully",
		instance_removed_successfully: "Instance Removed Successfully",
		main_instance: "Main Instance",
		united_arab_emirates: "United Arab Emirates",
		abu_dhabi_united_arab_emirates: "Abu Dhabi - UAE",
		i_agree_to_try_the_software_for_7_days: "I agree to try the Software for 7 days and know that No refund & Return Policy after subscription",
		key: "Key",
		secret: "Secret",
		payment_gateway_setting: "Payment Gateway Setting",
		company: "Company",
		edit_payment_gateway_setting: "Edit Payment Gateway Setting",
		payment_gateway_setting_updated_successfully: "Payment Gateway Setting Updated Successfully",
		payment_gateway_setting_not_exist: "Payment Gateway Setting Not Exist",
		ultra_cloud: "Ultra Cloud",
		payment_gateway_id: "PW ID",
		english_agreement_details: "English Agreement Details",
		arabic_agreement_details: "Arabic Agreement Details",
		invoice_setting_edited_successfully: "Invoice Setting Edited Successfully",
		bill_from_name: "Bill From Name",
		email_setting: "Email Setting",
		host: "Host",
		mailer: "Mailer",
		port: "Port",
		encryption: "Encryption",
		email_setting_edited_successfully: "Email Setting Edited Successfully",
		email_subject: "Email Subject",
		load_more: "Load More",
		historical_data_for_customers_types: "Historical Data For Tenants Types",
		whatsapp_notification_setting: "Whatsapp Notification Setting",
		email_notification_setting: "Email Notification Setting",
		email_setting_for_sending: "Email Setting for Sending",
		customer_to_business: "Tenant to Business",
		business_to_platform: "Business to Platform",
		platform_to_business: "Platform to Business",
		as: "As",
		logo_uploaded_successfully: "Logo Uploaded Successfully",
		logo_removed_successfully: "Logo Removed Successfully",
		activate_development: "Activate Development",
		stars: "Star",
		contracts: "Contracts",
		add_contract: "Add Contract",
		edit_contract: "Edit Contract",
		contract_to: "Contract To",
		platform_marketting: "Platform Marketting",
		should_contain_only_english_letters_arabic_letters_numbers_and_dash: "Should contain only English letters, Arabic letters, Numbers And -",
		contract_setting: "Contract Setting",
		contract_not_exist: "Contract Not Exist",
		merchant_service_agreement: "Merchant Service Agreement",
		undo: "Undo",
		this_operation_can_not_be_undone_are_you_sure_that_the_signature_is_correct:
			"This operation can't be undone, are you sure that the signature is correct?",
		please_draw_the_signature_first: "Please draw the signature first",
		submit_signature: "Submit Signature",
		signature_is_not_allowed_for_this_contract: "Signature is not allowed for this contract",
		signature_submitted_successfully: "Signature Submitted Successfully",
		to_email_not_exist: "To email not exist",
		account_logo: "Account Logo",
		employee_image: "Employee Image",
		online_background: "Online Background",
		offer_image: "Offer Image",
		images: "Images",
		trial_accounts: "Trial Accounts",
		failed_login: "Failed Login",
		trial_requests: "Trial Requests",
		signed: "Signed",
		change_owner_old: "Change Owner (Old)",
		change_owner_new: "Change Owner (New)",
		owner_confirmed: "Owner Confirmed",
		contract_signed: "Contract Signed",
		invoice_issued: "Invoice Issued",
		invoice_paid: "Invoice Paid",
		one_day: "One Day",
		range: "Range",
		from_date: "From Date",
		to_date: "To Date",
		some_dates_in_period_already_have_records_please_edit_or_delete_them_first:
			"Some dates in period already have records please edit or delete them first",
		the_period_should_be_a_maximum_30_days: "The period should be a maximum 30 days",
		platform_setting: "Platform Setting",
		merchant_extras: "Merchant Extra",
		payments_requests: "Payments Requests",
		website_setting: "Website Setting",
		sm_setting: "SM Setting",
		pg_setting: "PG Setting",
		payments_methods: "Payment Methods",
		platform_updates: "Platform Updates",
		mark_all_as_checked: "Mark All As Checked",
		created_at: "Created at",
		account_english_name: "Account English Name",
		client_ip: "Client IP",
		device_type: "Device Type",
		browser_type: "Browser Type",
		user_agent: "User Agent",
		approve: "Approve",
		reject: "Reject",
		start_Whatsapp: "Start whatsapp",
		reset_password_by_email: "Reset Password by Emails",
		suspend: "Suspend",
		account: "Account",
		old_english_name: "Old English Name",
		old_arabic_name: "Old Arabic Name",
		new_english_name: "New English Name",
		new_Arabic_name: "New Arabic Name",
		active_whatsapp_packages: "Active Whatsapp Packages",
		provided_services: "Provided Services",
		services_groups: "Services Groups",
		mark_as_checked: "Mark As Checked",
		whatsapp_status: "Whatsapp Status",
		merchant_with_wa_package: "Merchant With WA Package",
		validity: "Validity",
		total_messages: "Total Messages",
		merchants_information: "Merchants Information",
		rejected_merchants: "Rejected Merchants",
		all_merchants_requests: "All Merchants Requests",
		subject: "Subject",
		from_name: "From Name",
		updated_at: "Updated At",
		delete_from_yesturday: "Delete From Yesturday",
		merchants: "Merchants",
		merchants_employees: "Merchants Employees",
		active_merchants: "Active Merchants",
		trial_merchants: "Trial Merchants",
		disabled_merchants: "Disabled Merchants",
		expired_merchants: "Expired Merchants",
		check_entries: "Check Entries",
		inactive_global_employees: "Inactive Global Employees",
		added_employees: "Added Employees",
		active_global_employees: "Active Global Employees",
		edited_products: "Edited Products",
		added_images: "Added Images",
		added_groups: "Added Groups",
		added_services: "Added Services",
		marketing_merchants_requests: "Marketing Merchants Requests",
		marketing_active_merchants: "Marketing Active Merchants",
		customers_merchants: "Tenants Merchants",
		employees_merchants: "Employees Merchants",
		customers_active_wa: "Tenants Active WA",
		customers_inactive_wa: "Tenants Inactive WA",
		added_customers: "Added Tenants",
		system_connections: "System Connections",
		other_tools: "Other Tools",
		terms_and_condisions: "Terms & Conditions",
		english_subject: "English Subject",
		arabic_subject: "Arabic Subjects",
		english_description: "English Description",
		arabic_description: "Arabic Description",
		english_question: "English Question",
		arabic_question: "Arabic Question",
		english_answer: "English Answer",
		arabic_answer: "Arabic Answer",
		suggested_groups: "Suggested Groups",
		suggested_services: "Suggested Services",
		products_listed: "Products Listed",
		numbers_of_days: "Number of days",
		number_of_sms: "Number of SMS",
		add_whatsapp_package: "Add Whatsapp Package",
		currency: "Currency",
		contry: "Contry",
		home_page: "Home Page",
		source: "Source",
		user: "User",
		route: "Route",
		error: "Error",
		truncate: "Truncate",
		merchants_marketing: "Merchants Marketing",
		owener_name: "Owener Name",
		owener_mobile: "Owener Mobile",
		allow_customer_to_present: "Allow Tenant to present",
		checked: "checked",
		not_checked: "Not Checked",
		suggested_products: "Suggested Products",
		countries_list: "Countries List",
		cities_list: "Cities List",
		addon_services: "Addon Services",
		numbers_of_sms: "Number of SMS",
		varient: "Varient",
		english_title: "English Title",
		arabic_title: "Arabic Title",
		whatsapp_notifications: "Whatsapp Notifications",
		customer_appointment_confirmation: "Tenant Appointment Confirmations",
		rate_us_for_issued_invoice: "Rate us for issued Invoice",
		gift_card_to_purchaser: "Gift Card to Purchaser",
		gift_card_to_beneficiary: "Gift Card to beneficiary",
		whatsapp_marketting: "Whatsapp Marketing",
		account_balance_reminder: "Account Balance Reminder",
		customer_online_booking_otp: "Tenant Online booking otp",
		offer_remaining_services: "Offer revmaining Services",
		rate_us_for_service_from_offer: "Rate us for service from offer",
		receivable_reminder: "Receivable Reminder",
		campaing_test: "Campaing Test",
		campain_message: "Campain Message",
		merchant_to_customer: "Merchant to tenant",
		merchant_to_employee: "Merchant to Employee",
		platform_to_merchant: "Platform to Merchant",
		platform_to_customer: "Platform to tenant",
		account_contract: "Account Contract",
		account_payment_link: "Account Payment link",
		marketing_requested_merchant: "Marketing Requested Merchant",
		marketing_active_merchant: "Marketing Active Merchant",
		invoice_rating_question: "Invoice Rating Question",
		invoice_rating: "Invoice Rating",
		salon_alias: "Salon Alias",
		appointments_confirmation: "Appointment Confirmation",
		customer_web_invoice: "Tenant Web Invoice",
		customer_online_booking: "Tenant Online Booking",
		create_account: "Create account",
		reject_account: "Reject Account",
		account_online_booking: "Account online Booking",
		invoice_to_owner: "Invoice to Owener",
		free_gift_card_created: "Free Gift Card Created",
		daily_report: "Daily Report",
		account_backup: "Account Backup",
		send_stocks_notifications_to_management: "Send stocks Notification to mamagement",
		platform: "Platform",
		customer_to_merchant: "Tenant to Merchant",
		merchant_to_platform: "Merchant to Platform",
		email_notifications: "Email Notifications",
		customer_complain: "Tenant Complain",
		payment_request: "Payment Request",
		employee_access: "Employee Access",
		send_employee_appointments: "Send Employee Appointments",
		employee_done_services: "Employee Done Services",
		employee_order_services: "Employee order Services",
		manager_email_failed_login_attempt: "Manager Email Failed Login Attempt",
		show_price: "Show Price",
		hide_price: "Hide Price",
		data_updated_successfully: "Data Uploaded Successfully",
		show_on_website: "Show on Website",
		hide_from_website: "Hide from Website",
		secondary_whatsapp_bot: "Secondary Whatsapp Bot",
		platform_to_owner: "Platform to Owner",
		manager_failed_login: "Manager Failed Login",
		delete_role: "Delete Role",
		are_you_sure_you_want_to_delete_the_role: "Are you sure you want to delete the role?",
		please_delete_this_role_from_employees_delete_delete_it: "Please delete this role from employees before delete it",
		role_deleted_successfully: "Role Deleted Successfully",
		seo: "SEO",
		google_analytics: "Google Analytics",
		checkout_url: "Checkout URL",
		get_checkout_url: "Get Checkout URL",
		payment_link_url: "Payment Link URL",
		clear: "Clear",
		sell_package_online: "Sell Package Online",
		appointment_confirmed_successfully: "Appointment Confirmed Successfully",
		send_appointments_reminder: "Send Appointments Reminder",
		send_birthday_wishes: "Send Birthday Wishes",
		send_rebook_for_no_show: "Send Re-Book For No Show",
		send_reminder_for_appointment_on_same_day: "Send Reminder For Appointment On Same Day",
		add_block_time: "Add Block Time",
		block_times: "Block Times",
		week: "Week",
		online_whatsapp: "Online Whatsapp",
		cancel_group: "Cancel Group",
		cancel_record: "Cancel Record",
		are_you_sure_you_want_to_cancel_the_record: "Are you sure you want to cancel the record?",
		are_you_sure_you_want_to_cancel_the_group: "Are you sure you want to cancel the group?",
		point: "Point",
		sent_at: "Sent At",
		number_of_messages: "Number of Messages",
		allow_payment_during_online_booking: "Allow payment during online booking",
		download_upload_customers_form: "Download upload tenants form",
		purchased_package: "Purchased Package",
		cancellation_reason: "Cancellation Reason",
		invoice_return: "Invoice Return",
		online_appointment: "Online Appointment",
		buy_gift_card: "Buy Gift Card",
		buy: "Buy",
		buy_offer: "Buy Offer",
		next: "Next",
		buyer: "Buyer",
		when_cancel_invoice_the_products_will_be_returned_to_the_stock: "* When cancel invoice the products will be returned to the stock",
		your_payment_has_been_paid_successfully: "Your payment has been paid successfully",
		transaction_number: "Transaction Number",
		transaction_date_and_time: "Transaction Date & Time",
		payment_operation_failed: "Payment Operation Failed",
		retry: "Retry",
		selected_products_will_be_returned_to_the_stock: "* Selected products will be returned to the stock",
		remove_group: "Remove Group",
		term_and_condition_added_successfully: "Term & Condition Added Successfully",
		term_and_condition_edited_successfully: "Term And Condition Edited Successfully.",
		cc_email: "CC Email",
		payment_gateway_payments: "Payment Gateway Payments",
		checkout_id: "Checkout ID",
		reference: "Reference",
		sell_gift_card_online: "Sell Gift Card Online",
		expense_should_have_at_least_one_item: "Expense should have at least 1 item",
		page_added_successfully: "Page Added Successfully",
		page_edited_successfully: "Page Edited Successfully",
		page_cloned_successfully: "Page Cloned Successfully",
		page_deleted_successfully: "Page Deleted Successfully",
		page_google_indexed_successfully: "Page google indexed successfully",
		editing_done_successfully: "Editing Done Successfully",
		no_payment_gateway: "No Payment Gateway",
		seo_report: "SEO Report",
		seo_setting: "SEO Setting",
		linked_page: "Linked Page",
		arabic_keywords: "Arabic Keywords",
		english_keywords: "English Keywords",
		google_indexed: "Google Indexed",
		clone: "Clone",
		seo_page_details: "Seo Page Details",
		english_script: "English Script",
		arabic_script: "Arabic Script",
		script: "Script",
		offered_on_a_monthly_fee_by_third_party_companies: "Offered on monthly fee by third party companies",
		main_instance_disconnected: "Main Instrance Disconnected",
		account_instance_to_platform_disconnected: "Account instance to platform disconnected",
		whatsapp_package_warning: "Whatsapp package warning",
		secondary_instance_disconnected: "Secondary Instance disconnected",
		account_instance_to_owner_disconnected: "Account instance to Owner disconnected",
		merchant_with_wa_disconnected_package: "need add",
		run_check_whatsapp: "Run Check Whatsapp",
		generate_server_js: "Generate Server.js",
		server_js: "server.js",
		seo_records: "SEO Records",
		seo_record: "SEO Record",
		delete_record: "Delete Record",
		are_You_sure_you_want_to_delete_the_record: "Are you sure you want to delete the record?",
		youtube: "Youtube",
		used_when_total_tax_invoice_greater_than_services_and_products_customers_get_return_cases:
			"Used when total tax invoice greater than services and products tenants get (Return cases)",
		create_debit_note: "Create Debit Note",
		used_when_total_tax_invoice_less_than_services_and_products_customers_get:
			"Used when total tax invoice less than services and products tenants get",
		please_issue_new_invoice_to_include_extra_amount_required: "Please issue new invoice to include extra amount required",
		sorry_we_cant_proceed_with_your_booking_kindly_contact_salon: "Sorry, we cant proceed with your booking\nkindly contact salon",
		pay_later: "Pay Later",
		expected_payment_date: "Expected Payment Date",
		credit_note_will_be_issued_without_adding_to_expenses: "Credit note will be issued without adding to expenses",
		expected_expenses: "Expected Expenses",
		add_expected_expense: "Add Expected Expense",
		edit_expected_expense: "Edit Expected Expense",
		expected_expense: "Expected Expense",
		move_to_expenses: "Move to Expenses",
		expected_expense_move_to_expenses_succuessfully: "Expected expense moved to Expenses Successfully",
		issue_invoice_penalty: "Issue invoice (Penalty)",
		new_invoice_of_amount_will_be_issued: "New invoice of ({amount}) will be issued",
		current_customers: "Current Tenants",
		new_price: "New Price",
		old: "Old",
		the_additional_amount_of_the_item_includes_tax: "The additional amount of the item includes tax",
		attended_customers: "Attended Tenants",
		customers_visits: "Tenants Visits",
		are_you_sure_you_want_to_create_debit_note: "Are you sure you want to create debit note?",
		debit_notes: "Debit Notes",
		credit_notes: "Credit Notes",
		it_includes_all_payments_from_issued_invoices_advanced_payments_and_debit_notes:
			"It includes all payments from issued invoices, advance payment, and debit notes",
		please_select: "Please Select",
		to_add_amount_on_the_services_products_which_customer_get: "To Add extra amount on the services / products which tenant get",
		preferred_to_return_payment_to_customer_on_the_same_method_of_issued_invoice:
			"* Preferred to return payment to tenant on the same method of issued invoice",
		collected_vat_total_sales_and_debit_notes: "Collect VAT (Total Sales & Debit Notes)",
		paid_vat_expenses_and_credit_notes: "Paid VAT (Expenses & Credit Notes)",
		sales_adjustment_vat_report: "Sales Adjustment VAT Report",
		total_vat_report: "Total VAT Report",
		collected_vat_total_sales: "Collected VAT (Total Sales)",
		collected_vat_credit_notes: "Collected VAT (Credit Notes)",
		vat_paid_vat_expenses: "Paid VAT (Expenses)",
		paid_vat_debit_notes: "Paid VAT (Debit Notes)",
		vat_report_from_sales: "VAT Report (Sales & Expenses)",
		vat_report_from_notes: "VAT Report (Credits & Debits Notes)",
		employee_performance_and_commission_are_not_calculated_in_debit_notes: "Employee perfornace and commissions are not calculated in debit notes",
		employee_performance_and_commission_are_not_calculated_in_debit_notes_with_star:
			"* Employee perfornace and commissions are not calculated in debit notes",
		expense_without_the: "Expense",
		customers_balances: "Tenants Balances",
		customer_balance: "Tenant Balance",
		customer_balance_services: "Tenant Balance (Services)",
		customer_balance_money: "Tenant Balance ($)",
		customers_balances_services: "Tenants Balances (Services)",
		customers_balances_money: "Tenants Balances ($)",
		delete_expected_expense: "Delete Expected Expense",
		are_you_sure_you_want_to_delete_the_expected_expense: "Are you sure you want to delete the expected expense?",
		record_deleted_successfully: "Record Deleted Successfully",
		payment_methods: "Payment Methods",
		payment_gateway: "Payment Gateway",
		show_gifts_cards: "Show Gifts Cards",
		booking_is_not_allowed: "Booking is not allowed",
		payment_method_is_not_allowed: "Payment method is not allowed",
		you_should_select_number_services_for_this_offer: "You should select {numberOfServices} for this offer",
		card: "Card",
		online_booking_with_payment_gateway_will_be_suspended: "- Online booking with payment gateway will be suspended",
		selling_packages_and_offers_online_will_be_suspended: "- Selling packages and offers online will be suspended",
		selling_gifts_card_online_will_be_suspended: "- Selling gifts cards online will be suspended",
		appointment_not_exist: "Appointment not exist",
		all_services_in_this_appointment_has_been_cancelled_please_book_a_new_one:
			"All services in this appointment has been cancelled, please book a new one",
		are_you_sure_you_want_to_cancel_all_the_services: "Are you sure you want to cancel all the services?",
		you_can_not_create_credit_note_for_invoice_with_partial_payments_please_settle_the_payments:
			"You can't create credit note for invoice with partial payments\n\nPlease settle the payments",
		payment_should_equal_remaining_amount_for_settling_invoice: "Payment should equal remaining amount for settling invoice",
		search_booking_id: "Search Booking ID",
		other_amount: "Other Amount",
		supplier_name: "Supplier Name",
		common_name: "Common Name",
		branch_name: "Branch Name",
		street_name: "Street Name",
		building_number: "Building Number",
		city_subdivision_name: "City Sub-Division Name",
		city_name: "City Name",
		postal_zone: "Postal Zone",
		commercial_registration_number: "Commercial Registration Number",
		if_you_leave_it_blank_owner_email_will_be_used: "If you leave it blank, owner email will be used",
		activate_phase_2: "Activate Phase 2",
		are_you_sure_you_want_to_activate_phase_2: "Are you sure you want to activate phase 2?",
		otp: "OTP",
		extra_tax_information: "Extra TAX Information",
		ksa_tax_phase_2: "KSA TAX Phase 2",
		suppliers_liabilities: "Suppliers Liabilities",
		zatca_setting: "Zatca Setting",
		add_zatca_setting: "Add Zatca Setting",
		edit_zatca_setting: "Edit Zatca Setting",
		commercial_number: "Commercial Number",
		failed_to_generate_files: "Failed to generate files",
		please_enter_the_otp: "Please enter the OTP",
		phase_2_already_activated: "Phase 2 already activated",
		request: "Request",
		reported_to_zatca: "Reported to Zatca",
		report_to_zatca: "Report to Zatca",
		reported_successfully: "Reported Successfully",
		renewed: "Renewed",
		data_cancelled_successfully: "Data Cancelled Successfully",
		toggle: "Toggle",
		dummy_needs_a_vue_build: "Dummy (Needs a VUE build)",
		offers_and_membership_and_online_bookings: "Offers & Memberships & Online Bookings",
		change_employee: "Change Employee",
		on_hold_invoice: "On Hold Invoice",
		view_all_employees_appointments: "View all employees appointments",
		appointments_cancellation_reasons: "Appointments Cancellation Reasons",
		appointment_editted: "Appointment Editted",
		cancellation_source: "Cancellation Source",
		tip: "Tips",
		tips: "Tips",
		add_tips: "Add Tips",
		employee_income: "Employee Income",
		in_salon: "In Salon",
		pricing: "Pricing",
		salon_type: "Salon Type",
		subscription_type: "Subscription Type",
		number_of_units_will_exceed_the_max_number_of_units_allowed_in_the_subscription:
			"Number of units will exceed the max number of units allowed in the subscription",
		allowed_countries: "Allowed Countries",
		you_are_not_authorized_to_use_app_from_you_location_please_contact_your_account_manager:
			"You are not authorized to use app from your geographical location, please contact your account manager",
		price_before_discount: "Price Before Discount",
		you_dont_have_business_account_with_salonaty: "You don't have business account with AqarBox?",
		disallowed_country: "Disallowed Country",
		running_text: "Running Text",
		english_text: "English Text",
		arabic_text: "Arabic Text",
		divide_by_two: "Divide By 2",
		please_now_select_the_service: "Please now select the service",
		please_select_the_second_employee: "Please select the second employee",
		your_subscription_is_valid_for_employees_only_please_contact_your_sales:
			"Your subscriptions valid for {numberOfUnits} units only, please contact your sales manager",
		units: "Units",
		add_unit: "Add Unit",
		edit_unit: "Edit Unit",
		sector: "Sector",
		district: "District",
		plot: "Plot",
		unit: "Unit",
		floor: "Floor",
		building_english_name: "Building English Name",
		building_arabic_name: "Building Arabic Name",
		fewa_number: "Electricity #",
		sewarage_number: "Sewarage #",
		unit_already_exist: "Unit Already Exist",
		unit_form: "Unit Form",
		unit_usage: "Unit Usage",
		building: "Building",
		extra_informations: "Extra Informations",
		owners: "Owners",
		national_id: "National ID",
		add_owner: "Add Owner",
		edit_owner: "Edit Owner",
		google_location_map: "Google Location Map",
		minimum_rent_price: "Minimum Rent Price",
		size: "Size (sqft)",
		not_exist_create_new_owner: "Not Exist, Create New Owner?",
		search_owner: "Search Owner",
		security_deposit: "Security Deposit",
		customer_contracts: "Tenant Contracts",
		new_contract: "New Contract",
		data_added_successfully: "Data Added Successfully",
		data_edited_successfully: "Data Edited Successfully",
		usage: "Usage",
		bedrooms: "Bedrooms",
		bathrooms: "Bathrooms",
		buildings: "Buildings",
		add_building: "Add Building",
		edit_building: "Edit Building",
		sector_english_name: "Sector English Name",
		sector_arabic_name: "Sector Arabic Name",
		district_english_name: "District English Name",
		district_arabic_name: "District Arabic Name",
		residance: "Residance",
		commercial: "Commercial",
		apartment: "Apartment",
		villa: "Villa",
		commercial_shop: "Commercial Shop",
		agreed_installment_plan: "Agreed Installment Plan?",
		contract: "Contract",
		unit_details: "Unit Details",
		rent_price: "Rent Price",
		parking_details: "Parking Details",
		parking_id: "Parking ID",
		payment_plan: "Payment Plan",
		total_rent_price: "Total Rent Price",
		cheque_number: "Cheque Number",
		bank: "Bank",
		master_data: "Master Data",
		add_city: "Add City",
		edit_city: "Edit City",
		vacant_units: "Vacant Units",
		vacant_parkings: "Vacant Parkings",
		show_vacant_units: "Show Vacant Units",
		customers_contracts: "Tenants Contracts",
		customers_payments: "Tenants Payments",
		received_payments: "Received Payments",
		collected_payments: "Collected Payments",
		non_collected_payments: "Non Collected Payments",
		all_payments: "All Payments",
		rented_units: "Rented Units",
		contracts_expired_within_three_months: "Expired within 3 months",
		national_id_expiry_date: "National ID Expiry",
		passport_number: "Passport Number",
		passport_expiry_date: "Passport Expiry Date",
		contract_expiry_reminder: "Contract Expiry Reminder",
		tasdeq_number: "Tasdeq #",
		active_contracts: "Active Contracts",
		expired_contracts: "Expired Contracts",
		historical_contract: "Historical Contract",
		historical_contracts: "Historical Contracts",
		historical_data: "Historical Data",
		issued: "Issued",
		draft: "Draft",
		data_already_exist: "Data Already Exist",
		parking: "Parking",
		add_historical_contract: "Add Historical Contract",
		edit_historical_contract: "Edit Historical Contract",
		parking_price: "Parking Price",
		contract_issue: "Issue Contract",
		total_rent_not_equal_total_payments: "Total Rent NOT Equal Total Payments",
		contracts_extra_informations: "Contracts Extra Informations",
		period: "Period",
		contracts_payments: "Contracts Payments",
		on_date: "On Date",
		tenant_profile: "Tenant Profile",
		insurance_amount: "Insurance Amount",
		insurance_payment_method: "Insurance Payment Method",
		edit_extra_informations: "Edit Extra Informations",
		add_term_and_condition: "Add Term & Condition",
		contract_with_valid_increment: "Contract with valid rent increment",
		old_payments: "Old Payments",
		draft_contracts: "Contracts Under Processing",
		units_status: "Units Status",
		download_qr: "Download QR",
		insurance_report: "Insurance Report",
		minimum_rent_price_set_by_owner_is: "Minimum rent price set by owner is: ",
		cheques_management: "Cheques Management",
		pending_maintenace_requests: "Pending Maintenance Requests",
		completed_maintenance_requests: "Completed maintenance Requests",
		all_maintenance_requests: "All Maintenance Requests",
		maintenance: "Maintenance",
		parkings: "Parkings",
		insurances: "Insurances",
		add_parking: "Add Parking",
		edit_parking: "Edit Parking",
		clearence_date: "Clearence Date",
		cheque_date: "Cheque Date",
		contract_status: "Contract Status",
		change_status: "Change Status",
		collect_payment: "Collect Payment",
		are_you_sure_you_want_to_the_payment_to_collected: "Are you sure you want to change the payment to collected?",
		collect: "Collect",
		collected: "Collected",
		not_collected: "Not Collected",
		not_collected_payments: "Not Collected Payments",
		insurance_price: "Insurance Price",
		all_contracts: "All Contracts",
		tenant_has_no_insurance: "Tenant has no insurance",
		tenant_has_insurance_of: "Tenant has insurance of",
		contract_details: "Contract Details",
		all_statuses: "All Statuses",
		terminate: "Terminate",
		rent: "Rent",
		please_add_all_required_data: "Please add all required data",
		bank_receiver_name: "Bank/Receiver Name",
		all_times: "All Times",
		cancel_contract: "Cancel Contract",
		are_you_sure_you_want_to_cancel_the_contract: "Are you sure you want to cancel the contract?",
		total_insurance_payments_not_equal_insurance_amount: "Total insurance amount not equal insurance amount",
		disable_unit: "Disable Unit",
		are_you_sure_you_want_to_disable_the_unit: "Are you sure you want to disable the unit?",
		payments_and_insurance: "Payments & Insurance",
		are_you_sure_you_want_to_change_the_payment_to_not_collected: "Are you sure you want to change the payment to not collected?",
		are_you_sure_want_to_issue_the_contract: "Are you sure you want to issue the contract?",
		cancel_contract_will_cancel_rent_status_on_unit: "Will cancel rent status on unit",
		cancel_contract_will_cancel_rent_status_on_parking: "Will cancel rent status on parking",
		cancel_contract_will_cancel_all_payments_rent_price_insurance: "Will cancel all payments (rent price + insurance)",
		this_contract_has_collected_payments: "This contract has collected_payments",
		upload_data: "Upload Data",
		all_historical_contracts: "Historical Contracts",
		all_current_contracts: "Current Contracts",
		move_to_historical: "Move to historical",
		are_you_sure_want_to_move_to_historical: "Are you sure you want to move to historical?",
		are_you_sure_want_to_move_to_current: "Are you sure you want to move to current?",
		move_to_current: "Move to current",
		send_contract_in_email_to_unit_owner: "Send contract in email to unit owner",
		current_contracts: "Current Contracts",
		current_payments: "Current Payments",
		all_current_payments: "Current Payments",
		all_historical_payments: "Historical Payments",
		print_all: "Print All",
		historical: "Historical",
		are_you_sure_you_want_to_change_the_payment_to_on_date: "Are you sure want to change the payment to On Date?",
		this_action_will_move_the_contract_and_contract_payments_to_historical: "This action will move the contract and contract payments to historical",
		this_action_will_move_the_contract_and_contract_payments_to_current: "This action will move the contract and contract payments to current",
		current: "Current",
		disable_unit_will_remove_unit_from_building: "Disable unit will remove unit from building",
		suggested_maintenance_groups: "Suggested Maintenance Groups",
		suggested_maintenance_services: "Suggested Maintenance Services",
		flat_number: "Flat Number",
		contract_payments: "Contract Payments",
		follow_up: "Follow Up",
		unattested_contracts: "Unattested Contracts",
		c_payments: "C Payments",
		historical_payments: "Historical Payments",
		payments_tenants: "Payments Tenants",
		missing_data_reminder: "Missing Data Reminder",
		unattested_contracts_reminder: "Unattested Contracts Reminder",
		expired_within_three_months_reminder: "Expired Within 3 Months Reminder",
		expired_within_one_month_reminder: "Expired Within 1 Month Reminder",
		expired_contracts_reminder: "Expired Contracts Reminder",
		update_passport_reminder: "Update Passport Reminder",
		update_national_id_reminder: "Update National ID Reminder",
		contract_payment_reminder: "Contract Payment Reminder",
		on_date_payments: "On Date Payments",
		on_date_current_payments: "On Date Current Payments",
		collected_current_payments: "Collected Current Payments",
		not_collected_current_payments: "Not Collected Current Payments",
		cancelled_current_payments: "Cancelled Current Payments",
		cancelled_payments: "Cancelled Payments",
		managed_owners: "Managed Owners",
		managed_buildings: "Managed Buildings",
		managed_units: "Managed Units",
		managed_parkings: "Managed Parkings",
		owner_form: "Owner Form",
		owner_details: "Owner Details",
		owner_access_edited_successfully: "Owner Access Edited Successfully",
		passport_expiry_reminder: "Passport Expiry Reminder",
		national_id_expiry_reminder: "National ID Expiry Reminder",
		real_estate_employee: "Real Estate (Employee)",
	},
	ar: {
		dashboard: "الرئيسية",
		appointments: "المواعيد",
		customers: "المستأجرين",
		customers_original: "العملاء",
		employees: "الموظفين",
		employee: "الموظف",
		roles_and_permissions: "صلاحيات الموظفين",
		services: "الخدمات",
		groups: "مجموعات الخدمات",
		original_price: "سعر البيع",
		packages: "الباقات",
		products: "المنتجات",
		stock_management: "ادارة المخزون",
		accounting: "المحاسبة",
		total_sales: "المبيعات",
		expenses: "المصاريف",
		payments: "الدفعات",
		receivable: "ديون العملاء",
		accounting_reports: "تقارير المحاسبة",
		setting: "الاعدادات",
		general_setting: "الاعدادات العامة",
		inventory_reports: "تقارير المخزون",
		management_reports: "التقارير الادارية",
		marketing_tools: "ادوات التسويق",
		invoice_setting: "اعدادات الفاتورة",
		accounting_setting: "اعدادات المحاسبة",
		online_setting: "اعدادات الاونلاين",
		notification_setting: "اعدادات التنبيهات",
		cs_setting: "اعدادات العناية بالمستأجرين",
		logout: "تسجيل الخروج",
		edit: "تعديل",
		activate: "تفعيل",
		disable: "تعطيل",
		filter: "تخصيص",
		export: "تصدير",
		actions: "الاجراءات",
		name: "الاسم",
		mobile: "الموبايل",
		email: "البريد الالكتروني",
		type: "النوع",
		loyality_points: "نقاط الولاء",
		loyality_program: "برنامج الولاء",
		account_balace: "رصيد الحساب",
		identification_method: "طريقة التعارف",
		notes: "الملاحظات",
		status: "الحالة",
		not_exist_create_new_customer: "غير موجود, اضافة مستؤجر جديد؟",
		walk_in_customer: "مستؤجر بدون رقم",
		general_reports: "التقارير العامة",
		date_and_time: "التاريخ والوقت",
		invoice_number: "رقم الفاتورة",
		contract_number: "رقم العقد",
		subtotal: "المجموع",
		vat: "ضريبة القيمة المضافة",
		total: "المجموع الكلي",
		salesman: "البائع",
		issued_by: "طبعت بواسطة",
		description: "الوصف",
		supplier: "المورد",
		supplierinvoice: "رقم  فاتورة المورد",
		supplier_trn: "الرقم الضريبي للمورد",
		paid_vat: "الضريبة المدفوعة",
		payment_method: "طريقة الدفع",
		payment_date: "تاريخ الدفع",
		reference_number: "الرقم المرجعي",
		paid_amount: "المبلغ المدفوع",
		remaining: "المتبقي",
		credit_note_number: "رقم اشعار دائن",
		returned_invoice_numner: "رقم الفاتورة المرتجعة",
		returned_amount: "المبلغ المرتجع",
		returned_vat: "الضريبة المرتجعة",
		debit_note_number: "رقم اشعار مدين",
		supplier_invoice_number: "رقم فاتورة المورد",
		picture: "الصورة",
		experience: "الخبرات",
		passport_expiry: "انتهاء الجواز",
		residancy_expiry: "انتهاء الاقامة",
		joining_date: "تاريخ الانضمام",
		leaving_date: "تاريخ المغادرة",
		show_in_appointment_table: "اظهار في جدول المواعيد",
		have_access_login: "صلاحيات الدخول للبرنامج",
		order: "الترتيب",
		english_name: "الاسم بالانجليزية",
		arabic_name: "الاسم بالعربية",
		show_to_customer: "اظهار للمستؤجر",
		group: "المجموعة",
		originalprice: "سعر البيع",
		price_include_vat: "سعر البيع شامل الضريبة",
		total_including_vat: "المجموع شامل ضريبة القيمة المضافة",
		job_time: "وقت الانجاز (دقيقة)",
		offer_price: "سعر العرض",
		from: "من",
		to: "الى",
		barcode: "الباركود",
		average_cost_price: "متوسط سعر التكلفة",
		warning_if_reach: "ًتنبيه عند كمية مخزون",
		print_barcode: "طباعة الباركود",
		add: "اضافة",
		define: "تعريف منتج",
		appointment_reports: "تقارير المواعيد",
		appointment_report: "تقرير المواعيد",
		inventory_status: "حالة المخزن",
		inventory_movements: "حركة المخزون لفترة",
		internal_use_report: "الاستخدام الداخلي للمخزون",
		inventory_evaluation: "تقييم المخزون",
		booking_source: "طريقة الحجز",
		payment_status: "حالة الدفع",
		service: "الخدمة",
		service_without_the: "خدمة",
		booking_date: "تاريخ الموعد",
		start_time: "الوقت",
		google_map: "خرائط جوجل",
		send_reminder: "تذكير",
		sent: "تم الارسال",
		send: "ارسل",
		marketing_sms: "رسائل التواصل",
		inventory_movements_period: "حركة المخزون (لفترة)",
		internal_use: "الاستخدام الداخلي",
		added_quantity: "الكمية المضافة",
		sold: "مباع",
		lost: "مفقود",
		returned: "مرتجع",
		remaining_quantity: "الكمية المتبقية",
		product_name: "المنتج",
		operation: "العملية",
		quantity: "الكمية",
		service_product: "الخدمة/المنتج",
		activity_report: "تقرير الحركات",
		daily_payment_summary: "ملخص الدفعات اليومي",
		cash: "نقدا",
		credit_card: "بطاقة ائتمانية",
		transfer: "حوالة",
		cheuqe: "شيك بنكي",
		payment_link: "رابط دفع",
		website: "الموقع الالكتروني",
		date: "التاريخ",
		monthly_payment_summary: "ملخص الدفعات الشهري",
		month: "الشهر",
		employees_report: "تقرير الموظفين",
		employee_commission: "عمولة الموظف",
		employee_part_of_sales: "حصة الموظف من المبيعات",
		details: "التفاصيل",
		services_report: "تقرير الخدمات",
		number_of_time_used: "عدد مرات الخدمة",
		most_used_products: "أكثر المنتجات إستخداما",
		time_of_used: "عدد مرات الاستخدام",
		number_of_selling: "عدد مرات البيع",
		most_sold_products: "أكثر المنتجات مبيعا",
		online_daily_visits: "عدد زيارات الاونلاين",
		number_of_visits: "عدد الزيارات",
		coupons_report: "تقرير الكوبونات",
		coupon_id: "رقم الكوبون",
		serial_number: "الرقم التسلسلي",
		value: "القيمة",
		re_print: "اعادة الطباعة",
		complain_report: "تقرير الشكاوى",
		complain_id: "رقم الشكوى",
		customer_discount_report: "تقرير خصومات المستأجرين",
		discount_percente: "نسبة الخصم",
		discount_value: "قيمة الخصم",
		survey_report: "تقرير الاستبيانات",
		excellent: "ممتاز",
		good: "جيد",
		weak: "ضعيف",
		very_weak: "ضعيف جدا",
		search: "بحث",
		search_and_follow_up: "البحث والمتابعة",
		products_management_reports: "تقارير ادارة المخزون",
		customers_relations_reports: "تقارير علاقات المستأجرين",
		important_reports: "تقارير هامة",
		filter_options: "خيارات التخصيص",
		include_disabled: "اظهار المعطل",
		include_disabled_expired: "إظهار المعطّل / المنتهي",
		show_more: "المزيد",
		show_less: "الافتراضي",
		salonaty: "عقار بوكس",
		signin: "تسجيل الدخول",
		signout: "تسجيل الخروج",
		account_id: "رقم الحساب",
		forget_account_id: "نسيت رقم الحساب",
		remember_me: "تذكرني",
		continue: "متابعة",
		password: "كلمة المرور",
		forget_password: "نسيت كلمة المرور؟",
		login: "تسجيل الدخول",
		website_login: "الدخول",
		sms_marketing: "رسائل التسويق",
		business_start_time: "بداية وقت العمل",
		business_end_time: "نهاية وقت العمل",
		holiday: "عطلة",
		day: "اليوم",
		total_income: "الدخل الكلي",
		acheived_services: "الخدمات المنجزة",
		birth_date: "تاريخ الميلاد",
		nationality: "الجنسية",
		address: "العنوان",
		gender: "الجنس",
		overview: "عام",
		working_hours: "ساعات العمل",
		Activity: "النشاط",
		holidays: "الاجازات",
		salary: "الراتب",
		commission: "العمولات",
		holiday_name: "اسم الاجازة",
		holiday_start_date: "تاريخ بداية الاجازة",
		holiday_end_date: "تاريخ انتهاء الاجازة",
		salon_menu: "قائمة الاسعار",
		product_price_search: "البحث عن سعر منتج",
		add_internal_use: "اضافة استخدام داخلي",
		send_daily_reports: "ارسال التقارير اليومية",
		send_backup_reports: "ارسال النسخة الاحتياطية",
		language: "اللغة",
		lock_screen: "اقفال الشاشة",
		training: "التدريب",
		quick_setup: "الاعداد السريع للبرنامج",
		english: "الانجليزية",
		arabic: "العربية",
		employee_details: "تفاصيل الموظف",
		extra_information: "معلومات اضافية",
		rights: "حقوق",
		add_employee: "اضافة موظف",
		save: "حفظ",
		customer_details: "تفاصيل المستأجر",
		trn: "الرقم الضريبي",
		view_permissions: "مشاهدة الصلاحيات",
		user_has: "المستخدم حاصل على حقوق",
		rights_to_the_system: ",للدخول للنظام ",
		control_access_login: "التحكم بصلاحيات الدخول",
		add_a_customer: "اضافة مستؤجر",
		packages_and_membership: "الباقات والاشتراكات",
		offers_and_membership: "العروض والاشتراكات",
		coupons: "الكوبونات",
		new_invoice: "فاتورة جديدة",
		new_deposit: "اضافة عربون",
		transactions: "العمليات",
		balance_till_the_moment: "الرصيد المتوفر حتى اللحظة",
		insurance_till_the_moment: "مبلغ التأمين المتوفر حتى اللحظة",
		insurance: "التأمين",
		this_year: "السنة الحالية",
		add_appointment: "اضافة موعد",
		profit_and_loss_report: "تقرير الربح والخسارة",
		cash_flow_report: "تقرير التدفق النقدي",
		other_accounting_reports: "تقارير محاسبية اخرى",
		debit_notes_report: "تقرير اشعارات مدين",
		credit_notes_report: "تقرير اشعارات دائن",
		credit_and_debit_notes: "اشعارات الدائن والمدين",
		edit_employee_info: "تعديل معلومات موظف",
		customer_invoices: "فواتير المستأجر",
		saturday: "السبت",
		sunday: "الاحد",
		monday: "الاثنين",
		tuesday: "الثلاثاء",
		wednesday: "الاربعاء",
		thursday: "الخميس",
		friday: "الجمعة",
		edit_employee: "تعديل موظف",
		edit_extra_information: "تعديل البيانات الاضافية",
		expiry_date: "تاريخ انتهاء الصلاحية",
		show_in_billing: "اظهار خلال الفوترة",
		yes: "نعم",
		no: "لا",
		show_in_appointments: "اظهار في المواعيد",
		upload_photo: "تحميل الصورة",
		administrator: "مسؤول",
		total_users_with_roles: "عدد المستخدمين لهذه الصلاحيات:",
		add_a_Role: "اضافة مجموعة صلاحيات",
		role_name: "اسم مجموعة الصلاحيات",
		enter_a_role_name: "ادخل اسم مجموعة الصلاحيات",
		role_permissions: "صلاحيات المجموعة",
		read: "قراءة",
		write: "ادخال",
		create: "انشاء",
		administrator_access: "صلاحيات كاملة",
		select_all: "اختيار الجميع",
		cancel: "الغاء",
		add_new_role: "مجموعة صلاحيات جديدة",
		assign_permissions: "منح الصلاحيات",
		add_roles: "اضافة مجموعة صلاحيات",
		edit_roles: "تعديل صلاحيات لمجموعة",
		stocks_management: "ادارة المخزون",
		add_quantity: "اضافة مخزون",
		adjust_quantity: "تعديل مخزون",
		delete: "ازالة",
		show: "عرض",
		adjust: "تعديل",
		all_features: "جميع الخيارات",
		reports: "التقارير",
		most_used: "الاكثر استخداما",
		most_sold: "الاكثر مبيعا",
		receipt: "الايصال",
		tax: "الضريبة",
		tax_name: "اسم الضريبة",
		percentage: "النسبة%",
		business_hours: "أوقات العمل",
		discount_code: "كود الخصم",
		discount_codes: "كودات الخصم",
		loyality_code: "كود الولاء",
		points_vs_paid_currency: "النقاط / العملة المدفوعة",
		admin_email: "البريد الالكتروني للمدير",
		country: "الدولة",
		city: "المدينة",
		address_in_arabic: "العنوان باللغة العربية",
		address_in_english: "العنوان باللغة الانجليزية",
		trn_number: "الرقم الضريبي",
		import_data: "ادخال بيانات",
		make_sure_to_use_system_format_from_action_button: "الرجاء الالتزام باستخدام ملفات النظام الموجودة في زر الاجراءات",
		upload_now: "تحميل البيانات",
		link_employee_with_service: "ربط الموظف مع الخدمة",
		link_employee_with_product: "ربط الموظف مع المنتج",
		allow_hold_invoice: "السماح بتعليق الفاتورة",
		controller: "التحكم بالنظام",
		download_groups_template: "تحميل نموذج مجموعات الخدمات",
		download_services_template: "تحميل نموذج الخدمات",
		download_products_template: "تحميل نموذج المنتجات",
		edit_business_informations: "تعديل معلومات الشركة",
		invoice_controller: "التحكم بالفاتورة",
		show_employee_in_customer_invoice: "عرض اسم الموظف في فاتورة المستأجر",
		show_customer_information_in_invoice: "عرض معلومات المستأجر في الفاتورة ",
		send_email_to_employee_with_done_service: "ارسال بريد الكتروني للموظف في الخدمة المنجزة",
		send_email_to_employee_with_ordered_service: "ارسال بريد الكتروني للموظف بالخدمة المطلوبة",
		activate_winning_box: "تشغيل صندوق الربح",
		invoice_header: "راس الفاتورة",
		invoice_footer: "ذيل الفاتورة",
		add_customer: "اضافة مستؤجر",
		add_more_data: "اضافة بيانات اخرى",
		birthday: "تاريخ الميلاد",
		gifts_cards_reports: "تقارير بطاقات الهدايا",
		redeem_loyality_points: "تحويل نقاط الولاء",
		reminder: "تذكير",
		add_balance_to_customer_through_loyality_points: "اضافة رصيد من خلال تحويل نقاط الولاء,",
		gifts_cards: "بطاقات الهدايا",
		send_email: "ارسال بريد الالكتروني",
		whatsapp: "واتساب",
		use_service: "استخدم الخدمة",
		header_and_footer: "ترويسة وذيل الفاتورة",
		active_gifts_cards: "بطاقات الهدايا الفعالة",
		suppliers: "الموردون",
		expenses_types: "أنواع المصاريف",
		online_controller: "التحكم بخدمات الاونلاين",
		is_working_day: "يوم عمل",
		add_automatically: "إضافة بشكل تلقائي",
		primary_informations: "المعلومات الرئيسية",
		setup_your_account_details: "ادخل تفاصيل حسابك للأعمال",
		activate_vat: "تفعيل ضريبة القيمة",
		activate_vat_in_your_business: "فعل حسابات الضريبة الخاص ",
		employee_info: "معلومات الموظفين",
		add_employees_info: "اضافة معلومات الموظفين",
		set_business_hours: "اضافة ساعات العمل الخاصة ",
		set_how_invoice_looklike: "كيفية عرض الفاتورة للمستؤجر",
		activate_services: "تفعيل الخدمات",
		activate_services_and_set_prices: "تفعيل الخدمات واضافة الاسعار",
		completed: "مكتمل",
		woah_we_are_here: "تم اضافة البيانات بنجاح",
		aaccount_info: "معلومات الحساب",
		if_you_need_to_switch_languages: "في حال رغبت بتغيير اللغة,  ",
		switch_language: "تغيير اللغة",
		upload_business_logo: "تحميل الشعار",
		search_products: "البحث عن منتج",
		search_by_barcode_or_name: "البحث بالباركود او الاسم",
		balance_due: "المبلغ المستحق",
		sell_as_package: "بيع كباقة",
		add_new_payment: "اضافة دفعة جديدة",
		remove_payment: "ازالة الدفعة",
		issue_invoice: "اصدار فاتورة",
		cancel_issue_invoice: "الغاء اصدار الفاتورة",
		number_of_items: "عدد السلع",
		proceed_payment: "متابعة الدفع",
		hold_invoice: "تعليق الفاتورة",
		add_gift_card: "اضافة بطاقة هدايا",
		submit: "اضافة",
		add_free_text: "اضافة نص حر",
		free_text: "نص حر",
		invoice: "الفاتورة",
		remove: "ازالة",
		excel: "ملف اكسل",
		print: "طباعة",
		export_options: "خيارات التصدير",
		disabled: "معطّل",
		allow_dept_in_invoice: "السماح بالدين للعملاء",
		invoice_notes: "ملاحظات الفاتورة",
		forget_accoubt_id: "نسيت رقم الحساب؟",
		ok: "موافق",
		enter_your_email_to_receive_your_account_id: "ادخل البريد الالكتروني الخاص بك",
		back: "رجوع",
		enter_your_email_to_reset_your_password: "أدخل البريد الالكتروني لاستعادة كلمة المرور.",
		coupon_gift_card: "الكوبون / بطاقة هدايا",
		hide_from_customer: "اخفاء امام المستأجر",
		add_group: "اضافة مجموعة",
		add_service: "اضافة خدمة",
		edit_group: "تعديل مجموعة",
		edit_service: "تعديل خدمة",
		sell_price: "سعر البيع",
		sell_price_including_vat: "سعر البيع شامل الضريبة",
		add_package: "اضافة باقة",
		edit_package: "تعديل باقة",
		initial_quantity: "الكمية المبدئية",
		warning_if_stock_reach: "تحذير عند وصول الكمية",
		define_product: "تعريف منتج",
		edit_product: "تعديل منتج",
		working_day: "يوم عمل",
		new_times_warning: "** سيتم تطبيق الاوقات الجديدة على اوقات الموظفين والمواعيد",
		edit_business_hours: "تعديل ساعات العمل",
		edit_tax: "تعديل الضريبة",
		confirm: "تاكيد",
		editing_tax_will_affect_all_service_and_products: "** تغيير نسبة الضريبة ستؤدي لتغيير اسعار الخدمات و المنتجات",
		this_will_change_all_services_and_products_prices_according_to_new_vat: "سيتم تغيير اسعار الخدمات والمنتجات مع حسابات الضريبة الجديدة",
		code: "الكود",
		category: "المجموعة",
		add_discount_code: "اضافة كود خصم",
		edit_discount_code: "تعديل كود خصم",
		edit_loyality_program: "تعديل برنامج الولاء",
		activate_qr: "تشغيل رمز الاستجابة السريعة",
		edit_invoice_setting: "تعديل اعدادات الفاتورة",
		edit_invoice_header_and_footer: "تعديل راس وذيل الفاتورة",
		header: "الترويسة",
		footer: "الذيل",
		receptionist: "موظف إستقبال",
		management: "إدارة",
		manager: "الادارة",
		hair_services: "خدمات الشعر",
		hair_treatment: "علاجات الشعر",
		hair_color: "الصبغ",
		makeup: "المكياج",
		manicurist: "المانيكير",
		pedicurist: "البديكير",
		morrocan_bath: "الحمام المغربي",
		henna: "الحناء",
		message: "المساج",
		facial: "الوجه",
		wax: "الواكس",
		halawa: "الحلاوة",
		threding: "الخيط",
		eyebrow: "الحواجب",
		lashes: "الرموش",
		general_services: "خدمات عامة",
		not_specific: "غير محدد",
		relatives: "الاقارب",
		relatives_acquaintances: "أقارب أو معارف",
		facebook: "الفيسبوك",
		instagram: "الانستغرام",
		snapchat: "السناب شات",
		google: "جوجل",
		blogger_ads: "المؤثرين",
		coincidence: "صدفة",
		other: "اخرى",
		male: "ذكر",
		female: "انثى",
		business: "الأعمال",
		profile: "ملف المستأجر",
		activities: "النشاطات",
		this_month: "هذا الشهر",
		last_month: "الشهر السابق",
		invoice_payments_should_be_equal_to_invoice_total: "مجموع الدفعات يجب أن يساوي قيمة الفاتورة",
		invoice_payments_should_be_less_or_equal_to_invoice_total: "مجموع الدفعات يجب أن يكون أقل من أو يساوي قيمة الفاتورة",
		invoice_payments_is_not_the_same_as_invoice_total: "قيمة الدفعات ليست مساوية لقيمة الفاتورة",
		package_price_should_be_less_than_invoice_total: "قيمة الباقة يجب أن تكون أقل من أو تساوي قيمة الفاتورة",
		select_package_expiry: "إختر تاريخ إنتهاء الصلاحية",
		days: "أيام",
		select_date_manually: "إختر التاريخ يدويا",
		employees_information: "معلومات الموظفين",
		send_whatsapp: "إرسال واتس آب",
		disable_employee: "تعطيل موظف",
		employee_timing: "أوقات الموظف",
		no_show: "لم يحضر",
		pos: "الكاشير",
		today: "اليوم",
		whats_up_today: "نشاطات اليوم",
		booking: "حجز",
		profit_and_loss: "الربح والخسارة",
		profit: "الربح",
		loss: "الخسارة",
		net_vat: "اجمالي ضريبة القيمة",
		collected_vat: "الضريبة المجمعة",
		due_on_goverment: "المسترد من الحكومة",
		due_to_goverment: "المورد الى الحكومة",
		search_by_mobile_or_name: "البحث برقم الموبايل او الاسم...",
		invoice_added_successfully: "تم اصدار الفاتورة بنجاح",
		invoice_edited_successfully: "تم تعديل الفاتورة بنجاح",
		order_submitted_successfully: "تم الطلب بنجاح",
		price: "السعر",
		my_profile: "الملف الشخصي",
		job_profile: "الملف الوظيفي",
		my_employee_profile: "الملف الوظيفي",
		change_password: "تغيير كلمة المرور",
		custom_range: "فترة مخصصة",
		yesterday: "أمس",
		last_quarter: "آخر ربع",
		advanced: "مقدما",
		bank_status: "حالة البنك",
		create_invoice: "إنشاء فاتورة",
		create_contract: "إنشاء عقد",
		appointment: "موعد",
		block_time: "وقت حظر",
		cancelled: "ملغي",
		scheduled: "في موعده",
		check_in: "تم الحضور",
		acheived: "مكتملة",
		frequent: "متكرر",
		missed: "مفقود",
		new: "جديد",
		end_time: "وقت الإنتهاء",
		is_required: "مطلوب",
		apply: "تطبيق",
		show_alone: "عرض وحيدا",
		show_all: "عرض الكل",
		am: "صباحا",
		pm: "مساء",
		groups_and_services: "المجموعات والخدمات",
		selected_appointments: "المواعيد المختارة",
		minutes_short: "د",
		by: "بواسطة",
		search_customer: "البحث عن مستؤجر",
		appointment_added_successfully: "تم إضافة الموعد بنجاح",
		check_in_now: "إبدأ الآن",
		summary: "الملخص",
		invoices: "الفواتير",
		no_email: "لا يوجد بريد إلكتروني",
		select: "إختر",
		customer: "المستأجر",
		system: "النظام",
		online: "أونلاين",
		showing: "إظهار",
		records: "النتائج",
		employee_changed: "تغير الموظف",
		expired: "منتهي",
		id: "الرقم التعريفي",
		used_gifts_cards: "بطاقات الهدايا المستخدمة",
		whatsapp_marketing: "التسويق بالواتساب",
		reset_all: "إعادة ضبط الكل",
		active: "فعّال",
		unpaid: "غير مدفوع",
		paid: "مدفوع",
		waiting: "قيد الإنتظار",
		used: "مستخدم",
		authorization_code: "رقم الموافقة",
		add_deposit: "إضافة عربون",
		amount: "القيمة",
		payment: "الدفعة",
		customer_form: "نموذج المستأجر",
		balance: "الرصيد",
		loyality: "الولاء",
		package_expiry: "تاريخ إنتهاء الصلاحية",
		appointments_controller: "مراقب المواعيد",
		checkout: "الدفع",
		cancel_all: "إلغاء الكل",
		add_more_services: "إضافة المزيد من الخدمات",
		daily_activity_logs: "الأنشطة اليومية",
		redeemed_amount: "المبلغ المسترد",
		payments_details: "تفاصيل المدفوعات",
		access_level: "الصلاحيات",
		control_access: "التحكم في الوصول",
		role: "مجموعة الصلاحيات",
		edit_working_hours: "تعديل ساعات العمل",
		stock_operations: "عمليات المخزون",
		change_question: "تغيير؟",
		new_experience_for_salon_management: "تجربة فريدة لادارة العقارات",
		sell_without_stocks: "البيع بدون مخزون",
		welcome: "أهلا وسهلا",
		expired_gifts_cards: "بطاقات الهدايا المنتهية",
		prefrered_language: "اللغة المفضلة",
		left_working: "ترك العمل",
		if_you_need_more_info_please_check_out: "إذا كنت بحاجة إلى مزيد من المعلومات ، يرجى التحقق من",
		save_and_start: "إحفظ وإبدأ",
		please_wait_three_dots: "الرجاء الإنتظار...",
		data_saved_successfully: "تم حفظ البيانات بنجاح",
		backup: "نسخة إحتياطية",
		backup_account_data: "نسخة إحتياطية لمعلومات الحساب",
		email_it_now: "أرسل بالبريد الإلكتروني الآن",
		categories: "المجموعات",
		services_categories: "مجموعات الخدمات",
		maintenance_categories: "مجموعات الصيانة",
		maintenance_services: "خدمات الصيانة",
		data_removed_successfully: "تم حذف البيانات بنجاح",
		are_you_sure_you_want_to_remove_the_contract: "هل أنت متأكد أنك تريد حذف العقد؟",
		remove_contract: "حذف العقد",
		offers: "العروض",
		menu: "القائمة",
		preferred_language: "اللغة المفضلة",
		add_offer: "إضافة عرض",
		renew: "تجديد",
		disable_offer: "تعطيل العرض",
		renew_offer: "تجديد العرض",
		are_you_sure_you_want_to_renew_the_offer: "هل أنت متأكد من أنك تريد تجديد العرض",
		offer_expiry: "تاريخ إنتهاء الصلاحية",
		select_offer_expiry: "إختر تاريخ إنتهاء الصلاحية",
		offer_service_usage: "إستخدام خدمة عرض",
		add_custom_category: "إضافة مجموعة خاصة",
		add_category: "إضافة مجموعة",
		edit_category: "تعديل مجموعة",
		disable_category: "تعطيل مجموعة",
		invoice_gift_card_value_more_than_invoice_total_warning:
			"قيمة بطاقة الهدية اكبر من قيمة المبيعات،،، الرجاء اضافة المزيد من الخدمات حتى تتمكن من استخدام البطاقة",
		suggest_development: "اقتراح تطوير",
		home: "الرئيسية",
		wizard: "المساعد",
		select_category: "اختر مجموعة",
		add_custom_service: "اضافة خدمة خاصة",
		coming_soon: "لاحقا",
		expired_gift_card: "بطاقة الهدية منتهية الصلاحية",
		used_gift_card: "بطاقة الهدية مستخدمة مسبقا",
		legacy_version: "النسخة الأصلية",
		invoice_logo: "شعار الفاتورة",
		on: "مفعّل",
		off: "مطفئ",
		menu_off: "القائمة مغلقة",
		menu_on: "القائمة مفتوحة",
		setup_menu_now: "ضبط القائمة الآن",
		business_setup_guide: "إكمال ملفك التجاري",
		owner_email: "البريد الإلكتروني للمالك",
		salon_name: "إسم الشركة العقارية",
		request_free_trial: "طلب فترة تجريبية",
		login_by_email: "تسجيل الدخول عن طريق البريد الإلكتروني",
		login_by_account_id: "تسجيل الدخول عن طريق رقم الحساب",
		thank_you_for_your_time: "شكرا لك",
		request_sent_successfully: "تم إرسال الطلب بنجاح",
		we_will_send_you_instructions_to_get_started_via_email_or_whatsapp_within_24_hours:
			"سوف نقوم بإرسال التعليمات إلى البريد الإلكتروني المسجل او حساب الواتس آب خلال 24 ساعة",
		privacy_policy: "سياسة الخصوصية",
		features: "المميزات",
		programs: "البرامج",
		beauty_salons_management_system: "برنامج إدارة العقارات",
		contact_us: "تواصل معنا",
		any_time_any_device_any_where: "في أي وقت ومن أي مكان ومن اي جهاز",
		cloud_based_software_for_beauty_salon: "برنامج السحابة المتخصص في إدارة مراكز التجميل والصالونات والمشاغل النسائية",
		cloud_based_software: "برنامج السحابة المتخصص",
		for_beauty_salon: "برنامج إدارة العقارات",
		smart_simple_secure: "ذكي, سهل, أمن",
		enhance_salon_and_business_operations_with_salonaty: "طوّر تجربتك في الإدارة وسهّل عمليات شركتك مع عقار بوكس",
		customers_support: "الدعم التقني",
		rules_and_permissions: "اداة مطورة لصلاحيات الموظفين",
		reports_and_analysis: "تقارير وتحاليل",
		secure_cloud_system: "نظام سحابي أمن",
		by_checking_this_box_i_confirm_that_i_have_read_understood_and_agree_to_the: "بتحديد هذا المربع، أؤكد أنني قد قرأت وفهمت وأوافق على",
		by_checking_this_box_you_agree_with_the_handling_business_data_in_accordance_to_our:
			"كذلك أوافق على جمعنا لبياناتك الشخصية واستخدامها وتخزينها بالطريقة المبيّنة في",
		all_rights_reserved: "جميع الحقوق محفوظة",
		terms_and_conditions: "الشروط والاحكام",
		ultra_cloud_information_technologies: "الترا كلاود لتقنية المعلومات",
		more: "المزيد",
		salonaty_connect: "عقار بوكس تواصل",
		main_office: "المقر الرئيسي",
		uae: "الامارات العربية المتحدة",
		ksa: "المملكة العربية السعودية",
		kuwait: "الكويت",
		later: "لاحقا",
		salonaty_management_software_enhance_business_opearations_and_allow_oweners_and_decision_maker_complete_overview:
			"برنامج عقار بوكس لادارة صالونات التجميل النسائية (المشاغل النسائية - صالونات التجميل - مراكز التجميل) المنكامل يمنح ادارة واصحاب مراكز التجميل النسائية ادارة متكاملة لاعمالهم",
		it_include_online_booking_digital_menu_home_services_booking_social_media_links_and_others:
			"وتشمل نظام حجز المواعيد الالكترونية, المنيو الالكتروني (عن طريق الرابط او عن طريق مسح رمز الاستجابة) , حجز الخدمات المنزلية, مواقع التواصل الاجتماعي.",
		our_services: "خدماتنا",
		technical_support_and_backup: "الدعم الفني والنسخ الاحتياطي",
		our_team_consist:
			"				ان فريق العمل لدينا متخصص وذو خبرات عالية, مع ما يزيد عن 15 عام من الخبرة في مجال تطوير البرامج للاعمال, حيث يقوم الفريق بتدريب العملاء على كيفية استخدام البرنامج , كذلك نقوم بعمل نسخ احتياطية يومية لبيانات المستأجرين لتجنب فقدان اي بيانات مهمة للعملاء.",
		salonaty_is_cloud_base_software:
			"				ان جميع برامج السحابة (نقاط البيع) – برامج السحابة لمحلات التجزئة مشتركة بالعديد من الميزات التي تغطي جميع احتياجات الاعمال كذلك لاداعي للقلق من تكاليف البرمجة المرتفعة, وتكاليف البرمجة المرتفعة للشبكة والخوادم, كذلك تكاليف الدعم الفني والصيانة, المميزات كما يلي:",
		respoinsive: "متوافق مع جميع احجام الشاشات",
		online_software: "يمكن العمل به عن طريق الانترنت",
		multi_languages_support_arabic: "متعدد اللغات وداعم للعربية",
		send_sms_through_whatsapp: "ارسال الرسائل عن طريق الوتساب",
		appointment_system: "نظام المواعيد",
		accounting_and_vat_calculations: "الحسابات العامة والضرائب",
		customers_survey_system: "نظام استبيان المستأجرين",
		daily_and_backup_reports: "التقارير اليومية والاحتياطية",
		respoinsive_descriptions:
			"ان جميع البرامج المطورة من جانبنا, تبنى لتكون متوافقة مع قياسات الشاشات المختلفة, لذلك يمكنك استخدامها سواء من الهاتف المتحرك او شاشة الحاسوب.",
		online_software_descriptions:
			"ان البرامج التي نطورها تسطيع ان تعمل عليها من انترنت السحابة من اي مكان في العالم, لذلك فانه لا حدود لديك لادارة اعمالك اينما تكون وفي اي وقت.",
		roles_and_permissions_descriptions:
			"							تسطيع بكل بساطة التحكم بصلاحيات الدخول للموظفين, ومنح الصلاحيات المناسبة لكل موظف من الموظفين بحسب درجته الوظيفية والمعلومات المسموح الاطلاع عليها",
		multi_languages_support_arabic_descriptions:
			"ان برامجنا تحتوي بشكل تلقائي على اللغة الانجليزية والعربية, لذلك بغض النظر عن اللغات والمهارات التي يحملها موظفك. فان برامجنا ستناسب الجميع.",
		send_sms_through_whatsapp_descriptions:
			"ان برامجنا ستحل لك قضية التخاطب مع المستأجرين لقضايا المخاطبات او التذكيرات باستخدام منصة واتساب الشهيرة, فهي مؤمنة ,مشفرة بالكامل ومجانية.",
		appointment_system_descriptions:
			"ان البرامج تحتوي على نظام حجوزات متطور, سهل الاستخدام, مع جدول زمني واضح والذي سيغطي جميع احتياجاتك لجدولة المواعيد او السلع الايجارية.",
		accounting_and_vat_calculations_descriptions:
			"احصل على تقرير مفصل للمبيعات وطرق الدفع للعملاء مع ديونهم وجميع احياجاتك المحاسبية للمبيعات اليومية والشهرية, كذلك حساب ضريبة المبيعات على البضائع المباعة",
		customers_survey_system_descriptions:
			"ان برامجنا يحتوي على نظام الكتروني لاستبيانات المستأجرين, لمعرفة مدى رضائهم عن الخدمات او السلع المقدمة من فريق العمل, كذلك لاستقبال الشكاوى بشكل حديث.",
		daily_and_backup_reports_descriptions:
			"سوف تتلقى بشكل اوتوماتيكي تقارير يومية الى صندوق البريد الالكتروني لتبقى على اطلاع دائم على كافة الامور الخاصة بعملك, احجام المبيعات, الدفعات والفرص التجارية.",
		beauty_salons_management_system_part1:
			"برنامج ادارة العقارات المتكامل, عقار بوكس, يمنح ادارة واصحاب شركات العقار ادارة كاملة متكاملة لاعمالهم, بكل سهولة ويسر, فالبرنامج يحتوي على على جميع التقارير اللتي تحتاجها الشركة كذلك يحتوي على فواتير المستأجرين ودفعاتهم (نقدا, او ببطاقة ائتمانية), حسابات ضريبة القيمة المضافة, واي عمليات اخرى يحتاجها العمل.",
		beauty_salons_management_system_part2:
			"عقار بوكس مصمم لمقابلة احتياجات ادارة شركات العقارات, وتعمل باللغتين العربية والانجليزية, انه سريع الاعداد وسهل للاستخدام,وتستطيع العمل عليه خلال دقائق, عقار بوكس ليس مجرد برنامج عادي, انه خيار الاعمال الاول والافضل, لادارة واتتمتة عمل شركات العقارات",
		try_software_for_seven_days_free: "جرب البرنامج لمدة 7 ايام مجانا",
		training_descriptions:
			"نحن نؤمن بان مشاهدة الفيديو هو افضل طريقة للتعلم والتدريب, وان افضل الممارسات العالمية الفعالة للتدريب هي عن طريق مشاهدة الفيديو القصيرة لمعالجات معينة في نظام البرنامج",
		already_have_an_account: "لديك حساب في عقار بوكس؟",
		valid: "فعّال",
		send_message: "إرسال الرسالة",
		contact_us_message: "الرسالة",
		now_you_can_contact_us_any_time: "الان .. يمكنك التواصل معنا باي وقت للحصول على المزيد",
		commercial_partners_in_gulf: "الشركاء التجاريين في الخليج",
		telephone: "رقم الهاتف",
		faq: "الأسئلة المتكررة",
		we_received_your_message: "تم إرسال الرسالة بنجاح",
		our_team_will_contact_you_soon: "سنقوم بالتواصل معك قريبا",
		beauty_salon_system: "سستم صالونات تجميل",
		beauty_salon_casheir: "كاشير صالون تجميل",
		salons_software: "برنامج صالونات",
		woman_salon_software: "برنامج صالون نسائي",
		digital_menu_salon: "منيو الكتروني صالون",
		beauty_salon_management_software: "برنامج ادارة صالونات التجميل",
		woman_salon_cashier: "كاشير صالون نسائي",
		arabic_salon_software: "كاشير صالونات بالعربي",
		free_salon_software: "سستم صالون مجاني",
		salon_appointment_software: "سستم مواعيد صالون",
		woman_salon_system: "سستم مشغل نسائي",
		woman_salon_application: "تطبيق مشاغل نسائية",
		salon_software: "برنامج صالون",
		business_ready: "الملف التجاري جاهز",
		wrong_number: "رقم خاطئ",
		online_services: "خدمات الأونلاين",
		english_qr: "QR الإنجليزي",
		arabic_qr: "QR العربي",
		business_alias: "الإسم الإلكتروني",
		disable_online_setting: "تعطيل خدمات الأونلاين",
		alias_name_will_be_deleted_so_that_it_can_be_used_by_another_salon: "سيتم حذف الإسم المستعار الخاص بك وسيكون من الممكن إستخدامه من أي صالون آخر",
		your_online_services_will_be_disabled_if_you_want_to_change_the_alias_name_please_contact_support:
			"سيتم تعطيل خدمات الاونلاين الخاصة بكم، اذا كنت تريد تغيير الاسم الرجاء التواصل مع قسم الدعم الفني الخاص بكم",
		activate_online_setting: "تفعيل إعدادات الأونلاين",
		arabic_link: "رابط الموقع بالعربية",
		english_link: "رابط الموقع بالإنجليزية",
		price_list: "قائمة الأسعار",
		incomplete_number: "الرقم غير مكتمل",
		new_admin_must_be_in_salon_employees_and_must_have_admin_privileges: "يجب أن يكون المسؤول الجديد في الصالون ويجب أن يكون لديه امتيازات المسؤول",
		show_price_list: "إظهار قائمة الأسعار",
		show_price_include_vat_message: "إظهار رسالة الأسعار تشمل الضريبة",
		show_offers: "إظهار العروض",
		press_ctrl_with_plus_or_minus_to_adjust_the_screen: "إضغط على (CTRL +) أو (CTRL -) لضبط البرنامج مع شاشة الحاسوب",
		show_menu: "إظهار القائمة",
		online_booking: "حجوزات الأونلاين",
		complains: "الشكاوى والإقتراحات",
		home_services: "الخدمات المنزلية",
		link: "الرابط",
		edit_customer: "تعديل المستأجر",
		add_note: "إضافة ملاحظة",
		edit_note: "تعديل ملاحظة",
		working: "يعمل",
		activated_products: "المنتجات الفعالة",
		not_exist_request_define_product: "غير موجود، طلب تعريف منتج؟",
		active_products: "المنتجات الفعالة",
		request_define_product: "طلب تعريف منتج",
		proceed: "متابعة",
		add_expense: "إضافة مصروف",
		time: "الوقت",
		i_do_not_want_to_add_expense: "أنا لا أريد إضافة مصروف",
		move_up: "تحريك لأعلى",
		move_down: "تحريك لأسفل",
		appointments_whatsapp: "مواعيد الواتساب",
		offers_whatsapp: "عروض الواتساب",
		alias_name: "الإسم المستعار",
		social_media: "التواصل الاجتماعي",
		google_maps: "خرائط جوجل",
		facebook_page_id: "معرّف فيسبوك الخاص",
		owner: "المالك",
		this_will_change_all_services_and_products_prices_according_to_the_new_vat:
			"سيؤدي ذلك إلى تغيير أسعار جميع الخدمات والمنتجات وفقًا لضريبة القيمة المضافة الجديدة",
		customer_added_successfully: "تم إضافة المستأجر بنجاح",
		employee_form: "نموذج الموظف",
		online_setting_privacy_policy_for_web_portal:
			'ان سياسة الخصوصية لبوابة الانترنت الخاصةبالشركاء (عقار بوكس تواصل) هي ملحق لسياسة الخصوصية الرئيسية الموجودة في "موقع" عقار بوكس.بقبول هذا الملحق ، فان (الشريك / الشركاء) تصرح لمنصة عقار بوكس بعرض بعض المعلومات التجارية للمستخدمين (المستأجر / المستأجرين) مثل (خدمات وأسعار الصالون ، العروض ، ساعات العمل ، روابط وسائل التواصل الاجتماعي) ومنحهم الوصول إلى (حجز موعد ، طلب خدمات منزلية ، إرسال اقتراح أو شكوى لإدارة الأعمال).يمكن (للشريك / الشركاء) استخدام كلا من رمز الاستجابة السريعة ورابط الويب في مواقع الويب الخاصة بهم وصفحات وسائل التواصل الاجتماعي ومنطقة الاستقبال لإظهار المعلومات المختارة لعملائهم حسب اختيارهم في "خيار إعدادات صفحة الإنترنت".',
		online_setting_privacy_policy_aknowledge:
			'أنت تٌقر بأنك قد قرأت سياسة الخصوصية الخاصة بموقع عقار بوكس هذه وتوافق على جميع بنودها وشروطها. كما أنك تقر بأنك على دراية بسياسة خصوصية البوابة الإلكترونية "عقار بوكس تواصل" وتدرك أن المعلومات المذكورة أعلاه سيتم الوصول إليها من قبل (العملاء / العملاء) من موقع عقار بوكس ، تطبيق AQARBOX IOS ، تطبيق AQARBOX Android.',
		apply_timing_for_all_days: "تطبيق على جميع أيّام الأسبوع",
		screen_adjust: "ضبط شاشة الحاسوب",
		coinsedence: "صدفة",
		primary_informations_updated_successfully: "تم تعديل المعلومات الرئيسية بنجاح",
		tax_updated_successfully: "تم تعديل الضريبة بنجاح",
		tax_status_updated_successfully: "تم تعديل حالة الضريبة بنجاح",
		new_updated_business_timing_will_be_applied_to_all_employees_working_hours: "سيتم تطبيق توقيت العمل الجديد / المحدث على جميع ساعات عمل الموظفين",
		new_updated_business_timing_will_be_applied_appointment_screen: "سيتم تطبيق توقيت العمل الجديد / المحدث على شاشة المواعيد",
		working_hours_updated_successfully: "تم تعديل ساعات العمل بنجاح",
		employee_added_successfully: "تم إضافة الموظف بنجاح",
		services_activated_successfully: "تم تفعيل الخدمات بنجاح",
		select_group: "إختيار مجموعة",
		there_are_services_not_saved_do_you_want_to_save_them_before_change_group: "هناك بعض الخدمات غير محفوظة بعد، هل تريد حفظهم قبل تغيير المجموعة؟",
		in: "في",
		email_should_be_correct: "البريد الإلكتروني يجب أن يكون صحيح",
		style: "النمط",
		background_image: "صورة الخلفية",
		primary_color: "اللون الرئيسي",
		add_role: "إضافة صلاحية",
		edit_role: "تعديل صلاحية",
		view_role: "مشاهدة صلاحية",
		view: "مشاهدة",
		no_access: "بدون صلاحيات",
		profile_and_activities: "الملف الشخصي والأنشطة",
		full_access: "جميع الصلاحيات",
		cashier: "كاشير",
		sell_without_stock: "البيع بدون مخزون",
		i_would_like_to_book_home_service_please: "أنا أريد حجز خدمة منزلية من فضلك",
		complain_sent_successfully: "تم إرسال الرأي/الشكوى بنجاح",
		book_appointment: "حجز موعد",
		telephone_should_start_with_plus_or_zero_zero: "رقم الهاتف يجب أن يبدأ ب (+ أو 00)",
		whatsapp_should_not_start_with_plus_or_zero_zero: "الواتس آب يجب أن لا يبدأ ب (+ أو 00)",
		username_is_incorrect: "إسم المستخدم غير صحيح",
		password_is_incorrect: "كلمة المرور غير صحيحة",
		should_be_a_number: "يجب أن يكون عدد",
		should_be_an_integer: "يجب أن يكون عدد صحيح",
		should_be_more_than: "يجب أن يكون أكثر من",
		should_be_less_than: "يجب أن يكون أقل من",
		should_be_letters: "يجب أن يكون أحرف",
		should_be_more_than_or_equal_to: "يجب أن يكون أكبر من أو يساوي",
		should_be_less_than_or_equal_to: "يجب أن يكون أصغر من أو يساوي",
		should_be_a_number_with_max_two_decimals: "يجب أن يكون رقم بعدد منازل عشرية 2 كحد أقصى",
		should_contain_only_digits: "يجب أن يحتوي على أرقام فقط",
		should_be_same_as: "يجب أن يكون مطابق ل",
		should_be_after: "يجب أن يكون بعد",
		should_be_after_or_equal: "يجب أن يكون بعد أو مساوي ل",
		should_be_before_or_equal: "يجب ان يكون أقل أو مساوي ل",
		should_be_multiple_of: "يجب أن يكون من مضاعفات",
		length_should_be_maximum: "طوله يجب أن يكون كحد أقصى",
		length_should_be: "طوله يجب أن يكون",
		should_contain_only_numbers_zero_to_nine_and_english_letters_small_capital: "يجب أن يحتوي فقط على أرقام (0 - 9) وأيضا حروف إنجليزية (a-z)(A-Z)",
		prefix: "البادئة",
		is_email_field: "هو حقل بريد إلكتروني",
		this_field_should_be_email: "هذا الحقل يجب أن يكون بريد إلكتروني",
		access: "الوصول",
		allow_change_price_in_invoice: "السماح بتغيير السعر في الفاتورة",
		customers_care_services: "خدمات العناية بالمستأجرين",
		customers_care: "العناية بالمستأجرين",
		others: "أخرى",
		sell: "بيع",
		sell_gift_card: "بيع بطاقة هدية",
		gift_card_value: "قيمة بطاقة الهدية",
		country_of_residency: "دولة الإقامة",
		you_are_connected_to: "لديك صلاحيات",
		current_password: "كلمة المرور الحالية",
		new_password: "كلمة المرور الجديدة",
		confirm_new_password: "تأكيد كلمة المرور الجديدة",
		i_want_to_add_expense: "أرغب بإضافة مصروف",
		quantity_added_successfully: "تم إضافة الكمية بنجاح",
		stock_operations_report: "تقرير عمليات المخزون",
		quantity_adjusted_successfully: "تم تعديل الكمية بنجاح",
		quantity_used_successfully: "تم إستخدام الكمية بنجاح",
		create_credit_note: "إنشاء اشعار دائن",
		credit_note: "اشعار دائن",
		service_on_item: "الخدمة / السلعة",
		qty: "الكمية",
		returned_value_including_vat: "القيمة المرجعة شاملة الضريبة",
		i_want_to_generate_manual_credit_note: "أرغب بإصدار اشعار دائن يدوي",
		edit_payment: "تعديل دفعة",
		business_name: "الإسم التجاري",
		daily_reports: "التقارير اليومية",
		remove_appointment: "حذف موعد",
		are_you_sure_you_want_remove_the_appointment: "هل أنت متأكد من حذف الموعد؟",
		are_you_sure_you_want_to_deposit_amount_to_this_customer: "هل أنت متأكد من أنك تريد إيداع ({amount}) لهذا المستأجر؟",
		are_you_sure_to_add_penalty_with_amount_to_this_customer: "هل أنت متأكد من أنك تريد إضافة غرامة بقيمة ({amount}) لهذا المستأجر؟",
		deposit: "دفعة مقدما",
		customer_edited_successfully: "تم تعديل المستأجر بنجاح",
		gifts_cards_used: "بطاقات الهدايا المستخدمة",
		gift_card: "بطاقة هدية",
		custom: "خاص",
		allow_add_free_gift_card: "السماح بإضافة بطاقة هدية مجانية",
		add_free_gift_card: "إضافة بطاقة هدية مجانية",
		percentage_can_not_be_zero_when_status_is_active: "النسبة يجب ان تكون أكبر من صفر عند تفعيل الضريبة",
		add_expenses_type: "إضافة نوع مصروف",
		add_supplier: "إضافة مورد",
		expense_type_added_successfully: "تم إضافة نوع المصروف بنجاح",
		supplier_added_successfully: "تم إضافة المورد بنجاح",
		expense_type_status_updated_successfully: "تم تحديث حالة نوع المصروف بنجاح",
		expense_type_already_exist: "نوع المصروف موجود مسبقا",
		supplier_status_updated_successfully: "تم تحديث حالة المورد",
		supplier_already_exist: "المورد موجود مسبقا",
		supplier_edited_successfully: "تم تعديل المورد بنجاح",
		expense_type_edited_successfully: "تم تعديل نوع المصروف بنجاح",
		edit_expense_type: "تعديل نوع المصروف",
		edit_supplier: "تعديل المورد",
		employee_profile_updated_successfully: "تم تحديث تفاصيل الموظف بنجاح",
		remaining_amount_will_be_registered_in_receivable: "سيتم تسجيل المبلغ المتبقي في ديون المستأجرين",
		pay_pending: "دفع معلق",
		pay_now: "الدفع الآن",
		add_payment: "إضافة دفعة",
		payment_added_successfully: "تم إضافة الدفعة بنجاح",
		partially_paid: "مدفوعة جزئيا",
		not_paid: "غير مدفوعة",
		offer_added_successfully: "تم إضافة العرض بنجاح",
		offer_edited_successfully: "تم تعديل العرض بنجاح",
		some_block_times_conflict_with_previous_block_times: "تتعارض بعض أوقات الحظر مع أوقات الحظر الموجودة",
		block_times_added_successfully: "تم إضافة أوقات الحظر بنجاح",
		some_employees_are_not_allowed: "بعض الموظفين غير مسموح بهم",
		employee_does_not_have_email: "الموظف لا يملك بريد إلكتروني",
		email_sent_successfully: "تم إرسال البريد الإلكتروني بنجاح",
		block_time_cancelled_successfully: "تم إلغاء وقت الحظر بنجاح",
		service_added_successfully: "تم إضافة الخدمة بنجاح",
		customer_has_another_appointment_at_the_same_time: "المستأجر لديه موعد آخر في نفس التوقيت",
		you_have_another_appointment_at_the_same_time: "لديك موعد آخر بنفس التوقيت",
		please_add_at_least_one_service_to_the_appointment: "الرجاء إضافة على الأقل خدمة واحدة للموعد",
		employee_has_another_appointment_at_the_same_time: "الموظف لديه موعد آخر في نفس التوقيت",
		some_appointments_conflict_with_block_times: "بعض المواعيد تتعارض مع أوقات الحظر",
		employee_changed_successfully: "تم تغيير الموظف بنجاح",
		employee_is_the_same_as_old_employee: "الموظف هو نفس الموظف السابق",
		employee_not_exist: "الموظف غير موجود",
		item_not_exist: "العنصر غير موجود",
		changed: "تغيّر",
		edit_gift_card: "تعديل بطاقة هدية",
		gift_card_edited_successfully: "تم تعديل بطاقة الهدية بنجاح",
		reset_all_customers: "إعادة ضبط كل المستأجرين",
		are_you_sure_you_want_to_reset_the_sent_flag_for_all_customers: "هل أنت متأكد من أنك تريد إعادة ضبط تم الإرسال لكل المستأجرين؟",
		sent_status_reseted_successfully: "تم إعادة ضبط تم الإرسال بنجاح",
		customer_status_updated_successfully: "تم تحديث حالة المستأجر بنجاح",
		employee_stauts_updated_successfully: "تم تحديث حالة الموظف بنجاح",
		employee_access_edited_successfully: "تم تعديل صلاحيات دخول الموظف بنجاح",
		employee_edited_successfully: "تم تعديل الموظف بنجاح",
		new_updated_timing_will_be_applied_to_the_selected_employee_in_appointment_screen:
			"سيتم تطبيق التوقيت الجديد/المحدث على الموظف المحدد في شاشة الموعد",
		new_updated_timing_will_be_applied_to_the_selected_employee_in_online_booking_screen:
			"سيتم تطبيق التوقيت الجديد/المحدث على الموظف المختار في شاشة الحجز عبر الإنترنت",
		add_holiday: "إضافة إجازة",
		holiday_added_successfully: "تم إضافة الإجازة بنجاح",
		edit_holiday: "تعديل إجازة",
		holiday_edited_successfully: "تم تعديل الإجازة بنجاح",
		holiday_deleted_successfully: "تم حذف الإجازة بنجاح",
		note_added_successfully: "تم إضافة الملاحظة بنجاح",
		note_edited_successfully: "تم تعديل الملاحظة بنجاح",
		note_deleted_successfully: "تم حذف الملاحظة بنجاح",
		role_added_successfully: "تم إضافة الصلاحيات بنجاح",
		role_edited_successfully: "تم تعديل الصلاحيات بنجاح",
		credit_note_created_successfully: "تم إنشاء إشعار الدائن بنجاح",
		new_supplier: "مورد جديد",
		new_expense_type: "نوع مصروف جديد",
		expense_added_successfully: "تم إضافة المصروف بنجاح",
		payment_edited_successfully: "تم تعديل الدفعة بنجاح",
		billing: "الفواتير",
		category_edited_successfully: "تم تعديل المجموعة بنجاح",
		remove_category_and_it_is_services_from_billing_screen: "إزالة المجموعة وخدماتها من شاشة الفوترة",
		remove_category_and_it_is_services_from_appointment_screen: "إزالة المجموعة وخدماتها من شاشة المواعيد",
		remove_category_and_it_is_services_from_menu_screen: "إزالة المجموعة وخدماتها من شاشة القائمة",
		remove_services_within_group_from_assigned_employee: "إزالة الخدمات داخل المجموعة من الموظف المعين",
		hide_category_from_customers: "إخفاء المجموعة عن المستأجرين",
		status_updated_successfully: "تم تحديث الحالة بنجاح",
		show_to_customer_updated_successfully: "تم تحديث حالة إظهار للمستؤجر بنجاح",
		order_updated_successfully: "تم تحديث الترتيب بنجاح",
		category_added_successfully: "تم إضافة المجموعة بنجاح",
		category_already_exist: "المجموعة موجودة مسبقا",
		category_exist_in_categories_to_activate_you_can_activate_it_from_there: "المجموعة موجودة في المجموعات للتفعيل، الرجاء تفعيلها من هناك",
		service_edited_successfully: "تم تعديل الخدمة بنجاح",
		disable_service: "تعطيل الخدمة",
		remove_service_from_billing_appointment_menu_screens: "حذف الخدمة من شاشات (الفواتير، الموعد، القائمة)",
		remove_service_from_assigned_group: "إزالة الخدمة من المجموعة المعينة",
		remove_service_from_assigned_employee: "إزالة الخدمة من الموظف المعين",
		hide_service_from_customers: "إخفاء الخدمة عن المستأجرين",
		remove_offer_from_billing_menu_screens: "إزالة العرض من شاشات (الفواتير والقائمة)",
		hide_offer_from_customers: "إخفاء العرض من المستأجرين",
		stock: "المخزون",
		product_edited_successfully: "تم تعديل المنتج بنجاح",
		disable_product: "تعطيل منتج",
		disable_selling_adding_adjusting_withdraw_from_inventory: "تعطيل البيع والإضافة والتعديل والسحب من المخزون",
		product_status_updated_successfully: "تم تحديث حالة المنتج بنجاح",
		product_defined_successfully: "تم تعريف المنتج بنجاح",
		image_updated_successfully: "تم تحديث الصورة بنجاح",
		image_deleted_successfully: "تم حذف الصورة بنجاح",
		controller_updated_successfully: "تم تحديث المتحكم بنجاح",
		account_backup_sent_successfully_to: "تم إرسال النسخة الاحتياطية للحساب بنجاح إلى",
		payment_type_status_updated_successfully: "تم تحديث حالة طريقة الدفع بنجاح",
		trn_number_should_be_15_characters: "الرقم الضريبي يجب أن يكون 15 حرف",
		everything: "كل شيء",
		discount_program_edited_successfully: "تم تعديل كود الخصم بنجاح",
		discount_program_already_exist: "كود الخصم موجود مسبقا",
		discount_program_added_successfully: "تم إضافة كود الخصم بنجاح",
		discount_program_status_updated_successfully: "تم تحديث حالة كود الخصم بنجاح",
		loyality_program_edited_successfully: "تم تعديل برنامج الولاء بنجاح",
		loyality_program_status_updated_successfully: "تم تحديث حالة برنامج الولاء بنجاح",
		invoice_setting_controller_edited_successfully: "تم تعديل المتحكم بالفاتورة بنجاح",
		logo_edited_successfully: "تم تعديل الشعار بنجاح",
		logo_deleted_successfully: "تم حذف الشعار بنجاح",
		account_not_exist: "الحساب غير موجود",
		excel_is_not_correct: "الإكسل غير صحيح",
		name_is_required: "الإسم مطلوب",
		name_should_be_letters: "الإسم يجب أن يكون أحرف",
		mobile_is_required: "الموبايل مطلوب",
		mobile_is_not_correct: "الموبايل غير صحيح",
		mobile_already_exist_in_the_account_with_customer_name: "رقم المستأجر موجود مسبقا في الحساب مع مستؤجر باسم",
		mobile_already_exist_in_the_sheet_with_customer_name: "رقم المستأجر موجود مسبقا بالاكسل مع مستؤجر باسم",
		customers_uploaded_successfully: "تم رفع المستأجرين بنجاح",
		invoice_setting_header_and_footer_edited_successfully: "تم تعديل إعدادات الترويسة والذيل للفاتورة بنجاح",
		online_setting_disabled_successfully: "تم تعطيل إعدادات الأونلاين بنجاح",
		online_setting_activated_successfully: "تم تفعيل إعدادات الأونلاين بنجاح",
		price_list_edited_successfully: "تم تعديل قائمة الأسعار بنجاح",
		online_booking_edited_successfully: "تم تعديل حجوزات الأونلاين بنجاح",
		social_media_edited_successfully: "تم تعديل التواصل الاجتماعي بنجاح",
		complains_edited_successfully: "تم تعديل الشكاوى والإقتراحات بنجاح",
		working_hours_edited_successfully: "تم تعديل ساعات العمل بنجاح",
		home_services_edited_successfully: "تم تعديل الخدمات المنزلية بنجاح",
		style_edited_successfully: "تم تعديل النمط بنجاح",
		background_image_edited_successfully: "تم تعديل صورة الخلفية بنجاح",
		background_image_deleted_successfully: "تم حذف صورة الخلفية بنجاح",
		online_setting_not_exist: "إعدادات الأونلاين غير موجودة",
		notification_setting_edited_successfully: "تم تعديل إعدادات الإشعارات بنجاح",
		body: "الجسم",
		some_data_not_allowed: "بعض البيانات غير مسموح بها",
		edit_appointment_sms: "تعديل رسالة موعد الواتساب ",
		edit_offers_whatsapp: "تعديل رسالة عروض الواتساب",
		start_date: "تاريخ البداية",
		end_date: "تاريخ النهاية",
		url: "الرابط",
		appointment_cancelled_successfully: "تم إلغاء الموعد بنجاح",
		appointments_cancelled_successfully: "تم إلغاء المواعيد بنجاح",
		appointment_attended_successfully: "تم الحضور للموعد بنجاح",
		cancel_appointments: "إلغاء المواعيد",
		are_you_sure_you_want_to_cancel_the_appointments: "هل أنت متأكد من أنك تريد إلغاء المواعيد؟",
		suggest_development_sent_successfully: "تم إرسال اقتراح التطوير بنجاح",
		current_password_is_incorrect: "كلمة المرور الحالية غير صحيحة",
		password_changed_successfully: "تم تغيير كلمة المرور بنجاح",
		sorry_there_is_an_error_happened: "عفوا حدث خطأ",
		send_to_buyer: "إرسال للمشتري",
		send_to_beneficiary: "إرسال للمستفيد",
		gift_card_logo: "شعار بطاقة الهدية",
		from_salon: "من الصالون",
		beneficiary: "المستفيد",
		this_payment_was_already_cancelled: "هذه الدفعة ملغية مسبقا",
		only_deposit_payments_can_be_cancelled: "فقط الدفعات المقدمة يمكن إلغاءها",
		data_was_not_updated_successfully: "لم يتم تحديث البيانات بنجاح",
		payment_cancelled_successfully: "تم إلغاء الدفعة بنجاح",
		payment_paid_successfully: "تم دفع الدفعة بنجاح",
		authorization_code_is_required_when_the_payment_is_credit_card: "رقم الموافقة مطلوب في حال كانت طريقة الدفع بطاقة إئتمانية",
		customer_does_not_have_enough_balance: "رصيد المستأجر غير كافي",
		payment_method_not_exist: "طريقة الدفع غير موجودة",
		payment_method_not_changed: "طريقة الدفع لم تتغير",
		should_be_letters_and_numbers: "يجب أن يكون أحرف وأرقام",
		payment_not_exist: "الدفعة غير موجودة",
		payment_status_not_allowed_to_cancel: "حالة الدفعة لا تسمح بإلغائها",
		service_added_sucessfully: "تم إضافة الخدمة بنجاح",
		item: "عنصر",
		all_invoice: "كل الفاتورة",
		part_from_invoice: "جزء من الفاتورة",
		free: "مجاني",
		daily_reports_sent_successfully: "تم إرسال التقارير اليومي بنجاح",
		complain_send: "إرسال الشكوى",
		seems_there_is_nothing_here: "الصفحة غير صحيحة",
		return_home: "العودة للرئيسية",
		backup_reports: "التقارير الاحتياطية",
		tiktok: "تيك توك",
		tiktok_should_start_with: "تيك توك يجب أن يبدأ ب",
		employee_email_already_exist: "البريد الإلكتروني للموظف موجود مسبقا",
		paid_voucher: "قسيمة مدفوعة",
		loyality_voucher: "قسيمة ولاء",
		free_voucher: "قسيمة مجانية",
		notifications: "التنبيهات",
		updates: "التحديثات",
		issued_gifts_cards: "بطاقات الهدايا المصدرة",
		is_not_arabic_text: "ليس نصا عربيا",
		is_not_english_text: "ليس نصا إنجليزيا",
		password_reset_link_sent_successfully_to_the_email: "تم إرسال رابط إعادة ضبط كلمة المرور إلى البريد الإلكتروني",
		set_new_password: "كلمة المرور جديدة",
		enter_you_new_password: "كلمة المرور الجديدة الخاصة بك",
		confirm_password: "تأكيد كلمة المرور",
		submit_send: "إرسال",
		password_set_successfully: "تم حفظ كلمة المرور بنجاح",
		prices_are_vat_inclusinve: "الأسعار شاملة ضريبة القيمة المضافة",
		appointment_rescheduled: "تم إعادة جدولة الموعد بنجاح",
		copy_url_for_share: "نسخ الرابط للمشاركة",
		copied_to_clipboard: "تم النسخ للحافظة",
		trn_number_for_ksa_should_start_with_three_and_end_with_three: "الرقم الضريبي للسعودية يجب أن يبدأ ب 3 وينتهي ب 3",
		alias_name_is_incorrect: "الاسم المستعار غير صحيح",
		service_not_exist: "الخدمة غير موجودة",
		services_group_not_exist: "المجموعة غير موجودة",
		please_select_booking_date: "الرجاء إختيار تاريخ الحجز",
		book_today: "إحجز اليوم",
		do_you_want_to_add_another_service: "هل تريد حجز خدمة أخرى؟",
		customer_information: "معلومات المستأجر",
		services_are_incorrect: "الخدمات غير صحيحة",
		employees_are_incorrect: "بيانات الموظفين غير صحيحة",
		appointment_booked_successfully: "تم حجز الموعد بنجاح",
		add_more: "إضافة المزيد",
		review_booking: "مراجعة الحجز",
		total_services: "عدد الخدمات",
		confirm_booking: "تأكيد الحجز",
		light_primary_color: "اللون الرئيسي المخفّف",
		there_is_no_available_times_for_the_selected_date: "لا يوجد أوقات متاحة للتاريخ المختار",
		please_select_another_date: "الرجاء إختيار تاريخ آخر",
		reset: "الإفتراضي",
		incorrect_data: "بيانات غير صحيحة",
		reset_color: "إعادة اللون الإفتراضي",
		are_you_sure_you_want_to_reset_the_color: "هل أنت متأكد من إنك تريد إعادة اللون الإفتراضي؟",
		your_appointment_has_been_booked_successfully: "تم تأكيد الحجز بنجاح",
		booking_id: "رقم الحجز",
		booking_details: "تفاصيل الحجز",
		please_select_date: "الرجاء إختيار التاريخ",
		wizard_activate_vat: "تفعيل الضريبة",
		review: "مراجعة",
		change_booking_date: "تعديل تاريخ الحجز",
		this_option_will_delete_all_selected_services_from_basket: "هذا الخيار سوف يقوم بحذف كل الخدمات المختارة لديك في السلة",
		remove_last_service: "حذف آخر خدمة",
		this_option_will_return_you_to_home_page: "هذا الخيار سوف يعيدك للصفحة الرئيسية",
		mobile_already_exist: "رقم الموبايل موجود مسبقا",
		employee_mobile_already_exist: "رقم موبايل الموظف موجود مسبقا",
		email_already_exist: "البريد الإلكتروني موجود دسبقا",
		email_is_not_valid: "البريد الإلكتروني ليس صحيحا",
		edit_customer_email: "تعديل البريد الإلكتروني للمستؤجر",
		customer_email_already_exist: "البريد الإلكتروني للمستؤجر موجود مسبقا",
		customer_email_edited_successfully: "تم تعديل البريد الإلكتروني للمستؤجر بنجاح",
		customer_mobile_already_exist: "رقم موبايل المستأجر موجود مسبقا",
		current_quantity: "الكمية الحالية",
		powered_by: "مشغل بواسطة",
		are_you_sure_you_want_to_cancel_the_payment_this_will_change_your_accounting_statements:
			"هل أنت متأكد من إلغاء الدفعة؟ هذا سيؤدي إلى تغيير البيانات المحاسبية",
		cancel_payment: "إلغاء دفعة",
		daily_reports_sent_successfully_to: "تم إرسال التقارير اليومية بنجاح إلى",
		deposit_return: "إرجاع دفعة مقدمة",
		to_activate_this_option_paid_amount_should_be_less_than_total_sales: "لتفعيل الخيار، يجب أن يكون المبلغ المدفوع أقل من قيمة المبيعات",
		to_activate_this_option_paid_amount_should_be_less_than_total_sales_and_payment_method_is_selected_for_all_payments:
			"لتفعيل الخيار يجب أن يكون المبلغ المدفوع أقل من قيمة المبيعات وطريقة الدفع مختارة لجميع الدفعات",
		f11_for_full_mode: "F11 لوضع ملء الشاشة",
		select_date: "إختر اليوم",
		product_request_sent_successfully: "تم إرسال الطلب بنجاح",
		product: "المنتج",
		invoice_not_exist: "الفاتورة غير موجودة",
		invoice_has_vat_and_can_not_be_cancelled: "يوجد ضريبة على الفاتورة ولا يمكن إلغاءها",
		can_not_cancel_invoice_contains_products: "لا يمكن إلغاء فاتورة تحتوي على منتجات",
		can_not_cancel_sell_gift_card_invoice: "لا يمكن إلغاء فاتورة بيع بطاقة هدية",
		invoice_cancelled_successfully: "تم إلغاء الفاتورة بنجاح",
		cancel_invoice: "إلغاء فاتورة",
		are_you_sure_you_want_to_cancel_the_invoice: "هل أنت متأكد من أنك تريد إلغاء الفاتورة؟",
		can_not_use_this_gift_card_since_it_is_for_another_customer: "لا يمكن إستخدام بطاقة الهدية لأنها لمستؤجر آخر",
		you_dont_have_permission_to_use_this_screen_please_contact_system_admin: "ليس لديك صلاحيات لاستخدام هذه الشاشة ، يرجى الاتصال بمسؤول النظام",
		percentage_should_be_zero_when_status_is_disabled: "النسبة يجب أن تكون صفر عندما تكون الحالة معطلة",
		start: "البداية",
		all: "الكل",
		residancy_exipry: "تاريخ الإقامة",
		service_english_name_is_already_exist_in_active_or_disabled_services: "إسم الخدمة بالإنجليزية موجود مسبقا في الخدمات الفعّالة أو المعطلة",
		service_arabic_name_is_already_exist_in_active_or_disabled_services: "إسم الخدمة بالعربية موجود مسبقا في الخدمات الفعّالة أو المعطلة",
		group_english_name_is_already_exist_in_active_or_disabled_services: "إسم المجموعة بالإنجليزية موجود مسبقا في المجموعات الفعّالة أو المعطلة",
		group_arabic_name_is_already_exist_in_active_or_disabled_services: "إسم المجموعة بالعربية موجود مسبقا في المجموعات الفعّالة أو المعطلة",
		english_name_is_already_exist: "الإسم بالإنجليزية موجود مسبقا",
		arabic_name_is_already_exist: "الإسم بالعربية موجود مسبقا",
		change_branch: "تغيير الفرع",
		remember_password: "تذكر كلمة المرور",
		the_alias_is_not_correct_or_account_is_disabled: "الإسم المستعار ليس صحيحا أو أن الحساب معطّل",
		offer_type: "نوع العرض",
		fixed: "ثابت",
		variable: "متغيّر",
		max_number_of_services_to_use: "الحد الأقصى لعدد الخدمات للإستخدام",
		max_number_of_services_to_use_should_be_less_than_count_of_services: "الحد الأقصى لعدد الخدمات للإستخدام يجب أن يكون أقل من عدد الخدمات",
		max_number_of_services_to_use_is_required: "الحد الأقضى للخدمات للإستخدام مطلوب",
		some_offer_services_are_disabled: "بعض خدمات العرض معطلة",
		incorrect_parameters_for_offer: "مدخلات خاطئة للعرض",
		offer_not_exist: "العرض غير موجود",
		offer_is_disabled: "العرض معطّل",
		offer_expired: "العرض منتهي",
		fixed_services: "خدمات محددة",
		selection_from_customer: "خدمات مختارة من المستأجر",
		owner_name: "إسم المالك",
		service_date_and_time: "التاريخ والوقت للخدمة",
		this_product_is_already_defined: "هذا المنتج معرف مسبقا",
		this_product_is_already_exist: "هذا المنتج موجود مسبقا",
		there_is_already_a_request_for_this_product: "يوجد طلب مسبقا لهذا المنتج",
		already_defined: "معرف مسبقا",
		due_date: "تاريخ الإستحقاق",
		bill_from: "فاتورة من",
		expense_type: "نوع المصروف",
		bill_to: "فاتورة إلى",
		past: "سابقا",
		system_updates: "تحديثات النظام",
		offer_price_with_vat_without_vat: "سعر العرض (مع / بدون ضريبة القيمة)",
		sell_price_without_offer: "سعر البيع بدون عرض",
		some_appointments_to_add_are_at_the_same_time: "بعض المواعيد المراد إضافتها في نفس الوقت",
		appointment_overbooking: "حجز موعد زائد",
		activate_product: "تفعيل منتج",
		please_activate_some_products_for_sell_or_for_internal_use: "الرجاء تفعيل بعض المنتجات المخصصة للبيع أو الإستخدام الداخلي",
		employee_report: "تقرير الموظف",
		employee_commission_percentage: "نسبة عمولة الموظف",
		use_package_service: "إستخدام خدمة من الباقة",
		reminder_sent: "تم إرسال التذكير",
		refresh_the_page: "تحديث الصفحة",
		full_pay: "دفع كامل",
		partial_pay: "دفع جزئي",
		remaining_amount: "المبلغ المتبقي",
		this_product_is_already_exist_with_different_name: "هذا المنتج موجود مسبقا بإسم مختلف",
		old_sales: "مبيعات سابقة",
		appointment_not_exist_or_cancelled: "الموعد غير موجود أو ملغى",
		this_product_is_not_exist: "هذا المنتج غير موجود",
		group_exist_in_groups_to_activate_you_can_activate_it_from_there: "المجموعة موجود في المجموعات للتفعيل، يمكنك تفعيلها من هناك",
		group_already_exist: "المجموعة موجودة مسبقا",
		group_not_exist: "المجموعة غير موجودة",
		service_exist_in_services_to_activate_you_can_activate_it_from_there: "الخدمة موجودة في الخدمات للتفعيل، يمكنك تفعيل الخدمة من هناك",
		service_already_exist: "الخدمة موجودة مسبقا",
		your_request_has_been_submitted_successfully_suggested_product_will_be_approved_within_24_hours:
			"تم إرسال الطلب بنجاح، سيتم الموافقة على المنتج المقترح خلال 24 ساعة",
		allow_employee_overbooking: "السماح بالحجز الزائد للموظف",
		allow_customer_overbooking: "السماح بالحجز الزائد للمستؤجر",
		remember: "تذكر",
		print_services: "طباعة الخدمات",
		total_products: "عدد المنتجات",
		services_total: "مجموع الخدمات",
		products_total: "مجموع المنتجات",
		employee_profile: "ملف الموظف",
		invoice_hash: "رقم الفاتورة",
		supplier_invoice_date: "تاريخ فاتورة المورد",
		edit_expense: "تعديل مصروف",
		expense_edited_successfully: "تم تعديل المصروف بنجاح",
		cashier_summary: "ملخص الكاشير",
		authorization_code_is: "رقم الموافقة",
		are_you_sure_you_want_to_add_the_quantity: "هل أنت متأكد من إضافة الكمية؟",
		notify_management: "إرسال تنبيه للإدارة",
		notifications_sent_to_management_successfully: "تم إرسال التنبيهات للإدارة بنجاح",
		change_order: "تغيير الترتيب",
		please_add_the_services_that_the_employee_is_fluent_in_from_services_page: "يرجى إضافة الخدمات التي يجيدها الموظف من صفحة الخدمات",
		new_block_times_conflict_with_existing_block_times: "أوقات الحظر الجديدة تتعارض مع أوقات حظر موجودة",
		new_block_times_conflict_with_existing_appointments: "أوقات الحظر الجديدة تتعارض مع مواعيد موجودة",
		there_is_a_product_with_this_barcode_is_already_defined: "يوجد منتج بالباركود المرفق معرّف مسبقا",
		there_is_a_product_with_this_barcode_is_already_defined_you_need_to_activate_it: "يوجد منتج بالباركود المرفق معرّف مسبقا، أنت بحاجة لتفعيله",
		services_data_are_incorrect: "بيانات الخدمات غير صحيحة",
		employees_data_are_incorrect: "بيانات الموظفين غير صحيحة",
		cancel_invoice_or_create_credit_note: "إلغاء فاتورة / إنشاء اشعار دائن",
		edit_payment_method: "تعديل طريقة الدفع",
		cancel_advanced_payment: "إلغاء دفعة مقدمة",
		credit: "رصيد",
		add_penalty: "إضافة غرامة",
		penalty_added_successfully: "تم إضافة الغرامة بنجاح",
		customer_no_show: "عدم حضور المستأجر",
		new_penalty: "غرامة جديدة",
		penalty: "غرامة",
		account_balance_is_not_enough: "رصيد الحساب غير كافي",
		penalties_reports: "تقارير الغرامات",
		customers_penalties_report: "تقرير غرامات المستأجرين",
		customers_penalties: "غرامات المستأجرين",
		customers_offers_report: "تقرير عروض المستأجرين",
		remaining_services: "الباقي من الخدمات",
		offer: "العرض",
		advanced_credit: "رصيد إدخاري",
		confirm_appointment: "حجز موعد",
		global_appointments_confirm_appointment: "تأكيد الموعد",
		global_appointments_unconfirm_appointment: "إلغاء تأكيد الموعد",
		other_reasons: "أسباب أخرى",
		debit_note: "إشعار مدين",
		gift_card_description: "وصف بطاقة الهدية",
		payments_total_is_not_the_same_as_gift_card_value: "مجموع الدفعات لا يساوي قيمة بطاقة الهدية",
		training_account: "حساب التدريب",
		appointment_reminder: "تذكير بالموعد",
		reschedule_reminder: "تذكير لإعادة الحجز",
		rate_us: "تقييم الصالون",
		customer_care: "العناية بالمستأجرين",
		appointments_reminder: "التذكير بالمواعيد",
		no_show_reschedule: "إعادة جدولة عدم الحضور",
		edit_reschedule_appointments_message: "تعديل رسالة إعادة جدولة المواعيد",
		edit_salon_rate_message: "تعديل رسالة تقييم الصالون",
		no_expiry: "غير منتهي",
		please_spend_the_current_quantity_and_disable_the_product_later: "الرجاء صرف الكمية الحالية وتعطيل المنتج لاحقا",
		export_to_excel_and_print: "التصدير لإكسل والطباعة",
		customer_account_balance_reminder: "تذكير برصيد حساب المستأجر",
		edit_customer_account_balance_reminder_sms: "تعديل رسالة التذكير برصيد الحساب للمستؤجر",
		customer_account_balance_reminder_sent_successfully: "تم إرسال التذكير برصيد حساب المستأجر بنجاح",
		account_balance: "رصيد الحساب",
		rate_us_for_issued_invoices: "تقييم الصالون للفواتير المصدرة",
		rate_us_for_services_from_offers: "تقييم الصالون للخدمات من العروض",
		email_booking_reference: "البريد الإلكتروني (إختياري)",
		expense: "المصروف",
		supplier_not_exist: "المورد غير موجود",
		expense_type_not_exist: "نوع المصروف غير موجود",
		show_invoice: "عرض الفاتورة",
		submit_survey: "إرسال التقييم",
		kindly_rate_our_services: "الرجاء تقييم خدماتنا",
		rating: "التقييم",
		invoice_rated_successfully: "تم تقييم الفاتورة بنجاح",
		invoice_already_rated: "تم تقييم الفاتورة مسبقا",
		some_items_not_exist: "بعض العناصر غير موجودة",
		no_internet_connection: "لا يوجد إتصال بالإنترنت",
		please_check_and_try_again: "الرجاء التحقق والمحاولة مجددا",
		rating_from_5: "التقييم (من 5)",
		send_survey: "إرسال التقييم",
		send_invoice: "إرسال الفاتورة",
		survey_has_been_submitted_successfully: "تم مشاركة التقييم بنجاح",
		new_customers: "المستأجرين الجدد",
		another_session_is_already_exist_for_this_user_do_you_want_to_logout_from_it: "هناك جلسة أخرى فعالة لهذا المستخدم، هل تريد تسجيل الخروج منها؟",
		uploaded_file_must_be_one_of_these_extensions: "الملف المرفوع يجب أن يكون امتداده من هذه الامتدادات",
		percentage_of_each_employee: "نسبة كل موظف",
		user_is_not_active: "الموظف غير فعّال",
		user_not_exist: "الموظف غير موجود",
		employees_performance: "أداء الموظفين",
		best_services: "أفضل الخدمات",
		send_invoice_in_email_to_owner: "إرسال الفاتورة ببريد إلكتروني للمالك",
		the_session_has_been_expired_or_another_person_logged_in_with_the_same_user_please_login_again:
			"إنتهت صلاحية الجلسة أو قام أحد آخر بتسجيل الدخول بنفس المستخدم، الرجاء تسجيل الدخول مرة أخرى",
		another_person_logged_in_with_the_same_user: "قام شخص آخر بتسجيل الدخول بنفس المستخدم",
		you_are_not_logged_in_please_login_first: "أنت لم تقم بتسجيل الدخول، الرجاء تسجيل الدخول أولا",
		account_name: "إسم الحساب",
		total_sales_including_vat: "المبيعات شاملة الضريبة",
		vat_summary: "ملخص الضريبة",
		net_income: "المحصلة",
		general_accounting_report: "تقرير المحاسبة العام",
		vat_report: "تقرير ضريبة القيمة المضافة",
		vat_report_collected_vat: "الضريبة المحصلة من المبيعات",
		paid_vat_expenses: "الضريبة المدفوعة على المصاريف",
		vat_paid_to_goverment: "المحصلة",
		monthly_income: "الدخل الشهري",
		most_active_times: "أكثر الأوقات نشاطا",
		length_should_be_minimum: "طوله يجب أن يكون كحد أدنى",
		last_year: "السنة السابقة",
		max: "الحد الأقصى",
		last_three_months: "آخر 3 شهور",
		reminder_sent_successfully: "تم إرسال التذكير بنجاح",
		expense_with_same_supplier_invoice_number_and_date_already_exist_are_you_sure_that_you_want_to_continue:
			"يوجد مصروف بنفس رقم فاتورة المورد والتاريخ، هل أنت متأكد من أنك تريد المواصلة؟",
		expected_expense_with_same_supplier_invoice_number_and_date_already_exist_are_you_sure_that_you_want_to_continue:
			"يوجد مصروف متوقع بنفس رقم فاتورة المورد والتاريخ، هل أنت متأكد من أنك تريد المواصلة؟",
		all_services: "جميع الخدمات",
		you_can_choose_services: "يمكنك إختيار {numberOfServices} خدمات",
		add_item: "إضافة عنصر",
		add_custom_business_hours: "إضافة وقت عمل مخصص",
		edit_custom_business_hours: "تعديل وقت عمل مخصص",
		custom_business_hours: "أوقات العمل المخصصة",
		custom_working_hours_warning: "** سيتم تطبيق الاوقات الجديدة على اوقات المواعيد",
		this_date_already_has_record_you_can_edit_it: "هذا التاريخ يمتلك سجل يمكنك تعديله",
		custom_working_hours_added_successfully: "تم إضافة وقت العمل المخصص بنجاح",
		custom_working_hours_edited_successfully: "تم تعديل وقت العمل المخصص بنجاح",
		general_business_hours: "أوقات العمل العامة",
		orders: "الفواتير المعلقة",
		on_hold_report: "تقرير الفواتير المعلقة",
		order_id: "رقم الطلب",
		cancel_order: "إلغاء الطلب",
		are_you_sure_you_want_to_cancel_the_order: "هل أنت متأكد أنك تريد إلغاء الطلب؟",
		order_cancelled_successfully: "تم إلغاء الطلب بنجاح",
		employee_performance: "أداء الموظف",
		daily_income: "الدخل اليومي",
		show_on_online: "إظهار للأونلاين",
		hide_from_online: "اخفاء من الأونلاين",
		close: "إغلاق",
		services_without_the: "خدمات",
		image_should_be_square_width_equal_height: "الصورة يجب أن تكون مربعة (العرض = الارتفاع)",
		image: "الصورة",
		show_on_online_updated_successfully: "تم تعديل إظهار للأونلاين بنجاح",
		change_image: "تغيير الصورة",
		edit_offer: "تعديل العرض",
		send_automatic_rating_from_salonaty: "إرسال التقييم التلقائي من عقار بوكس",
		whatsapp_setting_edited_successfully: "تم تعديل إعدادات الواتس آب بنجاح",
		whatsapp_setting: "إعدادات الواتس آب",
		edit_whatsapp_setting: "تعديل إعدادات الواتس آب",
		whatsapp_bot: "الواتس آب الآلي",
		whatsapp_bot_setting: "إعدادات الواتس آب الآلي",
		disable_employee_will: "تعطيل الموظف سوف:",
		remove_employee_from_billing_screen: "إزالة الموظف من شاشة الفوترة",
		remove_employee_from_appointments_screen: "إزالة الموظف من شاشة المواعيد",
		remove_employee_from_assigned_services: "إزالة الموظف من الخدمات المعينة له",
		disable_employee_access: "تعطيل صلاحية دخول الموظف",
		activate_whatsapp_bot: "تفعيل الواتس آب الآلي",
		disconnected: "غير متصل",
		connected: "متصل",
		connect: "اتصل",
		connect_now: "اتصل الآن",
		please_contact_sales: "الرجاء التواصل مع المبيعات",
		whatsapp_already_connected: "الواتس آب متصل مسبقا",
		please_activate_two_step_verification_before_connect_to_whatsapp: "الرجاء تفعيل رمز المصادقة الثنائي في حساب الواتس آب الخاص بك قبل الاتصال",
		in_case_the_number_is_new_please_do_not_send_many_messages_to_prevent_blocking_the_number:
			"في حال أنّ الرقم جديد، الرجاء عدم إرسال كميات كبيرة من الرسائل حتى لا يتم إيقاف الرقم",
		connect_to_whatsapp: "اتصل بالواتس آب",
		you_can_not_use_offers_in_events_and_national_holidays: "لا يمكنك استخدام خدمات العروض في المناسبات والاعياد الوطنية",
		access_inside: "دخول",
		failed_to_get_the_qr: "لم يتم إحضار الرمز بنجاح",
		failed_to_send_the_message: "لم يتم إرسال الرسالة بنجاح",
		whatsapp_connected_successfully: "تم الإتصال بواتس آب بنجاح",
		whatsapp_not_connected_successfully: "لم يتم الإتصال بواتس آب بنجاح",
		whatsapp_message_sent_successfully: "تم إرسال رسالة الواتس آب بنجاح",
		whatsapp_is_not_connected: "الواتس آب غير متصل",
		you_do_not_have_valid_whatsapp_package_please_contact_sales: "لا يوجد لديك باقة واتس آب صالحة، الرجاء التواصل مع المبيعات",
		sent_updated_successfully: "تم تحديث إرسال بنجاح",
		please_connect_to_whatsapp_from_notifications_setting_screen: "الرجاء الاتصال مع الواتس آب من شاشة إعدادات التنبيهات",
		send_message_in_arabic: "إرسال الرسالة بالعربية",
		send_message_in_english: "إرسال الرسالة بالإنجليزية",
		one_of_the_messages_english_or_arabic_should_be_activated: "واحدة من الرسائل (الإنجليزية أو العربية) يجب أن تكون مفعلة",
		is_not_arabic_or_english_text: "يجب أن يكون أحرف (إنجليزية أو عربية) فقط",
		should_be_either_arabic_or_english_text_or_numbers: "يجب أن يكون إمّا أحرف (إنجليزية أو عربية) أو أرقام",
		your_package_is_paused_please_activate_it_from_notification_setting_screen:
			"الباقة الخاصة بك متوقفة مؤقتا، الرجاء تفعيلها من شاشة إعدادات التنبيهات",
		resume_package: "إستئناف الباقة",
		are_you_sure_you_want_to_resume_the_package: "هل أنت متأكد أنك تريد إستئناف الباقة؟",
		package_resumed_successfully: "تم إستئناف الباقة بنجاح",
		pause: "إيقاف مؤقتا",
		resume: "إستئناف",
		package_paused_successfully: "تم إيقاف الباقة مؤقتا بنجاح",
		pause_package: "إيقاف الباقة مؤقتا",
		are_you_sure_you_want_to_pause_the_package: "هل أنت متأكد أنك تريد إيقاف الباقة مؤقتا؟",
		offered_on_a_monthly_fee_by_third_party_companies_not_affiliated_with_ucitco: "تقدم برسوم شهرية عن طريق شركات طرف ثالث غير تابعة ل UCITCO",
		your_package_has_been_expired_please_contact_sales: "الباقة الخاصة بك قد إنتهت صلاحيتها، الرجاء التواصل مع المبيعات",
		day_without_the: "يوم",
		created_by: "أنشئ من",
		please_try_again_later: "الرجاء المحاولة لاحقا",
		send_salon_survey: "إرسال تقييم الصالون",
		payments_summary: "ملخص الدفعات",
		paused: "متوقف مؤقتا",
		send_issued_invoice_to_customer: "إرسال الفاتورة المصدرة للمستؤجر",
		send_employees_rating_from_salonaty: "إرسال تقييم الموظفين من عقار بوكس",
		send_appointment_confirmation_to_customer: "إرسال تأكيد الموعد للمستؤجر",
		send_appointment_confirmation_to_customer_on_booking_system_or_online: "إرسال تأكيد الموعد للمستؤجر بعد الحجز (النظام أو أونلاين)",
		send_online_appointment_confirmation_to_customer: "إرسال تأكيد لموعد الأونلاين للمستؤجر",
		send_system_appointment_confirmation_to_customer: "إرسال تأكيد لموعد النظام للمستؤجر",
		send_appointment_confirmation_for_bookings_from_online: "إرسال تأكيد الموعد للحجوزات من الأونلاين",
		send_appointment_confirmation_for_bookings_from_system: "إرسال تأكيد الموعد للحجوزات من النظام",
		send_salon_rating_to_customer_after_invoice_issue: "إرسال تقييم الصالون للمستؤجر بعد إصدار الفاتورة",
		whatsapp_bot_setting_edited_successfully: "تم تعديل إعدادات الواتس آب الآلي بنجاح",
		please_enter_a_number_which_has_whatsapp_service_activated_on_it: "الرجاء إدخال رقم مفعّل لخدمة الواتس آب",
		include_employees_assessment_link: "تضمين رابط تقييم الموظفين",
		this_option_is_not_allowed_when_automatic_whatsapp_is_connected: "هذا الخيار غير متاح في حال كان الواتس آب الآلي متصل",
		customer_not_exist: "المستأجر غير موجود",
		user_has_no_rights_to_the_system: "المستخدم غير حاصل على حقوق على النظام ",
		send_appointment_confirmation: "إرسال تأكيد الموعد",
		menu_qr: "رمز الاستجابة السريعة للقائمة",
		send_automatic_online_booking_confirmation_from_salonaty: "إرسال تأكيد حجز أونلاين تلقائي من عقار بوكس",
		this_option_is_not_allowed_when_automatic_whatsapp_is_connected_or_online_setting_is_disabled:
			"هذا الخيار غير متاح في حال كان الواتس آب الآلي متصل أو إعدادات الأونلاين معطلة",
		it_is_not_allowed_to_change_alias_name_after_selection: "* غير مسموح بتغيير الإسم المستعار بعد الإختيار",
		download_graphics_for_reception: "تنزيل النموذج الخاص بالاستقبال",
		basic: "أساسي",
		advanced_package: "متقدم",
		premium: "بريميوم",
		subscription_expired_at: "ينتهي الاشتراك في",
		expired_at: "تنتهي في",
		renew_subscription: "تجديد الاشتراك",
		allow_sell_products: "السماح ببيع المنتجات",
		this_number_does_not_have_active_whatsapp: "غير مفعّل لخدمة الواتس آب",
		this_number_have_active_whatsapp: "مفعّل لخدمة الواتس آب",
		customer_does_not_have_active_whatsapp: "المستأجر ليس لديه واتس آب فعّال",
		activate_customer: "تفعيل المستأجر",
		are_you_sure_you_want_to_activate_the_customer: "هل أنت متأكد أنك تريد تفعيل المستأجر؟",
		customer_activated_successfully: "تم تفعيل المستأجر بنجاح",
		not_allowed: "غير مسموح",
		check_whatsapp_number_in_process: "رقم الواتس آب قيد التحقق",
		whatsapp_number_checked_successfully: "تم التحقق من رقم الواتس آب بنجاح",
		verification_code: "رمز التحقق",
		please_enter_the_code_you_received_on_your_whatsapp: "من فضلك أدخل الرمز الذي تلقيته على الواتس آب الخاص بك",
		resend_code: "إعادة إرسال الرمز؟",
		the_code_you_entered_is_incorrect: "الرمز الذي أدخلته غير صحيح",
		customer_is_disabled_please_activate_him_first: "المستأجر معطّل، الرجاء تفعيله أولا",
		you_can_not_request_code_in_less_than_1_minute_please_try_again: "لا يمكنك طلب الرمز خلال أقل من دقيقة، الرجاء المحاولة مرة أخرى",
		added_at: "تمت الإضافة:",
		ads_campaigns: "الحملات الإعلانية",
		campaign_period: "فترة الحملة",
		customer_type: "نوع المستأجر",
		campaign_name: "اسم الحملة",
		the_code_has_been_expired_we_resend_another_one_to_you: "لقد انتهت صلاحية الرمز، تم إرسال رمز جديد إليك",
		send_otp_for_new_customers: "إرسال الOTP للعملاء الجدد",
		server_disconnected: "الخادم غير متصل",
		server_is_disconnected_please_try_again_later:
			"خادم الواتس آب غير متصل حاليا، الرجاء المحاولة لاحقا، يمكنك إستخدام الخيار اليدوي حتى يتم حل المشكلة من مزوّد الخدمة",
		this_customer_does_not_allow_whatsapp_contact: "هذا المستأجر لا يسمح بالتواصل على الواتس آب",
		send_otp_for_new_customers_from_salonaty: "إرسال الOTP للعملاء الجدد من عقار بوكس",
		print_daily_activities_for_employees: "طباعة النشاطات اليومية للموظفين",
		employee_daily_activities: "النشاطات اليومية للموظف",
		campaign_start: "بداية الحملة",
		campaign_end: "نهاية الحملة",
		customers_count_total: "عدد المستأجرين الكلي",
		add_campaign: "إضافة حملة",
		done_services: "الخدمات المنجزة",
		starts_with_letter: "يبدأ بحرف",
		operand: "المعامل",
		less_than_or_equal: "أقل من أو يساوي",
		less_than: "أقل من",
		equal: "يساوي",
		greater_than: "أكبر من",
		greater_than_or_equal: "أكبر من أو يساوي",
		starts_with: "يبدأ ب",
		contains: "يحتوي على",
		customers_count_within_campaign: "عدد المستأجرين ضمن الحملة",
		campaign_content: "محتوى الحملة",
		customers_count_within_campaign_should_be_more_than_zero: "عدد المستأجرين ضمن الحملة يجب أن يكون أكبر من 0",
		customers_total_count: "عدد المستأجرين الكلي",
		success: "نجح",
		failed: "فشل",
		sending: "قيد الإرسال",
		campaign_not_exist_or_not_allowed_to_edit: "الحملة غير موجودة أو غير مسموح بتعديلها",
		temporary_save: "حفظ مؤقت",
		temporary_saved: "محفوظ مؤقتا",
		save_draft: "حفظ مؤقت",
		can_not_change_the_status_of_this_campaign: "لا يمكن تغيير الحالة لهذه الحملة",
		status_changed_successfully: "تم تغيير الحالة بنجاح",
		start_sending: "إبدأ الإرسال",
		disable_campaign: "إلغاء الحملة",
		when_you_disable_campaign_you_can_not_activate_it_again: "عند إلغاء الحملة أنت لا تستطيع تفعيلها مجددا",
		report: "التقرير",
		ads_campaign_report: "تقرير الحملة",
		drop_file_here: "لتحميل الصور والملفات",
		campaign_ready: "الحملة جاهزة",
		you_can_not_edit_customer_list_after_save: "- لن تستطيع تعديل قائمة المستأجرين بعد الحفظ",
		campaign_added_successfull: "تم إضافة الحملة بنجاح",
		customer_selection: "اختيار المستأجرين",
		sent_date_and_time: "تاريخ ووقت الإرسال",
		campaign_content_edited_successfully: "تم تعديل محتوى الحملة بنجاح",
		google_review: "مراجعة جوجل",
		product_have_barcode: "المنتج لديه باركود",
		product_does_not_have_barcode: "المنتج ليس لديه باركود",
		expired_only: "تنتهي",
		only_images_videos_pdf_files_are_supported: "فقط الصور، الفيديو، الPDF مدعوم",
		file_size_should_be_less_5_mb: "حجم الملف يجب أن يكون أقل من 5 MB",
		please_complete_form_to_calculate_number_of_campaign_customers: "الرجاء إكمال الشروط لإحصاء عدد عملاء الحملة",
		start_now: "إبدا الآن",
		max_size_5_mb: "الحد الأقصى 5 MB",
		pending: "قيد الإنتظار",
		show_customers: "إظهار المستأجرين",
		hide_customers: "إخفاء المستأجرين",
		adding_date: "تاريخ الإضافة",
		resend_faild: "إعادة إرسال الفشل",
		there_are_no_failed_customers_for_this_campaign: "لا يوجد فشل في هذه الحملة",
		resend_failed_started_successfully: "تم بدء إعادة إرسال الفشل",
		renew_campaign: "تجديد الحملة",
		campaign_renewed_successfully: "تم تجديد الحملة بنجاح",
		send_test: "أرسل تجربة",
		campaign_test_send_successfully: "إرسال التجربة تم بنجاح",
		messages_in_the_campaign_are_sent_with_a_time_difference_of_30_to_90_seconds_between_each_client_and_another:
			"- يتم ارسال الرسائل في الحملة بفرق توقيت من 30 - 90 ثانية بين كل مستؤجر واخر",
		the_sending_stops_for_approximately_5_to_15_minutes_after_sending_100_messages: "- يتوقف الارسال لمدة 5 - 15 دقيقة تقريبا بعد ارسال 100 رسالة",
		messages_will_be_sent_between_9am_to_11pm_only: "- سيتم إرسال الرسائل بين 9:00 صباحا حتى 11:00 مساءا فقط",
		serial: "التسلسل",
		full_name: "الاسم كامل",
		first_name: "الإسم الأول",
		allow_whatsapp_contact: "السماح بالتواصل عالواتس آب",
		we_serve_only_ladies_customers: "نحن نخدم فقط عملاء نساء",
		we_serve_only_mens_customers: "نحن نخدم فقط عملاء رجال",
		we_serve_only_kids_customers: "نحن نخدم فقط عملاء أطفال",
		campaign_starts_sending_after_approximately_10_minutes_of_starting: "- تبدا الحملة تقريبا بعد 10 دقائق من التشغيل",
		salonaty_is_not_responsible_of_banning_the_number_from_whatsapp_company_due_to_misuse:
			"- عقار بوكس غير مسؤولة عن حظر الرقم من شركة واتس آب بسبب سوء الاستخدام",
		remove_package: "حذف الباقة",
		are_you_sure_you_want_to_remove_the_package: "هل أنت متأكد أنك تريد حذف الباقة؟",
		please_connect_your_whatsapp_automatic_whatsapp_is_disconnected: "الرجاء الاتصال بالواتس آب الخاص بك، الواتس آب الآلي غير متصل",
		this_is_only_ladies_salon: "هذا صالون نسائي فقط",
		this_is_only_men_salon: "هذا صالون رجالي فقط",
		this_is_only_kids_salon: "هذا صالون أطفال فقط",
		whatsapp_number_has_been_disconnected_successfully_from_salonaty: "تم فصل الرقم بنجاح من عقار بوكس",
		birth_month: "شهر الميلاد",
		january: "شهر 1",
		february: "شهر 2",
		march: "شهر 3",
		april: "شهر 4",
		may: "شهر 5",
		june: "شهر 6",
		july: "شهر 7",
		august: "شهر 8",
		september: "شهر 9",
		october: "شهر 10",
		november: "شهر 11",
		december: "شهر 12",
		frequent_has_more_than_1_invoice_and_last_invoice_less_than_60_days: "المتكرر: لديه أكثر من فاتورة و آخر فاتورة له أقل من 60 يوم",
		missed_has_no_invoices_or_last_invoice_more_than_60_days: "المفقود: ليس لديه أي فاتورة أو آخر فاتورة له أكثر من 60 يوم",
		new_has_1_invoice_and_last_invoice_less_than_60_days: "الجديد: لديه فاتورة واحدة و آخر فاتورة له أقل من 60 يوم",
		new_the_others_are_new_customers: "الجديد: ما تبقى هم عملاء جدد",
		current_commission: "العمولة الحالية",
		employees_commissions_report: "تقرير عمولات الموظفين",
		activities_report_summary: "ملخص تقرير الحركات",
		activities_summary: "ملخص الحركات",
		reception_and_stuff_treatment: "الاستقبال وتعامل الموظفين",
		cleanliness_and_place_arrangment: "النظافة وترتيب المكان",
		prices: "الأسعار",
		quality_of_provided_services: "جودة الخدمات المقدمة",
		complains_report: "تقرير الشكاوى",
		complaints_report: "تقرير الشكاوى",
		complaints: "الشكاوى",
		surveys_report: "تقرير الاستبيانات",
		remove_connection: "حذف الاتصال",
		are_you_sure_you_want_to_remove_whatsapp_from_your_account: "هل أنت متأكد أنك تريد حذف اتصال الواتس آب في حسابك؟",
		pdf: "PDF",
		edit_name_request: "طلب تعديل الاسم",
		edit_name_request_sent_successfully: "تم إرسال طلب تعديل الاسم بنجاح",
		this_user_is_the_owner_of_this_business_please_contact_with_the_service_provider:
			"هذا المستخدم هو المالك لهذا الحساب\nالرجاء التواصل مع مزود الخدمة",
		activities_report_summary_for_services: "ملخص تقرير الحركات للخدمات",
		activities_report_summary_for_products: "ملخص تقرير الحركات للمنتجات",
		you_can_request_name_edit_later_from_active_products: "* يمكنك طلب تعديل للاسم لاحقا من المنتجات الفعالة",
		edit_appointments: "تعديل المواعيد",
		all_appointments_in_this_day_will_be_cancelled: "سيتم إلغاء جميع المواعيد في هذا اليوم",
		this_customer_has_unpaid_invoices: "هذا المستأجر لديه فواتير غير مدفوعة",
		online_review: "تقييم",
		custom_working_hours_removed_successfully: "تم حذف وقت العمل المخصص بنجاح",
		remove_custom_working_hours: "حذف وقت العمل المخصص",
		remove_custom_working_hours_group: "حذف مجموعة وقت العمل المخصص",
		are_you_sure_you_want_to_remove_custom_working_hours: "هل أنت متأكد أنك تريد حذف وقت العمل المخصص؟",
		are_you_sure_you_want_to_remove_custom_working_hours_group: "هل أنت متأكد أنك تريد حذف مجموعة وقت العمل المخصص؟",
		note: "الملاحظة",
		print_tax_invoice: "طباعة فاتورة ضريبية",
		internal_use_for_products: "الاستخدام الداخلي للمنتجات",
		sold_products: "المنتجات المباعة",
		print_a4: "طباعة A4",
		services_and_products: "الخدمات والمنتجات",
		if_you_select_then_the_discount_will_be_applied_only_on_the_selected_leave_it_empty_if_you_want_the_discount_to_be_applied_at_all:
			"إذا قمت بالاختيار فسوف يتم تطبيق الخصم فقط على المختار، أتركها فارغة إذا كنت تريد الخصم أن يطبق على الكل",
		services_over_products: "الخدمات/المنتجات",
		account_has_been_disabled: "تم تعطيل الدخول لحسابك",
		account_has_been_expired: "تم إنتهاء صلاحية الدخول لحسابك، الرجاء التواصل مع المبيعات",
		add_edit_note: "إضافة/تعديل ملاحظة",
		note_added_edited_successfully: "تم إضافة/تعديل الملاحظة بنجاح",
		all_services_and_products: "جميع الخدمات والمنتجات",
		all_products: "جميع المنتجات",
		selected_services_and_products: "خدمات ومنتجات مختارة",
		selected_services: "خدمات مختارة",
		selected_products: "منتجات مختارة",
		password_reset_email_sent_successfully_to: "تم إرسال بريد إعادة تعيين كلمة المرور بنجاح إلى",
		reset_password: "إعادة تعيين كلمة المرور",
		surveys: "الاستبيانات",
		notification_mark_as_read: "تمت القراءة",
		notifications_marked_as_read_successfully: "تمت قراءة الإشعارات بنجاح",
		alert: "التنبيه",
		rate: "التقييم",
		achievement: "الإنجاز",
		invoice_not_exist_or_not_rated: "الفاتورة غير موجودة أو غير مقيّمة",
		product_is_disabled: "المنتج معطّل",
		check_in_checkout_report: "تقرير الحضور - الدفع",
		check_in_time: "وقت الحضور",
		checkout_time: "وقت الدفع",
		are_you_sure_you_want_to_cancel_the_service: "هل أنت متأكد أنك تريد إلغاء الخدمة؟",
		cancel_service: "إلغاء الخدمة",
		service_cancelled_successfully: "تم إلغاء الخدمة بنجاح",
		accounts_information: "معلومات الحسابات",
		disabled_accounts: "الحسابات المعطلة",
		expired_accounts: "الحسابات المنتهية",
		birthday_congratulations: "تهاني يوم الميلاد",
		send_birthday_congratulations: "إرسال تهاني يوم الميلاد",
		whatsapp_packages: "باقات الواتس آب",
		whatsapp_disconnected_packages: "باقات الواتس آب المفصولة",
		whatsapp_disconnected: "الواتس آب المفصول",
		system_option: "خيارات النظام",
		global_aliases: "الاسماء المستعارة العامة",
		accounts: "الحسابات",
		all_active_employees: "جميع الموظفين الفعالين",
		active_employees_with_access: "الموظفين الفعالين مع دخول",
		inactive_global_artists: "الموظفين غير الفعالين",
		current_connections: "الاتصالات الحالية",
		requested_accounts: "الحسابات المطلوبة",
		waiting_for_verify: "بانتظار التوثيق",
		rejected_accounts: "الحسابات المرفوضة",
		all_accounts_requests: "كل طلبات الحسابات",
		added_products: "المنتجات المضافة",
		edit_products_requests: "طلبات تعديل المنتجات",
		edit_products: "تعديل المنتجات",
		offers_images: "صور العروض",
		instance: "",
		mail_tracker: "متتبع البريد",
		qbot_tracker: "متتبع كيو بوت",
		failed_jobs: "العمليات الفاشلة",
		failed_login_attempts: "عمليات الدخول الخاطئة",
		telescope: "تلسكوب",
		js_errors: "أخطاء الجافا سكربت",
		backup_database: "نسخة احتياطية للداتا بيس",
		development_suggestions: "اقتراحات التطوير",
		requested: "المطلوب",
		trackers: "المتتبعات",
		technical: "التقني",
		system_data: "بيانات النظام",
		valid_till: "فعّال حتى",
		last_login_at: "آخر دخول في",
		online_url: "رابط الأونلاين",
		google_index: "موشر جوجل",
		full_backup: "نسخة احتياطية كاملة",
		number_of_units: "عدد الوحدات",
		number_of_users: "عدد المستخدمين",
		login_as_owner: "دخول كالمالك",
		change_owner: "تغيير المالك",
		full_backup_to_owner: "نسخة احتياطية كاملة للمالك",
		start_chat_with_owner: "بدء محادثة مع المالك",
		change_google_index_to_yes: "تغيير مؤشر جوجل ل نعم",
		change_google_index_to_no: "تغيير مؤشر جوجل ل لا",
		wt_setting: "إعدادات الواتس آب",
		change_alias: "تغيير الاسم المستعار",
		change_to_trial: "تغيير ل تجريبي",
		change_to_premium: "تغيير ل بريميوم",
		package: "الباقة",
		automatic_rating: "تقييم تلقائي",
		send_otp: "إرسال OTP",
		automatic_booking_confirmation: "تأكيد تلقائي للحجز",
		has_wt_package: "لديه باقة واتس آب",
		upload_customers: "رفع عملاء",
		truncate_failed_jobs: "إعادة ضبط العمليات الفاشلة",
		are_you_sure_you_want_to_truncate_failed_jobs: "هل أنت متأكد أنك تريد إعادة ضبط العمليات الفاشلة",
		failed_jobs_truncated_successfully: "تم إعادة ضبط العمليات الفاشلة بنجاح",
		attendence_system: "نظام الحضور",
		geolocation_is_not_supported_by_this_browser: "الموقع الجغرافي غير مدعوم من المتصفح",
		user_denied_the_request_for_geolocation: "المستخدم رفض الوصول للموقع الجغرافي",
		location_information_is_unavailable: "معلومات الموقع الجغرافي غير متوفرة",
		the_request_to_get_user_location_timed_out: "تم إنتهاء صلاحية طلب الموقع الجغرافي من المستخدم",
		getting_location: "يتم إحضار الموقع الجغرافي ...",
		check_out: "خروج",
		operation_done_successfully: "تمت العملية بنجاح",
		location: "الموقع",
		get_geolocation: "إحضار الموقع الجغرافي",
		employees_reports: "تقارير الموظفين",
		attendence_report: "تقرير الحضور",
		number_of_working_days: "عدد أيام العمل",
		number_of_working_hours: "عدد ساعات العمل",
		number_of_hours_at_appointments: "عدد الساعات في المواعيد",
		employee_attendence_report: "تقرير حضور الموظف",
		geolocation_updated_successfully: "تم تحديث الموقع الجغرافي بنجاح",
		update_geolocation: "تحديث الموقع الجغرافي",
		the_current_location_will_be_took_as_the_geolocation: "سيتم أخذ الموقع الحالي كالموقع الجغرافي",
		geolocation_is_not_set_before_you_need_to_set_it_first: "لم يتم وضع الموقع الجغرافي مسبقا، أنت بحاجة لوضعه أولا",
		the_distance_between_you_and_check_in_location_is_more_than_50_meters: "المسافة بينك وبين مكان العمل أكثر من 50 متر",
		please_give_permissions_to_browser_to_access_your_location: "الرجاء منح صلاحيات للمتصفح للوصول لموقعك",
		current_employee: "الموظف الحالي",
		all_business: "كل النظام",
		block_ip: "حجب الآي بي",
		are_you_sure_you_want_to_block_the_ip: "هل أنت متأكد أنك تريد حجب الآي بي؟",
		ip_already_blocked: "الآي بي محجوب بالفعل",
		ip_blocked_successfully: "تم حجب الآي بي بنجاح",
		blocked: "محجوب",
		unblock_ip: "رفع الحجب عن الآي بي",
		are_you_sure_you_want_to_unblock_the_ip: "هل أنت متأكد أنك تريد رفع الحجب عن الآي بي؟",
		ip_already_unblocked: "الآي بي غير محجوب بالفعل",
		ip_unblocked_successfully: "تم رفع الحجب عن الآي بي بنجاح",
		ip_is_blocked:
			"تم حجب رقم بروتوكول الانترنت الخاص بكم  عن الخدمة لمحاولتكم المتكررة للدخول بشكل خاطئ.\nوفي حال ثبوت محاولات اختراق من طرفكم،، فاننا سنقوم بابلاغ الجهات المختصة في الدولة ،،، لاتخاذ المقتضى القانوني بحقكم.\nلدينا جميع البيانات الخاصة بموقعكم الجغرافي والجهاز المستخدم في محاولات الدخول الخاطئة",
		add_as_home_services: "إضافة كخدمات منزلية",
		blocked_ips: "الآي بي المحجوبات",
		forecast: "التوقعات",
		commulative_attendence_report: "تقرير الحضور التجميعي",
		detailed_attendence_report: "تقرير الحضور التفصيلي",
		forecast_daily_sales: "المبيعات اليومية المتوقعة",
		payments_status: "حالة الدفعات",
		next_month: "الشهر القادم",
		tomorrow: "غدا",
		next_quarter: "الربع القادم",
		next_three_months: "الثلاث أشهر القادمة",
		next_year: "السنة القادمة",
		completion_period: "فترة الإنجاز",
		add_invoice: "إضافة فاتورة",
		edit_invoice: "تعديل فاتورة",
		form: "النموذج",
		account_invoices: "فواتير الحساب",
		request_payment: "طلب دفعة",
		account_invoice: "فاتورة حساب",
		owner_email_not_exist: "البريد الإلكتروني للمالك غير موجود",
		owner_mobile_not_exist: "موبايل المالك غير موجود",
		whatsapp_service_is_not_activated_on_owner_mobile: "خدمة الواتس آب غير مفعلة على موبايل المالك",
		whatsapp_service_is_not_activated_on_bill_to_mobile: "خدمة الواتس آب غير مفعلة على موبايل الفاتورة إلى",
		bill_to_email_not_exist: "البريد الإلكتروني ل 'فاتورة إلى' غير موجود",
		credit_card_payment_type_not_exist: "طريقة الدفع 'بطاقة ائتمانية' غير موجودة",
		change_to_paid: "تحويل إلى مدفوع",
		are_you_sure_you_want_to_change_the_payment_to_paid: "هل أنت متأكد أنك تريد تحويل الدفعة إلى مدفوعة؟",
		invoice_is_cancelled: "الفاتورة ملغية",
		total_payments_of_invoice_will_be_more_than_invoice_total: "مجموع المبيعات على الفاتورة ستكون أعلى من مجموع الفاتورة",
		payment_status_not_allowed_to_edit: "حالة الدفعة غير مسموحة للتعديل",
		countries: "الدول",
		cities: "المدن",
		marketting: "التسويق",
		active_accounts: "الحسابات الفعالة",
		google_index_changed_successfully: "تم تغيير مؤشر جوجل بنجاح",
		owner_changed_successfully: "تم تغيير المالك بنجاح",
		whatsapp_setting_changed_successfully: "تم تغيير إعدادات الواتس آب بنجاح",
		alias_name_changed_successfully: "تم تغيير الاسم المستعار بنجاح",
		add_account: "إضافة حساب",
		edit_account: "تعديل حساب",
		approve_account: "الموافقة على حساب",
		owner_mobile: "موبايل المالك",
		account_edited_successfully: "تم تعديل الحساب بنجاح",
		account_package_changed_successfully: "تم تعديل باقة الحساب بنجاح",
		account_status_changed_successfully: "تم تعديل حالة الحساب بنجاح",
		upload_customers_excel: "رفع إكسل بالمستأجرين",
		are_you_sure_you_want_to_upload_excel: "هل أنت متأكد أنك تريد رفع الإكسل",
		customers_invalid_data: "بيانات المستأجرين الخاطئة",
		row_number: "رقم الصف",
		errors: "الأخطاء",
		powered_by_ucitco: "أحد منتجات UCITCO",
		payment_done_successfully: "تم الدفع بنجاح",
		payment_failed: "فشل الدفع",
		abu_dhabi: "أبو ظبي",
		arab_emirates: "الإمارات العربية",
		call_us: "التواصل",
		instance_paused_successfully: "تم الإيقاف مؤقتا بنجاح",
		instance_resumed_successfully: "تم الإستئناف بنجاح",
		instance_removed_successfully: "تم الحذف بنجاح",
		main_instance: "النموذج الرئيسي",
		united_arab_emirates: "الإمارات العربية المتحدة",
		abu_dhabi_united_arab_emirates: "أبو ظبي - الإمارات العربية المتحدة",
		i_agree_to_try_the_software_for_7_days: "اوافق على تجربة البرنامج لمدة ٧ ايام واعلم بعدم امكانية الارجاع او الالغاء بعد الاشتراك",
		key: "المفتاح",
		secret: "السر",
		payment_gateway_setting: "إعدادات بوابة الدفع",
		company: "الشركة",
		edit_payment_gateway_setting: "تعديل إعدادات بوابة الدفع",
		payment_gateway_setting_updated_successfully: "تم تعديل إعدادات بوابة الدفع بنجاح",
		payment_gateway_setting_not_exist: "إعدادات بوابة الدفع غير موجودة",
		ultra_cloud: "الترا كلاود",
		payment_gateway_id: "معرف بوابة الدفع",
		english_agreement_details: "تفاصيل الاتفاق بالإنجليزية",
		arabic_agreement_details: "تفاصيل الاتفاق بالعربية",
		invoice_setting_edited_successfully: "تم تعديل إعدادات الفاتورة بنجاح",
		bill_from_name: "فوترة من إسم",
		email_setting: "إعدادات البريد الإلكتروني",
		host: "المستضيف",
		mailer: "الميلر",
		port: "المنفذ",
		encryption: "التشفير",
		email_setting_edited_successfully: "تم تعديل إعدادات البريد الإلكتروني بنجاح",
		email_subject: "عنوان البريد الإلكتروني",
		load_more: "حمّل المزيد",
		historical_data_for_customers_types: "البيانات التاريخية لأنواع المستأجرين",
		whatsapp_notification_setting: "إعدادات إشعارات الواتس آب",
		email_notification_setting: "إعدادات إشعارات البريد الإلكتروني",
		email_setting_for_sending: "إعدادات البريد الإلكتروني للإرسال",
		customer_to_business: "من المستأجر للعمل",
		business_to_platform: "من العمل للمنصة",
		platform_to_business: "من المنصة للعمل",
		as: "ك",
		logo_uploaded_successfully: "تم تحميل الشعار بنجاح",
		logo_removed_successfully: "تم حذف الشعار بنجاح",
		activate_development: "تفعيل الإختبار",
		stars: "نجوم",
		contracts: "العقود",
		add_contract: "إضافة عقد",
		edit_contract: "تعديل عقد",
		contract_to: "عقد إلى",
		platform_marketting: "تسويق المنصة",
		should_contain_only_english_letters_arabic_letters_numbers_and_dash: "يجب أن يحتوي فقط على أحرف إنجليزية، أحرف عربية، أرقام و -",
		contract_setting: "إعدادات العقد",
		contract_not_exist: "العقد غير موجود",
		merchant_service_agreement: "عقد خدمة لتاجر",
		undo: "تراجع",
		this_operation_can_not_be_undone_are_you_sure_that_the_signature_is_correct: "لا يمكن التراجع عن هذه العملية، هل أنت متأكد من صحة التوقيع؟",
		please_draw_the_signature_first: "الرجاء رسم التوقيع أولا",
		submit_signature: "إرسال التوقيع",
		signature_is_not_allowed_for_this_contract: "التوقيع غير مسموح لهذا العقد",
		signature_submitted_successfully: "تم إرسال التوقيع بنجاح",
		to_email_not_exist: "البريد الإلكتروني إلى غير موجود",
		account_logo: "شعار حساب",
		employee_image: "صورة موظف",
		online_background: "خلفية الأونلاين",
		offer_image: "صورة عرض",
		images: "الصور",
		trial_accounts: "الحسابات التجريبية",
		failed_login: "الدخول الخاطئ",
		trial_requests: "طلبات التجربة",
		signed: "موقعة",
		change_owner_old: "تغيير المالك (القديم)",
		change_owner_new: "تغيير المالك (الجديد)",
		owner_confirmed: "تأكيد المالك",
		contract_signed: "توقيع العقد",
		invoice_issued: "الفاتورة صدرت",
		invoice_paid: "الفاتورة مدفوعة",
		one_day: "يوم واحد",
		range: "فترة",
		from_date: "من تاريخ",
		to_date: "إلى تاريخ",
		some_dates_in_period_already_have_records_please_edit_or_delete_them_first: "بعض التواريخ في الفترة لديها سجلات، الرجاء تعديلها أو حذفها اولا",
		the_period_should_be_a_maximum_30_days: "الفترة يجب أن تكون بحد أقصى 30 يوم",
		platform_setting: "إعدادات المنصة",
		merchant_extras: "اضافات التجار",
		payments_requests: "طلبات الدفعات",
		website_setting: "اعدادات الموقع",
		sm_setting: "اعدادات السوشال ميديا",
		pg_setting: "اعدادات بوابة الدفع",
		payments_methods: "طرق الدفع",
		platform_updates: "تحديثات المنصة",
		mark_all_as_checked: "تم التدقيق للجميع",
		created_at: "انشات في",
		account_english_name: "اسم الحساب بالانجليزي",
		client_ip: "رقم المعرف",
		device_type: "نوع الجهار",
		browser_type: "نوع المتصفح",
		user_agent: "خادم المستخدم",
		approve: "موافقة",
		reject: "رفض",
		start_Whatsapp: "ابدا واتساب",
		reset_password_by_email: "ارسال كلمة المرور برسالة",
		suspend: "ايقاف",
		account: "الحساب",
		old_english_name: "الاسم القديم بالانجليزي",
		old_arabic_name: "الاسم القديم بالعربي",
		new_english_name: "الاسم الجديد بالانجليزي",
		new_Arabic_name: "الاسم الجديد بالعربي",
		active_whatsapp_packages: "باقات الواتساب الفعالة للحسابات",
		provided_services: "الخدمات المقدمة",
		services_groups: "مجموعات الخدمات",
		mark_as_checked: "تم التدقيق",
		whatsapp_status: "حالة الواتساب",
		merchant_with_wa_package: "التجار مع باقات واتساب",
		validity: "الصلاحية",
		total_messages: "مجموع المسجات",
		merchants_information: "معلومات التجار",
		rejected_merchants: "التجار المرفوضة",
		all_merchants_requests: "كل طلبات التجار",
		subject: "الموضوع",
		from_name: "من حساب",
		updated_at: "تم التحديث",
		delete_from_yesturday: "امسح من الامس",
		merchants: "التجار",
		merchants_employees: "موظفين التجار",
		active_merchants: "التجار الفعالين",
		trial_merchants: "التجار التجربة",
		disabled_merchants: "التجار المعطلين",
		expired_merchants: "التجار المنتهية",
		check_entries: "مراجعة المدخلات",
		inactive_global_employees: "الموظفين الجلوبال غير الفعالين ",
		added_employees: "الموظفين المضافين",
		active_global_employees: "موظفين الجلوبال الفعالين",
		marketing_merchants_requests: "التسويق للتجار",
		marketing_active_merchants: "التسويق للتجار الفعالين",
		customers_merchants: "زبائن التجار",
		employees_merchants: "موظفين التجار",
		customers_active_wa: "الزبائن مع واتس فعال",
		customers_inactive_wa: "الزبائن بدون واتساب",
		added_customers: "الزبائن المضافين",
		system_connections: "الاتصالات السابقة",
		other_tools: "أدوات اخرى",
		edited_products: "المنتجات المعدلة",
		added_images: "الصور المضافة",
		added_groups: "المجموعات المضافة",
		added_services: "الخدمات المضافة",
		english_subject: "الموضوع بالانجليزي",
		arabic_subject: "الموضوع بالعربي",
		english_description: "التفاصيل بالانجليزي",
		arabic_description: "التفاصيل بالعربي",
		english_question: "السؤال بالانجليزي",
		arabic_question: "السؤال بالعربي",
		english_answer: "الاجابة بالانجليزي",
		arabic_answer: "الاجابة بالعربي",
		suggested_groups: "المجموعات المقترحة",
		suggested_services: "الخدمات المقترحة",
		products_listed: "المنتجات المتوفرة",
		numbers_of_days: "عدد الايام",
		number_of_sms: "عدد الرسائل",
		add_whatsapp_package: "اضافة باقة واتساب",
		currency: "العملة",
		contry: "الدولة",
		home_page: "الصفحة الرئيسية",
		source: "المصدر",
		user: "المستخدم",
		route: "الطريق",
		error: "الخطا",
		truncate: "افراغ",
		merchants_marketing: "التسويق للتجار",
		owener_name: "اسم المالك",
		owener_mobile: "هاتف المالك",
		allow_customer_to_present: "السماح للزبون للعرض",
		checked: "تم الفحص",
		not_checked: "لم تفحص",
		suggested_products: "المنتجات المقترحة",
		countries_list: "قائمة الدول",
		cities_list: "قائمة المدن",
		addon_services: "الخدمات المضافة",
		numbers_of_sms: "عدد الرسائل",
		varient: "التغيير",
		english_title: "العنوان بالانجليزي",
		arabic_title: "العنوان بالعربي",
		whatsapp_notifications: "تنبيهات الواتساب",
		customer_appointment_confirmation: "تاكيد الموعد للزبون",
		rate_us_for_issued_invoice: "التقييم للفواتير المصدرة",
		gift_card_to_purchaser: "بطاقة الهدية للمشتري",
		gift_card_to_beneficiary: "بطاقة الهدية للمستفيد",
		whatsapp_marketting: "التسويق بالواتساب",
		account_balance_reminder: "التذكير برصيد الحساب",
		customer_online_booking_otp: "OTP المستأجر",
		offer_remaining_services: "الخدمات المتبقية من العروض",
		rate_us_for_service_from_offer: "تقييم الخدمات المنجزة",
		receivable_reminder: "تذكير بالدين",
		campaing_test: "تجربة الحملة",
		campain_message: "رسالة الحملة",
		merchant_to_customer: "التاجر الى المستأجر",
		merchant_to_employee: "التاجر الى الموظف",
		platform_to_merchant: "المنصة الى التاجر",
		platform_to_customer: "المنصة الى المستأجر",
		account_contract: "عقد الحساب",
		account_payment_link: "رابط الدفع للحساب",
		marketing_requested_merchant: "التسويق لطلبات التجار",
		marketing_active_merchant: "التسويق للتجار الفعالة",
		invoice_rating_question: "اسئلة تقييم الفاتورة",
		invoice_rating: "تقييم الفاتورة",
		salon_alias: "الاسم المستعار",
		appointments_confirmation: "تاكيد الموعد",
		customer_web_invoice: "فاتورة المستأجر الالكترونية",
		customer_online_booking: "حجز الاونلاين للمستؤجر",
		create_account: "انشاء حساب",
		reject_account: "رفض انشاء حساب",
		account_online_booking: "حجز الاونلاين للتاجر",
		invoice_to_owner: "فاتورة المالك",
		free_gift_card_created: "انشاء بطاقة هدية مجانية",
		daily_report: "التقارير اليومية",
		account_backup: "التقارير الاحتياطية",
		send_stocks_notifications_to_management: "ارسال تنبيه المخزون للادارة",
		platform: "المنصة",
		customer_to_merchant: "المستأجر الى التاجر",
		merchant_to_platform: "التاجر الى المنصة",
		email_notifications: "تنبيهات البريد الالكتروني",
		customer_complain: "شكاوى المستأجرين",
		payment_request: "طلب دفعة",
		employee_access: "صلاحية دخول الموظف",
		send_employee_appointments: "ارسال مواعيد الموظف",
		employee_done_services: "الخدمات المنجزة للموظف",
		employee_order_services: "الخدمات المطلوبة للموظف",
		manager_email_failed_login_attempt: "المحاولات الفاشلة للدخول للمانجر",
		show_price: "إظهار السعر",
		hide_price: "إخفاء السعر",
		data_updated_successfully: "تم تحديث البيانات بنجاح",
		show_on_website: "إظهار على الموقع الإلكتروني",
		hide_from_website: "إخفاء من الموقع الإلكتروني",
		secondary_whatsapp_bot: "الواتس آب الثانوي",
		platform_to_owner: "المنصة إلى المالك",
		manager_failed_login: "تسجيل دخول خاطئ للإدارة",
		delete_role: "حذف الصلاحيات",
		are_you_sure_you_want_to_delete_the_role: "هل أنت متأكد أنك تريد حذف الصلاحية؟",
		please_delete_this_role_from_employees_before_delete_it: "الرجاء حذف هذه الصلاحية من الموظفين قبل حذفها",
		role_deleted_successfully: "تم حذف الصلاحيات بنجاح",
		seo: "تحسين محرّك البحث",
		google_analytics: "تحليلات جوجل",
		checkout_url: "رابط الدفع",
		get_checkout_url: "رابط الحصول على الدفع",
		payment_link_url: "رابط الدفع",
		clear: "تفريغ",
		sell_package_online: "بيع العروض أونلاين",
		appointment_confirmed_successfully: "تم تأكيد الموعد بنجاح",
		send_appointments_reminder: "إرسال تذكير بالمواعيد",
		send_birthday_wishes: "أرسل تمنيات عيد ميلاد",
		send_rebook_for_no_show: "إرسال إعادة حجز لعدم الحضور",
		send_reminder_for_appointment_on_same_day: "إرسال تذكير للموعد في نفس اليوم",
		add_block_time: "إضافة وقت حظر",
		block_times: "أوقات الحظر",
		week: "أسبوع",
		online_whatsapp: "واتس آب الأونلاين",
		cancel_group: "حذف المجموعة",
		cancel_record: "حذف السجل",
		are_you_sure_you_want_to_cancel_the_record: "هل أنت متأكد أنك تريد حذف السجل؟",
		are_you_sure_you_want_to_cancel_the_group: "هل أنت متأكد أنك تريد حذف المجموعة؟",
		point: "نقطة",
		sent_at: "أرسلت في",
		number_of_messages: "عداد الرسائل",
		allow_payment_during_online_booking: "الدفع الإلكتروني أثناء حجز الأونلاين",
		download_upload_customers_form: "تنزيل نموذج رفع المستأجرين",
		purchased_package: "البكجات المشتراه",
		cancellation_reason: "سبب الإلغاء",
		invoice_return: "إرجاع فاتورة",
		online_appointment: "موعد أونلاين",
		buy_gift_card: "شراء بطاقة هدية",
		buy: "شراء",
		buy_offer: "شراء عرض",
		next: "التالي",
		buyer: "المشتري",
		when_cancel_invoice_the_products_will_be_returned_to_the_stock: "* سيتم إرجاع المنتجات إلى المخزون عند إلغاء الفاتورة",
		your_payment_has_been_paid_successfully: "تم الدفع بنجاح",
		transaction_number: "رقم العملية",
		transaction_date_and_time: "تاريخ ووقت العملية",
		payment_operation_failed: "فشل في عملية الدفع",
		retry: "إعادة المحاولة",
		selected_products_will_be_returned_to_the_stock: "* سيتم إرجاع المنتجات المختارة إلى المخزون",
		remove_group: "حذف مجموعة",
		term_and_condition_added_successfully: "تم إضافة الشروط والأحكام بنجاح",
		term_and_condition_edited_successfully: "تم تعديل الشروط والأحكام بنجاح",
		cc_email: "البريد الإلكتروني CC",
		payment_gateway_payments: "دفعات بوابة الدفع",
		checkout_id: "معرف العملية",
		reference: "المرجع",
		sell_gift_card_online: "بيع بطاقة هدية أونلاين",
		expense_should_have_at_least_one_item: "يجب أن يحتوي المصروف على عنصر واحد على الأقل",
		page_added_successfully: "تم إضافة الصفحة بنجاح",
		page_edited_successfully: "تم تعديل الصفحة بنجاح",
		page_cloned_successfully: "تم نسخ الصفحة بنجاح",
		page_deleted_successfully: "تم حذف الصفحة بنجاح",
		page_google_indexed_successfully: "تم تضمين الصفحة لدى جوجل بنجاح",
		editing_done_successfully: "تم التعديل بنجاح",
		no_payment_gateway: "لا يوجد بوابة دفع",
		seo_report: "تقرير SEO",
		seo_setting: "اعدادات SEO",
		linked_page: "الصفحات المربوطة",
		arabic_keywords: "الكلمات المفتاحية AR",
		english_keywords: "الكلمات المفتاحية EN",
		google_indexed: "تم تسليم جوجل",
		clone: "استنساخ",
		seo_page_details: "تفاصيل صفحات SEO",
		english_script: "العوامل بالانجليزية",
		arabic_script: "العوامل بالعربية",
		script: "السكربت",
		offered_on_a_monthly_fee_by_third_party_companies: "تقدم عبر اشتراكات شهرية عبر شركات غير تابعة ",
		main_instance_disconnected: "الرقم الرئيسي مفصول",
		account_instance_to_platform_disconnected: "رقم الانستانس للمنصة مفصول",
		whatsapp_package_warning: "تحذير باقات الواتساب",
		secondary_instance_disconnected: "الرقم الاحتياطي مفصول",
		account_instance_to_owner_disconnected: "ارسال المالك بانقطاع الانستانس",
		run_check_whatsapp: "تشييك ارقام الواتساب",
		generate_server_js: "توليد server.js",
		server_js: "server.js",
		seo_records: "سجلات SEO",
		seo_record: "سجل SEO",
		delete_record: "حذف سجل",
		are_You_sure_you_want_to_delete_the_record: "هل أنت متأكد أنك تريد حذف السجل؟",
		youtube: "يوتيوب",
		used_when_total_tax_invoice_greater_than_services_and_products_customers_get_return_cases:
			"تستخدم عندما تكون مجموع الفاتورة الضريبية المصدرة من النظام اكبر من قيمة الخدمات او المنتجات التي حصل عليها المستأجر (حالات الارجاع)",
		create_debit_note: "إنشاء إشعار مدين",
		used_when_total_tax_invoice_less_than_services_and_products_customers_get:
			"تستخدم عندما تكون مجموع الفاتورة الضريبية المصدرة من النظام،،، اقل من قيمة الخدمات او المنتجات التي حصل عليها المستأجر.",
		please_issue_new_invoice_to_include_extra_amount_required:
			"الرجاء اصدار فاتورة ضريبية جديدة تحتوي على المبلغ الاضافي التي يحتاج المستأجر الى دفعها.",
		sorry_we_cant_proceed_with_your_booking_kindly_contact_salon: "نعتذر لايمكن اتمام الحجز\nالرجاء التواصل مع الصالون",
		pay_later: "الدفع لاحقا",
		expected_payment_date: "تاريخ الدفع المتوقع",
		credit_note_will_be_issued_without_adding_to_expenses: "سيتم إنشاء إشعار دائن بدون الإضافة إلى المصاريف",
		expected_expenses: "المصاريف المتوقعة",
		add_expected_expense: "إضافة مصروف متوقع",
		edit_expected_expense: "تعديل مصروف متوقع",
		expected_expense: "مصروف متوقع",
		move_to_expenses: "نقل إلى المصروفات",
		expected_expense_move_to_expenses_succuessfully: "تم نقل المصروف المتوقع إلى المصروفات بنجاح",
		issue_invoice_penalty: "اصدار فاتورة (غرامة)",
		new_invoice_of_amount_will_be_issued: "سيتم إصدار فاتورة بقيمة ({amount})",
		current_customers: "المستأجرين الحاليين",
		new_price: "السعر الجديد",
		old: "القديم",
		the_additional_amount_of_the_item_includes_tax: "المبلغ الإضافي للسلعة شامل الضريبة",
		attended_customers: "المستأجرين الحاضرين",
		customers_visits: "زيارات المستأجرين",
		are_you_sure_you_want_to_create_debit_note: "هل أنت متأكد أنك تريد إنشاء إشعار مدين؟",
		debit_notes: "إشعارات مدين",
		credit_notes: "إشعارات دائن",
		it_includes_all_payments_from_issued_invoices_advanced_payments_and_debit_notes:
			"تشمل الدفعات المحصلة من اصدار الفواتير، الدفعات مقدما، واشعارات المدين",
		please_select: "الرجاء الإختيار",
		to_add_amount_on_the_services_products_which_customer_get: "يتم اضافة مبلغ إضافي للخدمة/ المنتج التي حصل عليها المستأجر",
		preferred_to_return_payment_to_customer_on_the_same_method_of_issued_invoice: "* يفضل إستخدام نفس طريقة الدفع المستخدمة في إصدار الفاتورة",
		collected_vat_total_sales_and_debit_notes: "ضريبة القيمة المحصلة (مبيعات وإشعارات مدين)",
		paid_vat_expenses_and_credit_notes: "ضريبة القيمة المدفوعة (مصاريف وإشعارات دائن)",
		sales_adjustment_vat_report: "تعديلات تقرير القيمة المضافة للمبيعات",
		total_vat_report: "تقرير ضريبة القيمة المضافة الكلي",
		collected_vat_total_sales: "ضريبة القيمة المحصلة (مبيعات)",
		collected_vat_credit_notes: "ضريبة القيمة المحصلة (إشعارات مدين)",
		vat_paid_vat_expenses: "ضريبة القيمة المدفوعة (مصاريف)",
		paid_vat_debit_notes: "ضريبة القيمة المدفوعة (إشعارات دائن)",
		vat_report_from_sales: "تقرير ضريبة القيمة (مبيعات ومصاريف)",
		vat_report_from_notes: "تقرير ضريبة القيمة (إشعارات دائن ومدين)",
		employee_performance_and_commission_are_not_calculated_in_debit_notes: "لا يتم احتساب انجازات الموظفين وعمولاتهم في اشعارات المدين",
		employee_performance_and_commission_are_not_calculated_in_debit_notes_with_star: "* لا يتم احتساب انجازات الموظفين وعمولاتهم في اشعارات المدين",
		expense_without_the: "مصروف",
		customers_balances: "أرصدة المستأجرين",
		customer_balance: "رصيد المستأجر",
		customer_balance_services: "رصيد المستأجر (الخدمات)",
		customer_balance_money: "رصيد المستأجر ($)",
		customers_balances_services: "أرصدة المستأجرين (الخدمات)",
		customers_balances_money: "أرصدة المستأجرين ($)",
		delete_expected_expense: "حذف المصروف المتوقع",
		are_you_sure_you_want_to_delete_the_expected_expense: "هل أنت متأكد أنك تريد حذف المصروف المتوقع؟",
		record_deleted_successfully: "تم حذف السجل بنجاح",
		payment_methods: "طرق الدفع",
		payment_gateway: "بوابة الدفع",
		show_gifts_cards: "إظهار بطاقات الهدايا",
		booking_is_not_allowed: "الحجز غير مسموح",
		payment_method_is_not_allowed: "طريقة الدفع غير مسموحة",
		you_should_select_number_services_for_this_offer: "يجب عليك أن تقوم بإختيار {numberOfServices} خدمات لهذا العرض",
		card: "بطاقة",
		online_booking_with_payment_gateway_will_be_suspended: "- سيتم ايقاف حجز المواعيد باستخدام بطاقات الدفع",
		selling_packages_and_offers_online_will_be_suspended: "- سيتم ايقاف بيع العروض",
		selling_gifts_card_online_will_be_suspended: "- سيتم ايقاف بيع بطاقات الهدايا",
		appointment_not_exist: "الموعد غير موجود",
		all_services_in_this_appointment_has_been_cancelled_please_book_a_new_one: "لقد ألغيت جميع الخدمات في هذا الموعد، الرجاء حجز موعد جديد",
		are_you_sure_you_want_to_cancel_all_the_services: "هل أنت متأكد أنك تريد إلغاء جميع الخدمات؟",
		you_can_not_create_credit_note_for_invoice_with_partial_payments_please_settle_the_payments:
			"لا يمكنك اصدار اشعار دائن لفاتورة غير مكتملة الدفعات\n\nالرجاء تسديد الدفعات الغير المكتملة",
		payment_should_equal_remaining_amount_for_settling_invoice: "الدفعة يجب أن تساوي القيمة المتبقية من أجل تسوية الفاتورة",
		search_booking_id: "بحث برقم الحجز",
		other_amount: "مبلغ آخر",
		supplier_name: "إسم المورد",
		common_name: "الاسم الشائع",
		branch_name: "اسم الفرع",
		street_name: "اسم الشارع",
		building_number: "رقم العمارة",
		city_subdivision_name: "اسم المنطقة",
		city_name: "اسم المدينة",
		postal_zone: "المنطقة البريدية",
		commercial_registration_number: "رقم السجل التجاري",
		if_you_leave_it_blank_owner_email_will_be_used: "إذا قمت بتركها فارغة سيتم إستخدام البريد الإلكتروني للمالك",
		activate_phase_2: "تفعيل المرحلة الثانية",
		are_you_sure_you_want_to_activate_phase_2: "هل أنت متأكد أنك تريد تفعيل المرحلة الثانية؟",
		otp: "كلمة المرور للمرة الواحد (OTP)",
		extra_tax_information: "معلومات ضريبية إضافية",
		ksa_tax_phase_2: "المرحلة الثانية للضريبة في السعودية",
		suppliers_liabilities: "إلتزامات الموردين",
		zatca_setting: "إعدادات الضريبة المرحلة الثانية",
		add_zatca_setting: "إضافة إعدادات الضريبة المرحلة الثانية",
		edit_zatca_setting: "تعديل إعدادات الضريبة المرحلة الثانية",
		commercial_number: "السجل التجاري",
		failed_to_generate_files: "فشل في إنشاء الملفات",
		please_enter_the_otp: "الرجاء إدخال ال(OTP)",
		phase_2_already_activated: "المرحلة الثانية مفعلة مسبقا",
		request: "طلب",
		reported_to_zatca: "تم الإبلاغ إلى ZATCA",
		report_to_zatca: "إبلاغ إلى ZATCA",
		reported_successfully: "تم الإبلاغ بنجاح",
		renewed: "تم التجديد",
		data_cancelled_successfully: "تم إلغاء البيانات بنجاح",
		toggle: "تبديل",
		dummy_needs_a_vue_build: "Dummy (Needs a VUE build)",
		offers_and_membership_and_online_bookings: "العروض والاشتراكات وحجوزات الاونلاين",
		change_employee: "تغيير الموظف",
		on_hold_invoice: "الفاتورة المعلقة",
		view_all_employees_appointments: "مشاهدة كل مواعيد الموظفين",
		appointments_cancellation_reasons: "أسباب إلغاء المواعيد",
		appointment_editted: "تم تعديل الموعد",
		cancellation_source: "مصدر الإلغاء",
		tip: "إكرامية",
		tips: "الإكراميات",
		add_tips: "إضافة إكرامية",
		employee_income: "دخل الموظف",
		in_salon: "في الصالون",
		pricing: "الأسعار",
		salon_type: "نوع الصالون",
		subscription_type: "نوع الاشتراك",
		number_of_units_will_exceed_the_max_number_of_units_allowed_in_the_subscription:
			"عدد الوحدات سوف يتجاوز الحد الأقصى لعدد الوحدات المسموحين في نوع الاشتراك",
		allowed_countries: "الدول المسموحة",
		you_are_not_authorized_to_use_app_from_you_location_please_contact_your_account_manager:
			"ليس لديك صلاحيات لاستخدام التطبيق من موقعك الجغرافي،،،، الرجاء التواصل مع مسؤول حسابك",
		price_before_discount: "السعر قبل الخصم",
		you_dont_have_business_account_with_salonaty: "ليس لديك حساب أعمال في عقار بوكس؟",
		disallowed_country: "دولة غير مسموحة",
		running_text: "النص المتحرك",
		english_text: "النص بالإنجليزية",
		arabic_text: "النص بالعربية",
		divide_by_two: "تقسييم على موظفين",
		please_now_select_the_service: "الرجاء الآن إختيار الخدمة",
		please_select_the_second_employee: "الرجاء إختيار الموظف الثاني",
		your_subscription_is_valid_for_employees_only_please_contact_your_sales: "إشتراكك صالح ل {numberOfUnits} وحدات فقط، الرجاء التواصل مع المبيعات",
		units: "الوحدات",
		add_unit: "إضافة وحدة",
		edit_unit: "تعديل وحدة",
		sector: "القطاع",
		district: "الحي",
		plot: "القطعة",
		unit: "الوحدة",
		floor: "الطابق",
		building_english_name: "إسم البناء بالإنجليزية",
		building_arabic_name: "إسم البناء بالعربية",
		fewa_number: "عداد الكهرباء",
		sewarage_number: "رقم الصرف الصحي",
		unit_already_exist: "العقار موجود مسبقا",
		unit_form: "نموذج الوحدة",
		unit_usage: "إستخدامات الوحدة",
		building: "البناية",
		extra_informations: "معلومات إضافية",
		owners: "المالكين",
		national_id: "الرقم الوطني",
		add_owner: "إضافة مالك",
		edit_owner: "تعديل مالك",
		google_location_map: "الموقع الجغرافي في جوجل",
		minimum_rent_price: "السعر الأدنى للإيجار",
		size: "المساحة (sqft)",
		not_exist_create_new_owner: "غير موجود, اضافة مالك جديد؟",
		search_owner: "البحث عن مالك",
		security_deposit: "مبلغ التأمين",
		customer_contracts: "عقود المستأجر",
		new_contract: "عقد جديد",
		data_added_successfully: "تم إضافة البيانات بنجاح",
		data_edited_successfully: "تم تعديل البيانات بنجاح",
		usage: "الاستخدام",
		bedrooms: "غرف النوم",
		bathrooms: "الحمامات",
		buildings: "الأبنية",
		add_building: "إضافة بناء",
		edit_building: "تعديل بناء",
		sector_english_name: "القطاع بالإنجليزية",
		sector_arabic_name: "القطاع بالعربية",
		district_english_name: "الحي بالإنجليزية",
		district_arabic_name: "الحي بالعربية",
		residance: "سكني",
		commercial: "تجاري",
		apartment: "شقة",
		villa: "فيلا",
		commercial_shop: "محل تجاري",
		agreed_installment_plan: "خطة السداد المتفق عليها؟",
		contract: "عقد",
		unit_details: "تفاصيل الوحدة",
		rent_price: "سعر الإيجار",
		parking_details: "تفاصيل الموقف",
		parking_id: "رقم الموقف",
		payment_plan: "خطة الدفع",
		total_rent_price: "سعر الإيجار الكلي",
		cheque_number: "رقم الشيك",
		bank: "البنك",
		master_data: "البيانات الرئيسية",
		add_city: "إضافة مدينة",
		edit_city: "تعديل مدينة",
		vacant_units: "الوحدات الشاغرة",
		vacant_parkings: "المواقف الشاغرة",

		show_vacant_units: "عرض الوحدات الشاغرة",
		customers_contracts: "عقود المستأجرين",
		customers_payments: "دفعات المستأجرين",
		received_payments: "الدفعات المستلمة",
		collected_payments: "الدفعات المحصلة",
		non_collected_payments: "الدفعات غير المحصلة",
		all_payments: "جميع الدفعات",
		rented_units: "الوحدات المؤجرة",
		contracts_expired_within_three_months: "العقود التي تنتهي خلال 3 شهور",
		national_id_expiry_date: "تاريخ إنتهاء الرقم الوطني",
		passport_number: "رقم جواز السفر",
		passport_expiry_date: "تاريخ إنتهاء جواز السفر",
		contract_expiry_reminder: "تذكير بإنتهاء العقد",
		tasdeq_number: "رقم التصديق",
		active_contracts: "العقود الفعّالة",
		expired_contracts: "العقود المنتهية",
		historical_contract: "عقد تاريخي",
		historical_contracts: "عقود تاريخية",
		issued: "مصدّر",
		draft: "مؤقت",
		data_already_exist: "البيانات موجودة مسبقا",
		parking: "موقف",
		add_historical_contract: "إضافة عقد تاريخي",
		edit_historical_contract: "تعديل عقد تاريخي",
		parking_price: "سعر الموقف",
		contract_issue: "إصدار العقد",
		total_rent_not_equal_total_payments: "مجموع الإيجار لا يساوي مجموع الدفعات",
		contracts_extra_informations: "المعلومات الإضافية للعقد",
		period: "الفترة",
		contracts_payments: "دفعات العقود",
		on_date: "في موعده",
		tenant_profile: "ملف المستأجر",
		insurance_amount: "قيمة التأمين",
		insurance_payment_method: "طريقة دفع التأمين",
		edit_extra_informations: "تعديل معلومات إضافية",
		add_term_and_condition: "إضافة شرط وحكم",
		contract_with_valid_increment: "العقود القابلة للزيادة الإيجارية",
		old_payments: "الدفعات السابقة",
		draft_contracts: "عقود قيد الإصدار",
		units_status: "حالة الوحدات",
		download_qr: "تنزيل الQR",
		insurance_report: "تقرير التأمينات",
		minimum_rent_price_set_by_owner_is: "أقل سعر للإيجار من المالك هو: ",
		cheques_management: "إدارة الشيكات",
		edit_term_and_condition: "تعديل الشروط والأحكام",
		pending_maintenace_requests: "طلبات الصيانة المعلقة",
		completed_maintenance_requests: "طلبات الصيانة المنجزة",
		all_maintenance_requests: "جميع طلبات الصيانة",
		maintenance: "الصيانة",
		parkings: "المواقف",
		insurances: "التأمينات",
		add_parking: "إضافة موقف",
		edit_parking: "تعديل موقف",
		clearence_date: "تاريخ التحصيل",
		cheque_date: "تاريخ الشيك",
		contract_status: "حالة العقد",
		change_status: "تغيير الحالة",
		collect_payment: "إستلام دفعة",
		are_you_sure_you_want_to_the_payment_to_collected: "هل أنت متأكد أنك تريد تغيير الدفعة لمستلمة؟",
		collect: "تحصيل",
		collected: "تم التحصيل",
		not_collected: "لم تحصل",
		not_collected_payments: "الدفعات غير المحصلة",
		insurance_price: "سعر التأمين",
		all_contracts: "جميع العقود",
		tenant_has_no_insurance: "هذا المستأجر ليس لديه تأمين",
		tenant_has_insurance_of: "المستأجر لديه تأمين",
		contract_details: "تفاصيل العقد",
		all_statuses: "جميع الحالات",
		terminate: "إنهاء",
		rent: "إيجار",
		please_add_all_required_data: "الرجاء إكمال جميع البيانات",
		bank_receiver_name: "البنك/إسم المستلم",
		all_times: "جميع الأوقات",
		cancel_contract: "إلغاء العقد",
		are_you_sure_you_want_to_cancel_the_contract: "هل أنت متأكد أنك تريد إلغاء العقد؟",
		total_insurance_payments_not_equal_insurance_amount: "مجموع دفعات التأمين لا يساوي قيمة التأمين",
		disable_unit: "تعطيل الوحدة",
		are_you_sure_you_want_to_disable_the_unit: "هل أنت متأكد أنك تريد تعطيل الوحدة؟",
		payments_and_insurance: "الدفعات والتأمين",
		are_you_sure_you_want_to_change_the_payment_to_not_collected: "هل أنت متأكد أنك تريد تحويل الدفعة إلى لم تحصل؟",
		are_you_sure_want_to_issue_the_contract: "هل أنت متأكد أنك تريد إصدار العقد؟",
		cancel_contract_will_cancel_rent_status_on_unit: "إلغاء العقد سيلغي حالة مؤجر عن الوحدة",
		cancel_contract_will_cancel_rent_status_on_parking: "إلغاء العقد سيلغي حالة مؤجر عن الموقف",
		cancel_contract_will_cancel_all_payments_rent_price_insurance: "إلغاء العقد سيلغي جميع الدفعات (سعر الإيجار + التأمين)",
		this_contract_has_collected_payments: "هذا العقد يحتوي على دفعات محصلة",
		upload_data: "رفع بيانات",
		all_historical_contracts: "العقود التاريخية",
		all_current_contracts: "العقود الحالية",
		move_to_historical: "نقل إلى تاريخي",
		are_you_sure_want_to_move_to_historical: "هل أنت متأكد أنك تريد النقل إلى تاريخي؟",
		are_you_sure_want_to_move_to_current: "هل أنت متأكد أنك تريد النقل إلى حالي؟",
		move_to_current: "نقل إلى حالي",
		send_contract_in_email_to_unit_owner: "إرسال العقد بالبريد الإلكتروني إلى مالك العقار",
		current_contracts: "العقود الحالية",
		current_payments: "الدفعات الحالية",
		all_current_payments: "الدفعات الحالية",
		all_historical_payments: "الدفعات التاريخية",
		print_all: "طباعة الكل",
		historical: "تاريخي",
		are_you_sure_you_want_to_change_the_payment_to_on_date: "هل أنت متأكد أنك تريد تغيير الحالة إلى في موعده؟",
		this_action_will_move_the_contract_and_contract_payments_to_historical: "هذه العملية سوف تنقل العقد ودفعات العقد إلى تاريخي",
		this_action_will_move_the_contract_and_contract_payments_to_current: "هذه العملية سوف تنقل العقد ودفعات العقد إلى حالي",
		current: "حالي",
		suggested_maintenance_groups: "مجموعات الصيانة المقترحة",
		suggested_maintenance_services: "خدمات الصيانة المقترحة",
		disable_unit_will_remove_unit_from_building: "تعطيل الوحدة سوف يحذف الوحدة من البناء",
		flat_number: "رقم الشقة",
		contract_payments: "دفعات العقد",
		follow_up: "المتابعة",
		unattested_contracts: "العقود غير الموثقة",
		c_payments: "دفعات المستأجرين",
		historical_payments: "الدفعات التاريخية",
		payments_tenants: "دفعات المستأجرين",
		missing_data_reminder: "تذكير بالبيانات الناقصة",
		unattested_contracts_reminder: "تذكير بالعقود غير المصدقة",
		expired_within_three_months_reminder: "تذكير بالعقود ستنتهي خلال 3 شهور",
		expired_within_one_month_reminder: "تذكير بالعقود ستنتهي خلال شهر",
		expired_contracts_reminder: "تذكير بالعقود المنتهية",
		update_passport_reminder: "تذكير بجواز السفر",
		update_national_id_reminder: "تذكير بالرقم الوطني",
		contract_payment_reminder: "تذكير بدفعة عقد",
		on_date_payments: "دفعات في الموعد",
		on_date_current_payments: "دفعات حالية في موعدها",
		collected_current_payments: "دفعات حالية حصّلت",
		not_collected_current_payments: "دفعات حالية لم تحصّل",
		cancelled_current_payments: "دفعات حالية ملغية",
		cancelled_payments: "دفعات حالية ملغية",
		managed_owners: "الملّاك المدارين",
		managed_buildings: "الأبنة المدارة",
		managed_units: "الوحدات المدارة",
		managed_parkings: "المواقف المدارة",
		owner_form: "نموذج المالك",
		owner_details: "تفاصيل المالك",
		owner_access_edited_successfully: "تم تعديل صلاحيات دخول المالك بنجاح",
		passport_expiry_reminder: "تذكير بانتهاء جواز السفر",
		national_id_expiry_reminder: "تذكير بانتهاء الهوية الوطنية",
		real_estate_employee: "شركة عقارية (موظف)",
	},
};

const i18n = createI18n({
	legacy: false,
	locale: "en",
	globalInjection: true,
	messages,
});

export default i18n;
