import { getAuthTypeId } from "../mixins/general_methods";

let idTokenKey = (process.env.VUE_APP_UMT_SIGNITURE + "_token") as string;

export const setToken = (): void => {
	if (getAuthTypeId() == 2) {
		idTokenKey = process.env.VUE_APP_UMT_SIGNITURE + "_manager_token";
	} else if (getAuthTypeId() == 3) {
		idTokenKey = process.env.VUE_APP_UMT_SIGNITURE + "_owner_token";
	} else {
		idTokenKey = process.env.VUE_APP_UMT_SIGNITURE + "_token";
	}
};

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
	setToken();

	return window.localStorage.getItem(idTokenKey);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
	console.log(idTokenKey);
	window.localStorage.setItem(idTokenKey, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
	window.localStorage.removeItem(idTokenKey);
};

export default { setToken, getToken, saveToken, destroyToken };
