import { RouteRecordRaw } from "vue-router";

export const ownerAppRoutes: Array<RouteRecordRaw> = [
	{
		path: "/:language(en|ar|)?/owner",
		component: () => import("@/owner/layout/Layout.vue"),
		meta: {
			type: "app",
		},
		children: [
			{
				path: "",
				name: "owner-empty",
				component: () => import("@/owner/views/Welcome.vue"),
			},
			{
				path: "welcome",
				name: "owner-welcome",
				component: () => import("@/owner/views/Welcome.vue"),
			},
			{
				path: "dashboard",
				name: "owner-dashboard",
				component: () => import("@/owner/views/dashboard/Dashboard.vue"),
			},
			{
				path: "buildings",
				name: "owner-buildings",
				component: () => import("@/owner/views/Buildings.vue"),
			},
			{
				path: "units",
				name: "owner-units",
				component: () => import("@/owner/views/Units.vue"),
			},
			{
				path: "parkings",
				name: "owner-parkings",
				component: () => import("@/owner/views/Parkings.vue"),
			},
			{
				path: "contract-payments",
				children: [
					{
						path: "current-payments",
						name: "owner-contracts-payments-current-payments",
						component: () => import("@/owner/views/contract-payments/CurrentPayments.vue"),
					},
					{
						path: "current-payments-report",
						name: "owner-contracts-payments-current-payments-report",
						component: () => import("@/owner/views/contract-payments/CurrentPaymentsReport.vue"),
					},
					{
						path: "historical-payments",
						name: "owner-contracts-payments-historical-payments",
						component: () => import("@/owner/views/contract-payments/HistoricalPayments.vue"),
					},
				],
			},
			{
				path: "contracts",
				children: [
					{
						path: "current-contracts",
						name: "owner-contracts-current-contracts",
						component: () => import("@/owner/views/contracts/CurrentContracts.vue"),
					},
					{
						path: "historical-contracts",
						name: "owner-contracts-historical-contracts",
						component: () => import("@/owner/views/contracts/HistoricalContracts.vue"),
					},
				],
			},
			{
				path: "comming_soon",
				name: "owner-comming-soon",
				component: () => import("@/owner/views/CommingSoon.vue"),
			},
			{
				path: "no-permission",
				name: "owner-no-permission",
				component: () => import("@/owner/views/NoPermission.vue"),
			},
			{
				path: "profile",
				name: "owner-profile",
				component: () => import("@/owner/views/Profile.vue"),
			},
			{
				path: "logout",
				name: "owner-logout",
				component: () => import("@/owner/views/SignIn.vue"),
			},
		],
	},
	{
		path: "/:language(en|ar|)?/owner",
		component: () => import("@/owner/layout/AuthLayout.vue"),
		children: [
			{
				path: "sign-in",
				name: "owner-sign-in",
				component: () => import("@/owner/views/SignIn.vue"),
			},
			{
				path: "reset-password/:token",
				name: "owner-reset-password",
				component: () => import("@/owner/views/ResetPassword.vue"),
			},
		],
	},
];
