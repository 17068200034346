import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { getAuthTypeId, goTo } from "@/core/mixins/general_methods";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
	/**
	 * @description property to share vue instance
	 */
	public static vueInstance: App;

	/**
	 * @description initialize vue axios
	 */
	public static init(app: App<Element>) {
		ApiService.vueInstance = app;
		ApiService.vueInstance.use(VueAxios, axios);
		ApiService.vueInstance.axios.interceptors.request.use(
			function (config) {
				config.baseURL = ApiService.getBaseUrl();

				ApiService.setToken();

				return config;
			},
			function (error) {
				return Promise.reject(error);
			}
		);

		ApiService.vueInstance.axios.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error) {
				const { config, response } = error;

				if (!response || (response.status >= 500 && response.status < 600 && response.status != 501)) {
					let retryCount = config.retryCount || 0;

					if (retryCount >= 2) {
						return Promise.reject(error);
					}

					retryCount += 1;

					return new Promise((resolve) => {
						setTimeout(() => {
							config.retryCount = retryCount;
							resolve(ApiService.vueInstance.axios(config));
						}, 1000);
					});
				}

				if (
					error != undefined &&
					error != null &&
					error.response != undefined &&
					error.response != null &&
					(error.response.status === 403 || error.response.status === 401)
				) {
					goTo({ name: "merchant-sign-in" });
				}

				return Promise.reject(error);
			}
		);
	}

	public static getBaseUrl() {
		if (getAuthTypeId() == 2) {
			return process.env.VUE_APP_MANAGER_API_URL;
		} else if (getAuthTypeId() == 3) {
			return process.env.VUE_APP_OWNER_API_URL;
		} else {
			return process.env.VUE_APP_API_URL;
		}
	}

	/**
	 * @description set the default HTTP request headers
	 */
	public static setHeader(): void {
		ApiService.setToken();
		ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
	}

	public static setToken(): void {
		ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
	}

	/**
	 * @description send the GET HTTP request
	 * @param resource: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.get(resource, params);
	}

	/**
	 * @description send the GET HTTP request
	 * @param resource: string
	 * @param slug: string
	 * @returns Promise<AxiosResponse>
	 */
	public static get(resource: string, slug = "" as string): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
	}

	/**
	 * @description set the POST HTTP request
	 * @param resource: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.post(`${resource}`, params);
	}

	/**
	 * @description send the UPDATE HTTP request
	 * @param resource: string
	 * @param slug: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static update(resource: string, slug: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
	}

	/**
	 * @description Send the PUT HTTP request
	 * @param resource: string
	 * @param params: AxiosRequestConfig
	 * @returns Promise<AxiosResponse>
	 */
	public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.put(`${resource}`, params);
	}

	/**
	 * @description Send the DELETE HTTP request
	 * @param resource: string
	 * @returns Promise<AxiosResponse>
	 */
	public static delete(resource: string): Promise<AxiosResponse> {
		return ApiService.vueInstance.axios.delete(resource);
	}
}

export default ApiService;
