<template>
	<div class="position-relative s-mobile-input" dir="ltr">
		<el-form-item
			:label="hideLabel ? '' : generalMethods.translate(label)"
			:prop="prop"
			:rules="rules"
			:class="`${elFormItemClassses}`"
			:for="id == null ? prop : id"
		>
			<el-input
				:id="id == null ? prop : id"
				ref="mobileInputRef"
				v-bind="$attrs"
				v-model="localModelValue"
				:autocomplete="autocomplete"
				dir="ltr"
				@input="
					(value) => {
						emitEvent('valueChanged', value);
					}
				"
				@change="
					(value) => {
						emitEvent('valueChanged', value);
					}
				"
				:label="ariaLabel"
				:disabled="disabled"
			>
				<template v-if="!hidePrepend" #prepend>
					<el-select
						v-show="!hideList"
						v-model="country"
						style="width: 85px"
						:placeholder="generalMethods.valueExist(country) || generalMethods.valueExist(localModelValue, true) ? ' ' : generalMethods.translate('select')"
						@change="updateMobileFromCountry()"
						aria-label="country code"
						:class="[{ 'mobile-input-empty': !generalMethods.valueExist(country) }, { 'dark-and-white-select': darkAndWhiteSelect }]"
						popper-class="el-dropdown-50px text-center"
						:teleported="false"
					>
						<el-option v-for="(countryOption, index) in countryOptions" :key="index" :value="countryOption">
							<span class="svg-35px">
								<SvgSprite :symbol="generalMethods.prepareSvgId(countryOption.english_name)" />
							</span>
						</el-option>
						<template #prefix>
							<span v-if="generalMethods.valueExist(country)" class="svg-30px">
								<SvgSprite :symbol="generalMethods.prepareSvgId(country.english_name)" />
							</span>
							<span v-else-if="generalMethods.valueExist(localModelValue, true)" class="svg-30px">
								<SvgSprite symbol="nations_flag" />
							</span>
						</template>
					</el-select>
					<span class="svg-38px" style="min-width: 38px; padding-right: 15px" :class="[{ 'd-inline-block': hideList }, { 'd-none': !hideList }]">
						<SvgSprite v-if="generalMethods.valueExist(country)" :symbol="generalMethods.prepareSvgId(country.english_name)" />
						<SvgSprite v-else-if="generalMethods.valueExist(localModelValue, true)" symbol="nations_flag" />
					</span>
				</template>
			</el-input>
		</el-form-item>
		<span class="mobile-input-valid" :style="`${generalMethods.valueExist(validTextTop) ? 'top:' + validTextTop + ';' : ''}`">
			<span
				v-if="
					generalMethods.valueExist(localModelValue) &&
					generalMethods.valueExist(country) &&
					mobileValid &&
					generalMethods.getMobileValidationLength(generalMethods.getMobileValue(localModelValue)) == generalMethods.getMobileValue(localModelValue).length
				"
				class="text-white bg-success"
				style="font-size: 10px; padding: 2px"
			>
				<i class="fa fa-check text-white" style="font-size: 10px" />
				{{ generalMethods.translate("valid") }}
			</span>

			<!--<span dir="ltr">
				<span v-if="whatsappIcon === true" class="me-2" style="padding-top: 2px">
					<i class="fab fa-whatsapp text-success" style="font-size: 16px; margin-right: 3px" />
					<span class="text-success">{{ generalMethods.translate("this_number_have_active_whatsapp") }}</span>
				</span>
				<span v-else-if="whatsappIcon === false" class="me-2" style="padding-top: 2px">
					<i class="fab fa-whatsapp text-danger" style="font-size: 16px; margin-right: 3px" />
					<span class="text-danger">{{ generalMethods.translate("this_number_does_not_have_active_whatsapp") }}</span>
				</span>
			</span>-->
		</span>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref, watch } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";
import * as customValidation from "@/core/mixins/custom_validation";
import axios from "axios";

export default defineComponent({
	name: "umt-element-plus-mobile-input",
	props: {
		id: { type: String },
		label: { type: String },
		hideLabel: { type: Boolean, default: false },
		prop: { type: String, required: true },
		modelValue: {},
		required: { type: Boolean, default: true },
		countryOptions: { type: Array },
		countryValidation: { type: Boolean, default: true },
		form: {},
		validTextTop: { type: String, default: null },
		validateMaxLength: { type: Boolean, default: false },
		validateNumeric: { type: Boolean, default: true },
		hideList: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		autocomplete: { type: String, default: "off" },
		ariaLabel: { type: String, default: null },
		darkAndWhiteSelect: { type: Boolean, default: false },
		extraRules: { type: Array, default: null },
		hidePrepend: { type: Boolean, default: false },
		whatsappIcon: { type: Boolean, default: null },
		elFormItemClassses: { type: String, default: "" },
		autofocus: { type: Boolean, default: false },
		getCountryAtFirstValue: { type: Boolean, default: false },
		submitted: { type: Boolean, default: true },
	},
	setup(props, { emit }) {
		onMounted(() => {
			if (props.autofocus && generalMethods.valueExist(mobileInputRef.value)) {
				mobileInputRef.value.focus();
			}

			if (props.getCountryAtFirstValue) {
				getCountryAtFirst();
			}

			nextTick(() => {
				if (generalMethods.valueExist(localModelValue)) {
					updateCountryFromMobile(props.modelValue, false);
					(props.form as any).resetFields();
				}
			});
		});

		const mobileInputRef = ref<any>(null);

		const localModelValue = computed({
			get() {
				return props.modelValue;
			},
			set(newValue) {
				emit("update:modelValue", newValue);
			},
		});

		const country = ref(null as any);

		const mobileValid = ref(false as boolean);

		const rules = computed(() => {
			let rules = [] as Array<any>;

			rules.push({
				validator: (rule, value, callaback) => {
					customValidation.required(rule, value, callaback, props.label);
				},
			});

			if (props.validateNumeric) {
				rules.push({
					validator: (rule, value, callaback) => {
						customValidation.isNumeric(rule, value?.startsWith("+") ? value.substring(1, value.length) : value, callaback, props.label);
					},
				});
			}

			if (props.countryValidation) {
				rules.push({
					validator: (rule, value, callaback) => {
						customValidation.custom(
							rule,
							value,
							callaback,
							generalMethods.valueExist(value, true) && !generalMethods.valueExist(country.value),
							generalMethods.getRequiredMessage("prefix")
						);
					},
				});
			}

			if (generalMethods.valueExist(country.value)) {
				if (props.submitted) {
					if (props.validateMaxLength) {
						rules.push({
							validator: (rule, value, callaback) => {
								customValidation.maxLength(rule, value, callaback, props.label, generalMethods.getMobileValidationLength(value));
							},
						});
					} else {
						rules.push({
							validator: (rule, value, callaback) => {
								customValidation.length(rule, value, callaback, props.label, generalMethods.getMobileValidationLength(value));
							},
						});
					}
				}
			}

			if (generalMethods.valueExist(props.extraRules)) {
				rules = rules.concat(props.extraRules);
			}

			return rules;
		});

		const updateMobileFromCountry = () => {
			localModelValue.value = null;
			if (generalMethods.valueExist(country)) {
				localModelValue.value = "+" + country.value.prefix;
			}

			emitEvent("valueChanged", localModelValue.value);

			updateMobileValid();
		};

		const updateCountryFromMobile = (value, withValidate = true) => {
			const countryOptions = props.countryOptions as Array<any>;

			if (value == null || value == "") {
				country.value = null;
			} else if (value?.startsWith("+")) {
				value = value.substring(1, value.length);
			} else if (value?.startsWith("00")) {
				value = value.substring(2, value.length);
			}

			let exist = false;
			for (let i = 0; i < countryOptions.length; i++) {
				if (countryOptions[i].prefix == 970) {
					if (value?.startsWith(970) || value?.startsWith(972)) {
						country.value = countryOptions[i];
						exist = true;
					}
				} else if (value?.startsWith(countryOptions[i].prefix)) {
					country.value = countryOptions[i];
					exist = true;
				}
			}

			if (!exist) {
				country.value = null;
			}

			if (withValidate) {
				updateMobileValid();
			}
		};

		const updateMobileValid = () => {
			(props.form as any).validateField(props.prop, (mobileValidateValid) => {
				mobileValid.value = mobileValidateValid;
			});
		};

		const getCountryAtFirst = () => {
			axios.get("https://ipapi.co/json").then((response) => {
				localModelValue.value = response.data.country_calling_code;
			});
		};

		const emitEvent = (event, value) => {
			if (value != localModelValue.value) {
				emit(event, value);
			}
		};

		watch(
			() => props.modelValue,
			() => {
				updateCountryFromMobile(props.modelValue, true);
			}
		);

		watch(
			() => props.countryOptions,
			() => {
				updateCountryFromMobile(props.modelValue, true);
			}
		);

		return {
			generalMethods,
			customValidation,

			localModelValue,

			mobileInputRef,

			rules,

			country,
			updateMobileFromCountry,
			updateCountryFromMobile,

			mobileValid,

			emitEvent,
		};
	},
});
</script>
